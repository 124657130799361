import React, {Component} from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class ExportFscpDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
          checklists:[],
        }
    }

    renderTableHeader() {
        return (
        <>
            <th key="checklist number">checklist number</th>
            <th key="template type">template type</th>
            <th key="line id">line id</th>
            <th key="task group">task group</th>
            <th key="item">item</th>
            <th key="frequency">frequency</th>
            <th key="description">description</th>
            <th key="documentation requirement">documentation requirement</th>
            <th key="working paper reference">working paper reference</th>
            <th key="prepared by user">prepared by user</th>
            <th key="prepared date">prepared date</th>
            <th key="reviewed by user">reviewed by user</th>
            <th key="reviewed date">reviewed date</th>
            <th key="conclusion">conclusion</th>
            <th key="matters to highlight">matters to highlight</th>
            <th key="control">control</th>
            <th key="key report">key report</th>
            <th key="status">status</th>
        </>
        )
    }

    setHTML(props){
        return props?{__html: props.replace(/<div>/g, "<br style=mso-data-placement:same-cell; />").replace(/<\/div>/g,"").replace(/<br>/g, "<br style=mso-data-placement:same-cell; />")}:{__html: props}
    }

    renderTableData() {
        const {checklists} = this.props;
        return checklists.filter((mth)=>{return mth.matters_to_highlight !== null && mth.matters_to_highlight !==''}).map((checklist) => {
                   const { checklist_number,checklist_line_id, template_type, line_id, task_group, item, frequency, description, documentation_requirement,
                           working_paper_reference, prepared_by_user ,prepared_date, reviewed_by_user, reviewed_date, conclusion, matters_to_highlight,
                            control, key_report, status
                   } = checklist;
           return (
              <tr key={checklist_line_id}>
                 <td>{checklist_number}</td>
                 <td>{template_type.charAt(0).toUpperCase() + template_type.slice(1).toLowerCase() + ' Specific'}</td>
                 <td>{line_id}</td>
                 <td>{task_group}</td>
                 <td>{item}</td>
                 <td>{frequency}</td>
                 <td>{description}</td>
                 <td dangerouslySetInnerHTML={this.setHTML(documentation_requirement)}/>
                 <td dangerouslySetInnerHTML={this.setHTML(working_paper_reference)}/>
                 <td>{prepared_by_user}</td>
                 <td>{prepared_date}</td>
                 <td>{reviewed_by_user}</td>
                 <td>{reviewed_date}</td>
                 <td dangerouslySetInnerHTML={this.setHTML(conclusion)}/>
                 <td dangerouslySetInnerHTML={this.setHTML(matters_to_highlight)}/>
                 <td dangerouslySetInnerHTML={this.setHTML(control)}/>
                 <td dangerouslySetInnerHTML={this.setHTML(key_report)}/>
                 <td>{status}</td>
              </tr>
           )
        }) 
    }

    render() {
        const {periodName,checklists,searchexport} = this.props;
        const fileName = `MattersToHiglightReport-${periodName}`;

        return (
            <>
            {checklists.length > 0 ?
                <ReactHTMLTableToExcel
                    id="Fscp-Export"
                    className={"fscp-btn-report"}
                    table="Fscp-Table"
                    filename={fileName}
                    sheet={fileName}
                    onClick={e=>console.log(e)}
                    buttonText={`${periodName} Highlight Report`}/>
                
            :searchexport?`Generating report…`:null}
            <table hidden id="Fscp-Table">
                    <tbody>
                        <tr>
                            <td>
                                <strong>Period</strong><br/>
                            </td>
                            <td>
                                <strong> : </strong><br/>
                            </td>
                            <td>
                                {periodName}<br/>
                            </td>
                        </tr>
                        <tr>
                            <td><br/>
                            </td>
                        </tr>    
                        <tr>{checklists.length > 0 && checklists?this.renderTableHeader():null}</tr>
                        {checklists.length > 0 &&checklists?this.renderTableData():null}
                    </tbody>
                </table>
            </>
        );
    }
}
 
export default ExportFscpDetail;