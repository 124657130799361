/* global gapi */
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import Select from 'react-select';
import './sea-react-table.css';
import { withRouter } from 'react-router-dom';
import "react-sweet-progress/lib/style.css";
import 'react-tabs/style/react-tabs.css';
import ExportTaxSummary from './ExportTaxSummary';
import {baseURL} from '../Constant'

const selectStyle = {
  container: base => ({
    ...base,
    width: 250,
    minwidth: 250,
    maxwidth: 250,
    height: 40,
    maxheight: 38,
    marginLeft: 15,
  })
};

var pageId = 1;

let Segments = [];
let Entities = [];
let Countries = [];
let TaxCategory = [];
let Year = [];

class ManageFlow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accesstoken: '',
      username: '',
      cookies: this.props.cookies.get('Logins'),
      isSearch: false,
      selectedTaxCategory: 'All',
      selectedSegment: 'All',
      selectedCountry: 'All',
      selectedEntity: 'All',
      selectedYear: new Date().getFullYear(),
      accessType: null,
      hidecreate: true,
      pageAccessAllowed: '',
      pageAccessType: '',
      exportData: null,
      toExportData: false,
    };
  };

  abortController = new AbortController();

  onChangeHandler = (selectedOption, compProps) => {
    const selectedValue = selectedOption.value, name = compProps.name;
    const { isSearch, selectedTaxCategory, selectedEntity, selectedCountry, selectedSegment } = this.state;
    if (isSearch && (selectedTaxCategory !== selectedValue || selectedEntity !== selectedValue || selectedValue === 'All')) {
      this.setState({ isSearch: false });
    }
    switch (name) {
      case 'TaxCategory':
        this.setState({
          selectedTaxCategory: selectedValue,
        });
        break;
      case 'Year':
        this.setState({
          selectedYear: selectedValue,
        });
        break;
      case 'segment':
        this.setState({ selectedSegment: selectedValue },
          () => {
            this.cleanUpDropdown('country');
            this.cleanUpDropdown('entity');
            this.populateCountries(selectedEntity, selectedCountry, selectedValue);
            this.populateEntities(selectedCountry, selectedValue);
          });
        break;
      case 'country':
        this.setState({ selectedCountry: selectedValue },
          () => {
            this.cleanUpDropdown('segment');
            this.cleanUpDropdown('entity');
            this.populateSegments(selectedEntity, selectedValue);
            this.populateEntities(selectedValue, selectedSegment);
          });
        break;
      case 'entity':
        this.setState({ selectedEntity: selectedValue },
          () => {
            this.cleanUpDropdown('segment');
            this.cleanUpDropdown('country');
            this.populateCountries(selectedValue, selectedCountry, selectedSegment);
            this.populateSegments(selectedValue, selectedCountry);
          });
        break;
      default:
        break;
    };
  };

  populateYear() {
    const url = `${baseURL}/tfs/periods/`;
    const signal = this.abortController.signal;
    const { accesstoken } = this.state;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          Year.push({ label: element["year"], value: element["year"] });
        });
      });
  };

  populateTaxCategory() {
    const url = `${baseURL}/tfs/tax_category/`;
    const signal = this.abortController.signal;
    const { accesstoken } = this.state;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          TaxCategory.push({ label: element["taxcategory_id"], value: element["taxcategory_id"] });
        });
      });
  };

  populateCountries(selectedEntity, selectedCountry, selectedSegment) {
    const url = `${baseURL}/tfs/country_list/?entity_id=${selectedEntity}&country_id=${selectedCountry}&segment_id=${selectedSegment}`;
    const signal = this.abortController.signal;
    const { accesstoken } = this.state;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          Countries.push({ label: element["country_name"], value: element["country_id"] });
        });
      });
  };

  populateSegments(selectedEntity, selectedCountry) {
    const url = `${baseURL}/tfs/segment_list/?entity_id=${selectedEntity}&country_id=${selectedCountry}`;
    const signal = this.abortController.signal;
    const { accesstoken } = this.state;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          Segments.push({ label: element["segment_name"], value: element["segment_id"] });
        })
      });
  };

  populateEntities(selectedCountry, selectedSegment) {
    const url = `${baseURL}/tfs/entity_list/?country_id=${selectedCountry}&segment_id=${selectedSegment}`;
    const signal = this.abortController.signal;
    const { accesstoken } = this.state;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          Entities.push({ label: element["entity_id"], value: element["entity_id"] });
        })
      });
  };

  cleanUpDropdown = (type) => {
    switch (type) {
      case 'All':
        TaxCategory.length = 0;
        Year.length = 0;
        Countries.length = 0;
        Segments.length = 0;
        Entities.length = 0;
        Countries.push({ label: "All", value: "All" });
        Segments.push({ label: "All", value: "All" });
        Entities.push({ label: "All", value: "All" });
        TaxCategory.push({ label: "All", value: "All" });
        Year.push({ label: "All", value: "All" });
        break;
      case 'segment':
        Segments.length = 0;
        Segments.push({ label: "All", value: "All" });
        break;
      case 'country':
        Countries.length = 0;
        Countries.push({ label: "All", value: "All" });
        break;
      case 'entity':
        Entities.length = 0;
        Entities.push({ label: "All", value: "All" });
        break;
      default:
        break;
    };
  }

  componentDidMount() {
    const { selectedSegment, selectedCountry, selectedEntity } = this.state;
    this.cleanUpDropdown('All');
    if (this.state.cookies !== "Test" && this.state.cookies) {
      window.gapi.load('auth2', () => {
        window.gapi.auth2.init({
          client_id: `${process.env.REACT_APP_CLIENT_ID}`,
          cookie_policy: 'none'
        }).then(() => {
          try {
            const user = gapi.auth2.getAuthInstance().currentUser.get();
            user.reloadAuthResponse()
            gapi.auth2.getAuthInstance().currentUser.listen((usr) => this.setState({ accesstoken: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token, username: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail() }))
          } catch (error) {
            console.log("trycatch", error)
          }

          this.setState({
            accesstoken: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token,
            username: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail()
          })
          this.populateYear();
          this.populateTaxCategory();
          this.populateCountries(selectedEntity, selectedCountry, selectedSegment);
          this.populateSegments(selectedEntity, selectedCountry);
          this.populateEntities(selectedCountry, selectedSegment);
          this.initUserPageAccess();
        })
      })
    } else {
      gapi.auth2.getAuthInstance().disconnect()
      window.location.href = '/Login';
    }
  };

  hideCreateButton() {
    document.getElementById("#create").disabled = (this.state.pageAccessType !== "READ_ONLY") ? false : true;
    document.getElementById("#create").style.opacity = (this.state.pageAccessType !== "READ_ONLY") ? null : 0.5;
  }

  initUserPageAccess() {
    const { accesstoken } = this.state;
    const url = `${baseURL}/tfs/my_role_page_access/?page_id=${pageId}`;

    var pageAccessAllowed, pageAccessType;
    fetch(url, {
      signal: this.abortController.signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then((jsonStr) => {
        jsonStr.forEach(element => {
          pageAccessAllowed = element["access_allowed"];
          pageAccessType = element["access_type"];

          this.setState({ pageAccessAllowed: pageAccessAllowed, pageAccessType: pageAccessType }, () => {
            if (this.state.pageAccessAllowed !== "Y") {
              window.location.href = '/';
            }
          });
          this.hideCreateButton();
        })
      }).catch(error => console.log('e', error));
  };

  componentWillUnmount() {
    this.abortController.abort();
  }

  /**
   * To pass the states to the parent component
   */
  onClickSearchButton = () => {
    this.props.getDropdownData(
        this.state.selectedEntity,
        this.state.selectedSegment,
        this.state.selectedCountry,
        this.state.selectedTaxCategory,
        this.state.selectedYear
    )
  }

  /**
   * To handle on export click
   *
   * @return {Promise<void>}
   */
  onClickExportData = async () => {
    const data = await this.props.onClickExportData()

    this.setState({
      exportData: data,
      toExportData: true
    }, () => {
      this.setState({
        toExportData: false
      })
    })
  }

  render() {
    const { selectedTaxCategory } = this.state.selectedTaxCategory;
    const { selectedYear } = this.state.selectedYear;
    const { selectedSegment } = this.state.selectedSegment;
    const { selectedCountry } = this.state.selectedCountry;
    const { selectedEntity } = this.state.selectedEntity;

    return (
      <>
        <Select
          styles={selectStyle}
          options={Year}
          placeholder={'Year'}
          name='Year'
          value={selectedYear}
          onChange={this.onChangeHandler}
          defaultValue={{ label: new Date().getFullYear(), value: selectedYear }}
        />
        <Select
          styles={selectStyle}
          options={TaxCategory}
          placeholder={'Tax Category'}
          name='TaxCategory'
          value={selectedTaxCategory}
          onChange={this.onChangeHandler}
        />
        <Select
          styles={selectStyle}
          options={Countries}
          placeholder={'Country'}
          name='country'
          value={selectedCountry}
          onChange={this.onChangeHandler}
        />
        <Select
          styles={selectStyle}
          options={Segments}
          placeholder={'Segment'}
          name='segment'
          value={selectedSegment}
          onChange={this.onChangeHandler}
        />
        <Select
          styles={selectStyle}
          options={Entities}
          placeholder={'Entity'}
          name='entity'
          value={selectedEntity}
          onChange={this.onChangeHandler}
        />
        <div className="fscp-btn-wrapper">
          <button className="fscp-btn btn-focus" onClick={this.onClickSearchButton}>Search</button>
          <button id="#create" className={"fscp-btn btn-focus"} onClick={e => window.location.href = '/createsubmission'}>Create</button>
          <button className={"fscp-btn btn-focus"} onClick={this.onClickExportData}>Export</button>
          {this.state.toExportData && <ExportTaxSummary exportData={this.state.exportData} /> }
        </div>
      </>
    );
  };
};

export default withCookies(withRouter(ManageFlow));
