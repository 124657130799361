import React from 'react';
import { Link } from 'react-router-dom';
var userPageAccess = [];

const menuLists = [
  {
    listID: 'ml1',
    listName: 'menu-home',
    linkTo: '/',
    linkName: "sprite-more more-active fl",
    linkValue: 'Home',
    linkaccessname: 'Home'
  },
  {
    listID: 'ml2',
    listName: 'menu-item',
    linkTo: '/taxsubmissions',
    linkName: 'sprite-fscp-s fl',
    linkValue: 'Tax Submissions',
    linkaccessname: 'TaxSubmissions'
  },
  {
    listID: 'ml3',
    listName: 'menu-item',
    linkTo: '/manageflow',
    linkName: 'sprite-others-s fl',
    linkValue: 'Manage Approval Tax Item',
    linkaccessname: 'ManageApproval'
  },
];

const ShowList = props => {
  var upa = props.userPageAccess;
  userPageAccess.length = 0;

  for (var i = 0; i < upa.length; i++) {
    userPageAccess.push(upa[i].page_name);
  }

  const mLists = props.menuLists;
  const listNames = require('classnames'), linkNames = require('classnames')
  const theLists = mLists.map((mList) => {
    if (userPageAccess.indexOf(mList.linkaccessname) !== -1 || mList.linkaccessname === "Home") {
      return (
        <li key={mList.listID} className={listNames(mList.listName)}>
          <Link to={mList.linkTo}>
            <span className={linkNames(mList.linkName)}></span>{mList.linkValue}
          </Link>
        </li>
      )
    }
    else {
      return null;
    }
  })
  return theLists;
}

const Menulist = props => {
  let menulistClasses = 'menu-list';
  if (props.isMenuExpanded) {
    menulistClasses = 'menu-list menu-list-expanded';
  };

  return (
    <ul className={menulistClasses} style={{ minHeight: '536px' }}>
      <ShowList menuLists={menuLists} onClick={props.menuToggle} userPageAccess={props.userPageAccess} />
    </ul>
  );
};

export default Menulist;
