import React, {
    forwardRef,
    Fragment,
    useImperativeHandle,
    useRef, useState,
} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "@material-ui/core";

export default forwardRef((props, ref) => {
    const inputRef = useRef(null);
    const [sortBy, setSortBy] = useState(null)

    // we need this
    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
        return {};
    });

    const getSortingStatus = () =>{
        let status = props.renderSortingStatus(props.column.colId)

        setSortBy(status[0]?.sortBy)
    }

    const onClickSortingChange = () => {
        props.onSortingChange(props.column.colId, getSortingStatus)
    }

    return (
        <Fragment>
            <div className="ag-header-cell-label" onClick={()=>onClickSortingChange()}>
                <span  className="ag-header-cell-text" unselectable="on">{props.displayName}</span>
                {props.toolTip && <>
                    <Tooltip title={`${props.toolTip?.title}`} placement="top" arrow style={{paddingLeft:"5px"}}>
                        <span><FontAwesomeIcon icon={faInfoCircle} /></span></Tooltip>
                </>
                }
                <span  className={`ag-header-icon ag-header-label-icon ag-sort-ascending-icon ${sortBy !== 'asc' && 'ag-hidden'}`} aria-hidden="true">
                    <span className="ag-icon ag-icon-asc" unselectable="on" role="presentation"></span>
                </span>
                <span className={`ag-header-icon ag-header-label-icon ag-sort-descending-icon ${sortBy !== 'desc' && 'ag-hidden'}`} aria-hidden="true">
                    <span className="ag-icon ag-icon-desc" unselectable="on" role="presentation"></span>
                </span>
            </div>
        </Fragment>
    );
});