import React from 'react';
import { withRouter } from 'react-router-dom';
import './comment.scss';

class Comments extends React.Component {
  render() {
    return (
      <div className="comment">
        <p className="comment-header">{this.props.author} ({this.props.date})</p>
        <p className="comment-body">- {this.props.body}</p>
        <div className="comment-footer">

        </div>
      </div>
    );
  }
  _deleteComment() {
    alert("-- DELETE Comment Functionality COMMING SOON...");
  }
}

class CommentsLatest extends React.Component {
  render() {
    return (
      <div className="comment">
        <p className="comment-header">{this.props.author} ({this.props.date})</p>
        <p className="comment-body">- {this.props.body}</p>
        <div className="comment-footer">

        </div>
      </div>
    );
  }
  _deleteComment() {
    alert("-- DELETE Comment Functionality COMMING SOON...");
  }
}

const baseURL = `${process.env.REACT_APP_API_URL}`;

class Comment extends React.Component {

  constructor() {
    super();

    this.state = {
      showComments: false,
      comments: []
    };
  }

  abortController = new AbortController();

  fetchcomments() {

    const { accesstoken } = this.props;

    const url = `${baseURL}/tfs/comments_summary/?submission_id=${this.props.submission_id}&tz=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
    this.abortController.abort();
    this.abortController = new AbortController();
    const signal = this.abortController.signal;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then((jsonStr) => {
        this.setState({ comments: jsonStr });
      })
      .catch(e => console.log('commente', e));


    fetch(`${baseURL}/tfs/comments_summary_latest/?submission_id=${this.props.submission_id}&tz=${Intl.DateTimeFormat().resolvedOptions().timeZone}`, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then((jsonStr) => {
        this.setState({ commentslatest: jsonStr });
      })
      .catch(e => console.log('commente', e));
  };

  submitcomments() {

    const { accesstoken } = this.props;

    const url = `${baseURL}/tfs/comment_save/`;
    this.abortController.abort();
    this.abortController = new AbortController();
    const signal = this.abortController.signal;
    fetch(url, {
      signal: signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      },
      body: JSON.stringify(
        {
          submission_id: this.props.submission_id,
          comments: this._body.value,
        }
      )
    })
      .then(response => response.json())
      .then((jsonStr) => {
        this.fetchcomments()
      })
      .catch(e => console.log('commente', e));
  };

  componentDidMount() {
    if (this.props.submission_id !== 'create') {
      this.fetchcomments();
    }


  }

  _getComments() {
    return this.state.comments.map((comment) => {
      return (
        <Comments
          date={comment.comment_date_format}
          author={comment.user_email}
          body={comment.comments}
          key={comment.comment_id} />
      );
    });
  }

  _getCommentsLatest() {
    return this.state.commentslatest.map((comment) => {
      return (
        <CommentsLatest
          date={comment.comment_date_format}
          author={comment.user_email}
          body={comment.comments}
          key={comment.comment_id} />
      );
    });
  }

  _getCommentsTitle(commentCount) {
    if (commentCount === 0) {
      return 'No comments yet';
    } else if (commentCount === 1) {
      return "1 comment";
    } else {
      return `${commentCount} comments`;
    }
  }

  _handleClick() {
    this.setState({
      showComments: !this.state.showComments
    });
  }

  _addComment(author, body) {
    const comment = {
      id: this.state.comments.length + 1,
      author,
      body
    };
    this.setState({ comments: this.state.comments.concat([comment]) }); // *new array references help React stay fast, so concat works better than push here.
  }

  _handleSubmit(event) {
    event.preventDefault();   // prevents page from reloading on submit
    return false;
    // let author = this._author.value;
    // let body = this._body.value;
    // const comment = {
    //     id: this.state.comments.length + 1,
    //     author,
    //     body
    //   };
    //   this.setState({ comments: this.state.comments.concat([comment]) })
  }

  render() {
    let commentsLatest;
    if (this.state.commentslatest) {
      commentsLatest = this._getCommentsLatest();
    }
    const comments = this._getComments();

    let commentNodes;
    let commentsNodesLatest;
    commentsNodesLatest = <div className="comment-list-latest">{commentsLatest}</div>;
    let buttonText = 'Show Comments';

    if (this.state.showComments) {
      buttonText = 'Hide Comments';
      commentNodes = <div className="comment-list">{comments}</div>;
      commentsNodesLatest = '';
    }
    return (
      <div className="comment-box">
        <h2>Comments</h2>
        <form className="comment-form" onSubmit={this._handleSubmit.bind(this)}>
          <div className="comment-form-fields">
            {/* <input placeholder="Name" required ref={(input) => this._author = input}></input><br /> */}
            <textarea id="commentTextArea" placeholder="Comment" rows="4" required ref={(textarea) => this._body = textarea}></textarea>
          </div>
          <div className="comment-form-actions">
            {this.props.postcomment ? <button type="button" className="btn-focus" onClick={e => this.submitcomments()} >Post Comment</button> : null}
            {/* <button type="submit">Post Comment</button> */}
          </div>
        </form>
        <button id="comment-reveal" className="btn-neutral" style={{border:"1px solid #bdb9b9"}} onClick={e => this._handleClick()}>{buttonText}</button>
        <h3>Comments</h3>
        <h4 className="comment-count">
          {this._getCommentsTitle(comments.length)}
        </h4>
        {commentsNodesLatest}
        {commentNodes}
      </div>
    );
  };
}

export default withRouter(Comment);