/* global gapi */
import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { gapi } from 'gapi-script'
import Toolbar from './components/toolbar/Toolbar';
import Menubar from './components/menubar/Menubar';
import Home from './components/Home';
import TaxSubmissions from './components/TaxSubmissions';
import ManageFlow from './components/ManageFlow';
import './App.css';
import Createsubmission from './components/createsubmission';
import Editsubmission from './components/editsubmission';
import TaxSubmissionScheduleInfo from './components/TaxSubmissionScheduleInfo';
import 'react-tabs/style/react-tabs.css';
import IdleTimer from 'react-idle-timer';
import Modal from 'react-modal';
import AlertDialog from "./components/Auth/AlertDialog";

const baseURL = `${process.env.REACT_APP_API_URL}`;
var PAGE_ACCESS = new Map();

const warningStyles = {
  content: {
    top: '40%',
    left: '50%',
    right: '50%',
    bottom: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '350px',
    height: '120px',
    transition: 'ease-in-out',
    float: 'left'
  }
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cookies: this.props.cookies.get('Logins'),
      token: false,
      UserName: '',
      UserID: '',
      UserEmail: '',
      accesstoken: '',
      useraccess: [],
      render: false,
      userPageAccess: [],
      userPageAccessEntity: [],
      isReadOnly: true,
      countApproval: 0,
      selectedTabIndex: 1, //default my approval tab
      isTimedOut: false,
      access: null,
      timerInMS: 1000 * 60 * 60 * 24 // 24 hrs
    }
    this.idleTimer = React.createRef();
    this._onIdle = this._onIdle.bind(this);
    this._onActive = this._onActive.bind(this);
    this.logout = this.logout.bind(this);
    this.continueSession = this.continueSession.bind(this);
  }

  fetchUserPageAccess = (accessToken) => {
    const url = `${baseURL}/tfs/my_role_page_access/`;
    const urlent = `${baseURL}/tfs/my_role_page_access_entity/`;
    fetch(url, {
      signal: AbortController.signal,
      method: 'GET',
      headers: { 'Authorization': `Bearer google-oauth2 ${accessToken}` }
    }).then(result => result.json())
      .then(userPageAccess => {
        let access = false;

        if(userPageAccess.length > 0){
          access = true
        }

        this.setState({ userPageAccess, access })

        userPageAccess.forEach(element => {
          PAGE_ACCESS.set(element["page_id"], element["access_type"]);
          if (PAGE_ACCESS.get(1) === "FULL_ACCESS") {
            this.setState({ isReadOnly: false });
          }
        })
      })

    fetch(urlent, {
      signal: AbortController.signal,
      method: 'GET',
      headers: { 'Authorization': `Bearer google-oauth2 ${accessToken}` }
    }).then(result => result.json())
      .then(userPageAccessEntity => this.setState({ userPageAccessEntity }))
  }

  fetchCurrentUser = (accessToken) => {
    const url = `${baseURL}/tfs/current_user/`;
    var userId = 0;
    fetch(url, {
      signal: AbortController.signal,
      method: 'GET',
      headers: { 'Authorization': `Bearer google-oauth2 ${accessToken}` }
    }
    ).then(response => response.json())
      .then(user => {
        user.forEach(element => {
          userId = element["user_email"];
        });
        this.setState({ userId: userId });
      });
  }

  componentDidMount() {
    window.gapi.load('auth2', () => {
      window.gapi.auth2.init({
        client_id: `${process.env.REACT_APP_CLIENT_ID}`,
        cookie_policy: 'none'
      }).then(() => {
        this.setState({ token: gapi.auth2.getAuthInstance().isSignedIn.get() })
        if (this.state.token) {
          this.setState({ accessToken: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token, UserEmail: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail() })
          if (this.props.cookies.get('Logins') === "Test") {
            this.props.cookies.set('Logins', gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getId(), { maxAge: 432000, sameSite: false })
          }
          this.setState({ cookies: this.props.cookies.get('Logins') })
          if (this.state.cookies !== "Test" && this.state.cookies) {
            this.fetchUserPageAccess(gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token);
            this.fetchCurrentUser(gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token);
            if (this.props.history.location.hash !== "" && this.props.history.location.hash.includes('state')) {
              var url = this.props.history.location.hash.split("=")[1];
              this.props.history.push(url.replace('&scope', ''));
            }
          } else {
            window.URLRequested = this.props.history.location.pathname;
            this.props.history.push('/Login');
          }
        } else {
          window.URLRequested = this.props.history.location.pathname;
          this.props.history.push('/Login');
        }
      })
    });

  };

  setRender = () => {
    this.setState({ render: true })
  }

  state = {
    isMenuExpanded: false
  };

  toggleMenuHandler = () => {
    this.setState((prevState) => {
      return ({ isMenuExpanded: !prevState.isMenuExpanded });
    });
  };

  _onActive = (e) => {
    this.setState({ isTimedOut: false })
  }

  _onIdle = (e) => {
    const { getopenWarningModal } = this.state;
    if (getopenWarningModal === false || getopenWarningModal === undefined) {
      this.idleTimer.current.reset();
      this.setState({ timerInMS: 1000 * 60 * 1, getopenWarningModal: true }) //additional 1 min
    }
    else
      this.logout();
  }

  continueSession() {
    this.idleTimer.current.reset();
    this.setState({ timerInMS: 1000 * 60 * 60 * 24, getopenWarningModal: false });
  }

  logout() {
    window.gapi.load('auth2', () => {
      window.gapi.auth2.init({
        client_id: `${process.env.REACT_APP_CLIENT_ID}`,
        cookie_policy: 'none'
      }).then(() => {
        gapi.auth2.getAuthInstance().disconnect();
        this.props.cookies.remove('Logins')
        window.location.href = '/Login';
      })
    })
  }

  render() {
    return (
      <React.Fragment>
        <IdleTimer
          key={'idleTimer'}
          startOnMount={true}
          ref={this.idleTimer}
          element={document}
          onIdle={this._onIdle}
          timeout={this.state.timerInMS}
        />
        {this.state.getopenWarningModal &&
          <Modal
            isOpen={this.state.getopenWarningModal}
            onRequestClose={e => this.setState({ getopenWarningModal: false })}
            style={warningStyles}
          >
            <div className="fscp-btn-wrapper">
              <label className="label">Session Expiring</label><br />
              <p><span className="span-gray">Your session is about to expire due to inactivity</span></p>
              <p><span className="span-gray">Click Ok to continue or click Cancel to logout the session</span></p>
              <br></br>
              <button className="button-warning" onClick={this.logout}>Cancel</button>
              <button className="button-warning" onClick={this.continueSession}>OK</button>
            </div>
          </Modal>
        }
        <Router>
          <div className="App" style={{height:'98vh'}}>
            <Toolbar isMenuExpanded={this.state.isMenuExpanded} menuToggle={this.toggleMenuHandler} />
            <Menubar initMenu={this.state.isMenuExpanded} isMenuExpanded={this.state.isMenuExpanded} menuToggle={this.toggleMenuHandler} userPageAccess={this.state.userPageAccess} />
            {this.state.access &&
            <Switch>
              <Route exact path='/' render={(props) =>
                  (<Home {...props} isMenuExpanded={this.state.isMenuExpanded}
                         menuToggle={this.toggleMenuHandler} userPageAccess={this.state.userPageAccess}
                      />
                  )
              }
              />
              <Route exact path='/manageflow' render={(props) =>
                  (<ManageFlow {...props} isMenuExpanded={this.state.isMenuExpanded}
                               menuToggle={this.toggleMenuHandler} userId={this.state.userId}
                      />
                  )
              }
              />
              <Route exact path='/taxsubmissionscheduleinfo' render={(props) =>
                  (<TaxSubmissionScheduleInfo {...props} isMenuExpanded={this.state.isMenuExpanded}
                                              menuToggle={this.toggleMenuHandler} userId={this.state.userId}
                      />
                  )
              }
              />
              <Route exact path='/taxsubmissions/submission/:submission_id' render={(props) =>
                (<Editsubmission {...props} isMenuExpanded={this.state.isMenuExpanded}
                  menuToggle={this.toggleMenuHandler} userId={this.state.userId}
                />)
              }
              />
              <Createsubmission exact path='/createsubmission' userId={this.state.userId} />
              <Route exact path='/taxsubmissions' render={(props) => (
                  <TaxSubmissions {...props}
                                  accessToken={this.state.accessToken}
                                  isReadOnly={this.state.isReadOnly}/>
              )}/>
            </Switch>
            }
          </div>
        </Router>
        {this.state.access === false&&
        <AlertDialog/>
        }
      </React.Fragment>
    );
  }
}

export default withCookies(withRouter(App));
