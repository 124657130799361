import React from 'react';
import ApprovalLogo from '../images/sprite/flow_approve.png'
import EmptyLogo from '../images/sprite/flow_empty.png'
import ArrowLogo from '../images/sprite/Arrow.png'

const ShowList = props => {
    const theLists = props.flow.map((flow, i) => {
        return (
            <div>
                <div style={{ inlineSize: 'inherit' }}>
                    <div className="flow-container" style={{ height: '100%', fontWeight: 'bold' }}>
                        <table className="table-flow">
                            <tr >
                                <td style={flow.approve_date_format ? { inlineSize: 'inherit', background: 'lightgreen' } : flow.skipped === "Y" ? { inlineSize: 'inherit', background: 'darkgrey' } : { inlineSize: 'inherit' }} className="td-flow" colspan="2">
                                    {flow.skipped === "Y" ? flow.role_flow + " (SKIPPED)" : flow.role_flow}
                                </td>
                            </tr>
                            <tr>
                                <td className="td-image">
                                    <img src={flow.approve_date_format ? ApprovalLogo : EmptyLogo} className="approval-logos" />
                                </td>
                                <td className="td-emaildate">
                                    <tr>
                                        {flow.first_name + " " + flow.last_name}
                                    </tr>
                                    <tr className="tr-date">
                                        {flow.user_email}
                                    </tr>
                                    <tr className="tr-date">
                                        {flow.approve_date_format}
                                    </tr>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div>
                    {props.flow.length === i + 1 ? null : <img src={ArrowLogo} className="arrow-logos" />}
                </div>
            </div>
        )
    })
    return theLists;
}

const ShowFlow = props => {
    return (
        <div className="content" onClick={props.isMenuExpanded ? props.menuToggle : null}>
            <div className="menu-container" style={{ height: '100%' }}>
                <ul className="flow-list">
                    {props.flow.length > 0 ? <ShowList flow={props.flow} /> : <label className="asterisk" >Unable to find approver for {props.entity} {props.taxType} <br></br> Please contact HQ Tax team for assistance.</label>}
                </ul>
            </div>
        </div>
    );
};


export default ShowFlow;
