import React, { Component } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class ExportManageFlow extends Component {
    renderTableHeader() {
        return (
            <>
                <th key="Country">Country</th>
                <th key="Entity">Entity</th>
                <th key="Tax Type">Tax Type</th>
                <th key="firstreview">1st Review</th>
                <th key="secondreview">2nd Review</th>
                <th key="thirdreview">3rd Review</th>
                <th key="fourthreview">4th Review</th>
                <th key="fifthreview">5th Review</th>
                <th key="sixthreview">6th Review</th>
                <th key="seventhreview">7th Review</th>
                <th key="eigthreview">8th Review</th>
                <th key="Final Review">Final Review</th>
                <th key="Post Review Submission">Post Review Submission</th>
                <th key="Post Review Approve">Post Review Approve</th>
                <th key="Update Payment Info">Update Payment Info</th>
                <th key="Payment Amount Verification">Payment Amount Verification</th>
            </>
        )
    }

    setHTML(props) {
        return props ? { __html: props.replace(/<div>/g, "<br style=mso-data-placement:same-cell; />").replace(/<\/div>/g, "").replace(/<br>/g, "<br style=mso-data-placement:same-cell; />") } : { __html: props }
    }

    renderTableData() {
        const { checklists } = this.props;
        return checklists.map((checklist) => {
            const { flow_id, entity_id__country_id, entity_id, tax_type_id__tax_type, firstreview, secondreview, thirdreview, fourthreview, fifthreview, sixthreview, seventhreview, eigthreview, finalreview, postreviewsubmission,
                postreviewapprove, updatepaymentinfo, paymentamountverification
            } = checklist;
            return (
                <tr key={flow_id}>
                    <td>{entity_id__country_id}</td>
                    <td>{entity_id}</td>
                    <td>{tax_type_id__tax_type}</td>
                    <td>{firstreview}</td>
                    <td>{secondreview}</td>
                    <td>{thirdreview}</td>
                    <td>{fourthreview}</td>
                    <td>{fifthreview}</td>
                    <td>{sixthreview}</td>
                    <td>{seventhreview}</td>
                    <td>{eigthreview}</td>
                    <td>{finalreview}</td>
                    <td>{postreviewsubmission}</td>
                    <td>{postreviewapprove}</td>
                    <td>{updatepaymentinfo}</td>
                    <td>{paymentamountverification}</td>
                </tr>
            )
        })
    }

    render() {
        const fileName = `Tax Item Flow`;
        return (
            <>
                <ReactHTMLTableToExcel
                    id="FscpDetail-Export"
                    className={"fscp-btn btn-focus"}
                    table="FscpDetail-Table"
                    filename={fileName}
                    sheet={fileName}
                    buttonText="Export" />
                <table hidden id="FscpDetail-Table">
                    <tbody>
                        <tr>{this.renderTableHeader()}</tr>
                        {this.renderTableData()}
                    </tbody>
                </table>
            </>
        );
    }
}

export default ExportManageFlow;