import React from 'react';
var PAGE_ACCESS = [];

const menuLists = [
    {
        listID: 'hl1',
        linkTo: '/taxsubmissions',
        linkName: 'menu-icon sprite-fscp',
        spanName: 'menu-name',
        spanDesc: 'Tax Submissions',
        spanLongDesc: 'Tax Submissions',
        spanaccessname:'TaxSubmissions'
    },
    {
        listID: 'hl2',
        linkTo: '/manageflow',
        linkName: 'menu-icon sprite-others',
        spanName: 'menu-name',
        spanDesc: 'Manage Approval Tax Item',
        spanLongDesc: 'Manage Approval Tax Item',
        spanaccessname:'ManageApproval'
    },           
    {
      listID: 'hl3',
      linkTo: '/taxsubmissionscheduleinfo',
      linkName: 'menu-icon sprite-hq-input',
      spanName: 'menu-name',
      spanDesc: 'Schedule Information',
      spanLongDesc: 'Tax Submission Schedule Information',
      spanaccessname:'ScheduleInfo'
  },     
];

const ShowList = props => {
    var upa = props.userPageAccess;
    PAGE_ACCESS.length = 0;
  
    for (var i = 0; i < upa.length; i++){
      PAGE_ACCESS.push(upa[i].page_name);
    }

    const mLists = props.menuLists;
    const linkNames = require('classnames'), spanNames = require('classnames');
    const theLists = mLists.map((mList,i) => {
      if (PAGE_ACCESS[i] == mList.spanaccessname) {
        return ( 
          <li key={mList.listID}>
            <a href={mList.linkTo}>
              <span className={linkNames(mList.linkName)}></span>
            </a>
            <span className={spanNames(mList.spanName)}>
            <a href={mList.linkTo}>{mList.spanDesc} </a>
            </span>
            <span>{mList.spanLongDesc}</span>
          </li>
        )
      }
      else
        return null;
      })    
    return theLists;                
  }

const Home = props => {
    return (
        <div className="content" onClick={props.isMenuExpanded ? props.menuToggle : null}>
            <div className="menu-container" style={{height: '100%'}}>
                <ul className="menu-list">
                    <ShowList menuLists={menuLists} userPageAccess={props.userPageAccess}/>
                </ul>
            </div>
        </div>
    );
};


export default Home;
