/* global gapi */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { gapi } from 'gapi-script'

import { withCookies } from 'react-cookie';

class toolbar extends Component {
    state = {
        UserName: '',
    }

    componentDidMount() {
        window.gapi.load('auth2', () => {
            window.gapi.auth2.init({
                client_id: `${process.env.REACT_APP_CLIENT_ID}`,
                cookie_policy: 'none'
            }).then(() => {
                if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
                    this.setState({ UserName: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getName() })
                }
            })
        })
    }

    logout = () => evt => {
        window.location.href = 'https://' + window.location.hostname.replace('tfs', 'workspace');
    };

    render() {
        return (
            <header className="toolbar" onClick={this.props.isMenuExpanded ? this.props.menuToggle : null}>
                <div className="toolbar-container">
                    <div>
                        <a href={window.location.hostname === 'localhost' ? 'http://localhost:3004/' :
                            'https://' + window.location.hostname.replace('tfs', 'workspace')} className="fl logo"></a>
                    </div>
                    <div className="spacer" />
                    <div>
                        <Link to='/' className="toolbar-title">Tax Filing System</Link>
                    </div>
                    <div className="spacer" />
                    <div className="login-info fr">
                        <Link to='/' className="fl">{this.state.UserName}</Link>
                        <Link to='/' className="btn-logout fl" style={{ cursor: 'pointer' }} onClick={this.logout()}>Logout</Link>
                    </div>
                </div>
            </header>
        )
    }
}

export default withCookies(toolbar);