/* global gapi */
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import Select from 'react-select';
import ReactTable from "react-table";
import './sea-react-table.css';
//import "react-table/react-table.css";
import { Link, withRouter, useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import Comments from './Comments';
import ShowFlow from './showflow';
import ExportHighlightReport from './ExportHighlightReport';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import AmountDetailVAT from './AmountDetailVAT';
import './FileDrop.css';
import SubmissionHistory from './submissionhistory';
import AttachmentLogo from '../images/sprite/attachedfiles.png'
import 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import "react-datepicker/dist/react-datepicker.css";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import AmountDetailCIT from './AmountDetailCIT';
import AmountDetailWHT from './AmountDetailWHT';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import LastSubmissionHistory from './lastsubmissionhistory';
import FileEvent from "./FileEvent";
const baseURL = `${process.env.REACT_APP_API_URL}`;

const selectStyle = {
  container: base => ({
    ...base,
    width: 250,
    minwidth: 250,
    maxwidth: 250,
    height: 40,
    maxheight: 38,
    marginLeft: 0
  })
};

const selectStyleReject = {
  container: base => ({
    ...base,
    width: 400,
    height: 40,
    marginLeft: 0
  })
};


const rejectStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '30%',
    height: '60%',
    transform: 'translate(-50%, -50%)',
    overflow: 'unset'
  }
};

const AttachmentStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '50%',
    height: '50%',
    transform: 'translate(-50%, -50%)',
  }
};

const numericStyles = {
  zIndex: 0,
  fontWeight: 'bold'
};

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: '50%',
    bottom: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '85%'
  }
};

const SaveStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '22%',
    height: '25%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(255,255,255,0.5)',
    opacity: 0.8,
    outline: 'none',
    border: '1px solid #ffffff'
  },
  overlay: {
    zIndex: 1000
  },
};

const formLabelsTheme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131"
        }
      }
    }
  }
});

let Entities = [];
let TaxTypes = [];
let Reject = [];
let PaymentOptions =
  [{ label: "Giro arrangements in place, and sufficient funds in bank account for GIRO deductions", value: "GIRO" },
  { label: "Cheque payment applicable?", value: "Cheque" },
  { label: "Not Applicable", value: "NA" },
  { label: "Cash", value: "Cash" }
  ];
let getattachmentlist = []


class EditSubmissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accesstoken: '',
      UserID: '',
      username: '',
      cookies: this.props.cookies.get('Logins'),
      checklists: [],
      submission: [],
      isSearch: false,
      isCreate: false,
      isChecklistsExist: false,
      selectedEntity: 'All',
      selectedTaxType: 'None',
      submissionStatus: null,
      selectedChecklistIndex: null,
      showHistory: false,
      showUpdateStatus: false,
      showCreateAlert: false,
      selectedChecklistId: null,
      entityId: null,
      periodId: null,
      accessType: null,
      render: false,
      checklistsHighlight: [],
      createDate: new Date(),
      // dueDate: new Date(),
      // DateFrom: null,
      // DateTo: new Date(),
      selectedPayOption: '',
      attachmentList: [],
      attachmentDraft: [],
      attachmentFinal: [],
      attachmentTaxReturns: [],
      attachmentPayment: [],
      getattachmentDraft: [],
      getattachmentFinal: [],
      getattachmentTaxReturns: [],
      getattachmentPayment: [],
      actual_paid_date: null,
      modalIsOpen: false,
      rowDataGridWHT: [],
      rowDataGridCIT: [],
      type: '',
      taxPercentCIT1: "",
      taxPercentCIT2: "",
      taxPercentCIT3: "",
      totalAmount1: 0,
      totalAmount2: 0,
      totalAmount3: 0,
      selectedRejectUser: '',
      exchangeRateIndicative: 0,
      prevAmount1: "",
      prevAmount2: "",
      prevAmount3: "",
      prevStatus1: "",
      prevStatus2: "",
      prevStatus3: "",
      isAllowAccessAttachment: false,
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.amountClick = this.amountClick.bind(this);
    this.getTotalAmount = this.getTotalAmount.bind(this);
    this.getTotalAmount1 = this.getTotalAmount1.bind(this);
    this.getTotalAmount2 = this.getTotalAmount2.bind(this);
    this.getTotalAmount3 = this.getTotalAmount3.bind(this);
    this.getTaxPercentCIT = this.getTaxPercentCIT.bind(this);
    this.getTaxPercentCIT2 = this.getTaxPercentCIT2.bind(this);
    this.getTaxPercentCIT3 = this.getTaxPercentCIT3.bind(this);
  };

  abortController = new AbortController();

  onChangeHandler = (selectedOption, compProps) => {
    const selectedValue = selectedOption.value, name = compProps.name, order = selectedOption.order;
    const { isSearch, selectedTaxType, selectedEntity } = this.state;
    if (isSearch && (selectedTaxType !== selectedValue || selectedEntity !== selectedValue || selectedValue === 'All')) {
      this.setState({ isSearch: false });
    }
    switch (name) {
      case 'entity':
        this.setState({ selectedEntity: selectedValue },
          () => {
            this.cleanUpDropdown('taxtype');
            this.populateTaxType(selectedValue);
          });
        break;
      case 'paymentOption':
        this.setState({ selectedPayOption: selectedValue });
        break;
      case 'reject':
        this.setState({ selectedRejectUser: selectedValue, selectedRejectOrder: order })
        break;
      default:
        break;
    };
  };

  cleanUpDropdown = (type) => {
    switch (type) {
      case 'taxtype':
        TaxTypes.length = 0;
        //TaxTypes.push({label:"None",value:"None"});
        break;
      case 'entity':
        Entities.length = 0;
        //TaxTypes.push({label:"None",value:"None"});
        break;
      default:
        break;
    };
  }

  componentDidMount() {
    if (this.state.cookies !== "Test" && this.state.cookies) {
      window.gapi.load('auth2', () => {
        window.gapi.auth2.init({
          client_id: `${process.env.REACT_APP_CLIENT_ID}`,
          cookie_policy: 'none'
        }).then(() => {
          try {
            gapi.auth2.getAuthInstance().currentUser.listen((usr) => this.setState({ accesstoken: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token, username: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail() }))
          } catch (error) {
            console.log("trycatch", error)
          }
          this.setState({ accesstoken: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token, username: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail() })
          this.populateEntities();
          this.fetchsubmission()
          this.fetchFlow()
          this.getattachment(this.props.match.params.submission_id)
        })
      })
    } else {
      gapi.auth2.getAuthInstance().disconnect()
      window.location.href = '/Login';
    }
  };

  getType(next_order) {
    if (Number(next_order) < 9) {
      this.setState({ type: "Draft" });
    }
    else if (Number(next_order) >= 9 && Number(next_order) < 12) {
      this.setState({ type: "Draft" });
    }
    else if (Number(next_order) === 12) {
      this.setState({ type: "Actual" });
    }
    else {
      this.setState({ type: "Draft" });
    }
  }

  populateRowDataGrid(tax_category) {
    const signal = this.abortController.signal;
    const { accesstoken, type } = this.state;

    if (tax_category === "VAT") {
      const url = `${baseURL}/tfs/vat_submission/?submission_id=${this.props.match.params.submission_id}&type=${type}`;
      fetch(url, {
        signal: signal,
        method: 'GET',
        headers: { 'Authorization': `Bearer google-oauth2 ${accesstoken}` }
      }
      ).then(result => result.json())
        .then(rowDataGridVAT => this.setState({ rowDataGridVAT }))
    }

    if (tax_category === "CIT") {
      const url = `${baseURL}/tfs/cit_submission/?submission_id=${this.props.match.params.submission_id}&type=${type}&tab=1`;
      fetch(url, {
        signal: signal,
        method: 'GET',
        headers: { 'Authorization': `Bearer google-oauth2 ${accesstoken}` }
      }
      ).then(result => result.json())
        .then(rowDataGridCIT =>

          this.setState({ rowDataGridCIT, taxPercentCIT: rowDataGridCIT.filter(filter => { return filter.account === 'tax_%' })[0].amount })
        )
    }

    if (tax_category === "WHT") {
      const url = `${baseURL}/tfs/wht_submission/?submission_id=${this.props.match.params.submission_id}&type=${type}`;
      fetch(url, {
        signal: signal,
        method: 'GET',
        headers: { 'Authorization': `Bearer google-oauth2 ${accesstoken}` }
      }
      ).then(result => result.json())
        .then(rowDataGridWHT => this.setState({ rowDataGridWHT }))
    }
  }

  getTaxPercentCIT(taxPercentCIT) {
    this.setState({ taxPercentCIT: taxPercentCIT })
  }

  getTaxPercentCIT2(taxPercentCIT) {
    this.setState({ taxPercentCIT2: taxPercentCIT })
  }

  getTaxPercentCIT3(taxPercentCIT) {
    this.setState({ taxPercentCIT3: taxPercentCIT })
  }

  fetchsubmission() {
    const { accesstoken } = this.state;
    const url = `${baseURL}/tfs/submission_summary_edit/?submission_id=${this.props.match.params.submission_id}&tz=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then((jsonStr) => {
        this.fieldcontrol(jsonStr[0].next_role, jsonStr[0].status)
        this.getType(jsonStr[0].next_order)
        this.populateRowDataGrid(jsonStr[0].tax_category)

        if (this.props.location.state) {
          this.setState({ pagetype: this.props.location.state.pagetype })
        }
        if (jsonStr[0].actual_paid_date !== null) {
          this.setState({ actual_paid_date: moment(jsonStr[0].actual_paid_date).toDate() })
        }
        if (jsonStr[0].exchange_rate_indicative !== null || jsonStr[0].exchange_rate_indicative !== "") {
          this.setState({ exchangeRateIndicative: jsonStr[0].exchange_rate_indicative })
        }

        this.setState({
          submission: jsonStr, submission_id: this.props.match.params.submission_id, selectedPayOption: jsonStr[0].payment_options
          , dueDate: moment(jsonStr[0].due_date).toDate(), DateFrom: moment(jsonStr[0].tax_filling_month_from).toDate(), DateTo: moment(jsonStr[0].tax_filling_month_to).toDate(),
          submissionStatus: jsonStr[0].status
        })
      }).catch(error => console.log(error));
  };

  fetchPrevSubmission(entity_id, tax_type_id) {
    const { accesstoken } = this.state;
    const url = `${baseURL}/tfs/last_submission_summary_edit/?entity_id=${entity_id}&tax_type_id=${tax_type_id}&tz=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then((res) => {
        this.setState({
          prevAmount1: res[1].amount, prevStatus1: res[1].status,
          prevAmount2: res[2].amount, prevStatus2: res[2].status,
          prevAmount3: res[3].amount, prevStatus3: res[3].status
        })
      }).catch(error => console.log(error));
  }

  getattachment(submission_id) {
    const { accesstoken } = this.state;

    const url = `${baseURL}/tfs/submission_attachment/?submission_id=${submission_id}`;

    fetch(url, {
      //   signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then((jsonStr, i) => {

        if(jsonStr.detail && jsonStr.detail === 'You do not have permission to perform this action.'){
          this.setState({isAllowAccessAttachment: false})
          return;
        } else {
          this.setState({isAllowAccessAttachment: true})
        }

        let fileList
        this.state.getattachmentDraft.length = 0
        this.state.getattachmentFinal.length = 0
        this.state.getattachmentTaxReturns.length = 0
        this.state.getattachmentPayment.length = 0
        for (var i = 0; i < jsonStr.length; i++) {
          switch (jsonStr[i].attachment_type) {
            case 'draft':
              fileList = this.state.getattachmentDraft
              fileList.push({ attachment_id: jsonStr[i].attachment_id, filename: jsonStr[i].filename, change: true, type: jsonStr[i].attachment_type, created_by: jsonStr[i].created_by })
              break;
            case 'final':
              fileList = this.state.getattachmentFinal
              fileList.push({ attachment_id: jsonStr[i].attachment_id, filename: jsonStr[i].filename, change: true, type: jsonStr[i].attachment_type, created_by: jsonStr[i].created_by })
              break;
            case 'tax_returns':
              fileList = this.state.getattachmentTaxReturns
              fileList.push({ attachment_id: jsonStr[i].attachment_id, filename: jsonStr[i].filename, change: true, type: jsonStr[i].attachment_type, created_by: jsonStr[i].created_by })
              break;
            case 'payment':
              fileList = this.state.getattachmentPayment
              fileList.push({ attachment_id: jsonStr[i].attachment_id, filename: jsonStr[i].filename, change: true, type: jsonStr[i].attachment_type, created_by: jsonStr[i].created_by })
              break;
          }
        }
        this.setState({ renderDelete: true })
      }).catch(error => console.log(error));
  };

  fetchFlow() {
    const { accesstoken } = this.state;
    const url = `${baseURL}/tfs/flow_submissions_summary_edit/?submission_id=${this.props.match.params.submission_id}&tz=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then((jsonStr) => {
        this.setState({ flow: jsonStr })
      }).catch(error => console.log(error));
  };

  fieldcontrol(next_role, submissionStatus) {
    if (next_role === '') {
      this.setState({
        fieldDueDate: 'Read',
        fieldAmount: submissionStatus === "Closed" ? 'Write' : 'Read',
        fieldPaymentOptions: 'Read',
        fieldDraftAttachment: 'Read',
        fieldPostReviewAttachment: 'Read',
        fieldFinalAmount: 'Read',
        fieldFinalAttachment: 'Read',
        fieldComments: 'Read',
        fieldActualPaidAmount: 'Read',
        fieldActualPaidDate: 'Read',
        fieldPaymentAttachment: 'Read',
        fieldTaxFillingMonthFrom: 'Read',
        fieldTaxFillingMonthTo: 'Read'
      })
    }
    else {
      const url = `${baseURL}/tfs/field_control/?role=${next_role}`;
      fetch(url, {
        signal: AbortController.signal,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer google-oauth2 ${this.state.accesstoken}`
        },
      }
      ).then(response => response.json())
        .then(data => {
          this.setState({
            fieldDueDate: data[0].due_date,
            fieldAmount: data[0].amount,
            fieldPaymentOptions: data[0].payment_options,
            fieldDraftAttachment: data[0].draft_attachment,
            fieldPostReviewAttachment: data[0].post_review_attachment,
            fieldFinalAmount: data[0].final_amount,
            fieldFinalAttachment: data[0].final_attachment,
            fieldComments: data[0].comments,
            fieldActualPaidAmount: data[0].actual_paid_amount,
            fieldActualPaidDate: data[0].actual_paid_date,
            fieldPaymentAttachment: data[0].payment_attachment,
            fieldTaxFillingMonthFrom: data[0].tax_filing_month_from,
            fieldTaxFillingMonthTo: data[0].tax_filing_month_to
          })
        }).
        catch(e => console.log('error', e))
    }


  }

  populateEntities() {
    const url = `${baseURL}/tfs/entity_list/`;
    const signal = this.abortController.signal;
    const { accesstoken } = this.state;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          Entities.push({ label: element["entity_id"], value: element["entity_id"] });
        })
      });
  };

  populateTaxType(selectedEntity) {
    const url = `${baseURL}/tfs/tax_type/?entity_id=${selectedEntity}`;
    const signal = this.abortController.signal;
    const { accesstoken } = this.state;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          TaxTypes.push({ label: element["tax_type"], value: element["tax_type_id"] });
        })
      });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.submission.length >= 1 || this.state.renderDelete === true) {
      return true
    }
    else {
      return false

    }
  }

  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    const { style } = this.subtitle;
  };

  ApproveSubmission() {
    const url = `${baseURL}/tfs/update_submission/`;
    let actual_paid_date = this.state.actual_paid_date
    let amount = 0;
    let amountOth = 0;
    let taxCategory = this.state.submission[0].tax_category
    const { type, fieldActualPaidAmount } = this.state;

    if (this.state.actual_paid_date !== null) {
      actual_paid_date = moment(this.state.actual_paid_date).format("YYYY-MM-DD")
    }
    if (type === "Draft") {
      if (this.state.DrafttotalAmout)
        amount = this.state.DrafttotalAmout
      else
        amount = parseFloat(document.getElementById("#amount").value.replace(/,/g, ""))
    }

    if (type === "Actual") {
      if (document.getElementById("#txtAmountActual").value !== '') {
        amount = parseFloat(document.getElementById("#txtAmountActual").value.replace(/,/g, ""))
      }
    }
    if (taxCategory === "OTH") {
      if (type === "Draft") {
        parseFloat(document.getElementById("#amount").value.replace(/,/g, ""))
      }
    }

    if(fieldActualPaidAmount === 'Write'){
      if(this.state.submission[0].amount !== 0 && amount === 0){
        const submissionAmount = (Math.round(this.state.submission[0].amount * 100) / 100).toFixed(2);
        if(!window.confirm(
            `Are you sure you want to approve this submission ${this.state.submission[0].tfs_submission_number}? \n\nThe assessed amount is $${submissionAmount} while the actual paid amount is $0, are you sure you want to proceed? \n\nClick [Ok] to confirm approval or [Cancel] to abort.`)){
          this.setState({ isFinishSaving: true });
          return;
        }
      }
    }

    fetch(url, {
      signal: AbortController.signal,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer google-oauth2 ${this.state.accesstoken}`
      },
      body: JSON.stringify(
        {
          "submission_id": this.state.submission[0].tfs_submission_id,
          "next_order": this.state.submission[0].next_order,
          "due_date": moment(this.state.dueDate).format("YYYY-MM-DD"),
          "tax_filling_month_from": moment(this.state.DateFrom).format("YYYY-MM-DD"),
          "tax_filling_month_to": moment(this.state.DateTo).format("YYYY-MM-DD"),
          "payment_options": this.state.selectedPayOption,
          "actual_paid_date": actual_paid_date,
          "amount": amount,
          "type": type,
          "skipped": amount === 0 && this.state.submission[0].next_order === "11" ? "Y" : "N",
          "amount_usd_indicative": amount * this.state.exchangeRateIndicative
        }
      )
    }
    ).then(response => response.json())
      .then(data => {
        this.upload(true)
      }).
      catch(e => console.log('error', e))

  }

  WithdrawSubmission() {
    if (window.confirm(`Are you sure you want to withdraw this submission ${this.state.submission[0].tfs_submission_number}? \n Click [Ok] to confirm withdrawal or [Cancel] to abort.`)) {
      const url = `${baseURL}/tfs/withdrawn_submission/`;
      let amount = 0;
      const { type } = this.state;

      if (type === "Draft") {
        if (document.getElementById("#amount").value !== '') {
          amount = parseFloat(document.getElementById("#amount").value.replace(/,/g, ""))
        }
      }
      else if (type === "Actual") {
        if (document.getElementById("#txtAmountActual").value !== '') {
          amount = parseFloat(document.getElementById("#txtAmountActual").value.replace(/,/g, ""))
        }
      }

      fetch(url, {
        signal: AbortController.signal,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer google-oauth2 ${this.state.accesstoken}`
        },
        body: JSON.stringify(
          {
            "submission_id": this.state.submission[0].tfs_submission_id,
            "amount": amount
          }
        )
      }
      ).then(response => response.json())
        .then(data => {
          if (data.status === "Submission Withdrawn Successfully") {
            this.setState({ isFinishSaving: true });
            window.location.reload();
          }
        }).
        catch(e => console.log('error', e))
    }
    else {
      this.setState({ isFinishSaving: true });
    }
  }

  SaveSubmission() {
    const url = `${baseURL}/tfs/save_submission/`;
    let actual_paid_date = this.state.actual_paid_date
    let amount = 0;
    let taxCategory = this.state.submission[0].tax_category
    let amountOth = 0;
    const { type } = this.state;

    if (this.state.actual_paid_date !== null) {
      actual_paid_date = moment(this.state.actual_paid_date).format("YYYY-MM-DD")
    }
    if (type === "Draft") {
      if (this.state.DrafttotalAmout)
        amount = this.state.DrafttotalAmout
      else
        amount = parseFloat(document.getElementById("#amount").value.replace(/,/g, ""))
    }

    if (type === "Actual") {
      if (document.getElementById("#txtAmountActual").value !== '') {
        amount = parseFloat(document.getElementById("#txtAmountActual").value.replace(/,/g, ""))
      }
    }
    if (taxCategory === "OTH") {
      if (type === "Draft") {
        parseFloat(document.getElementById("#amount").value.replace(/,/g, ""))
      }

    }

    fetch(url, {
      signal: AbortController.signal,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer google-oauth2 ${this.state.accesstoken}`
      },
      body: JSON.stringify(
        {
          "submission_id": this.state.submission[0].tfs_submission_id,
          "due_date": moment(this.state.dueDate).format("YYYY-MM-DD"),
          "tax_filling_month_from": moment(this.state.DateFrom).format("YYYY-MM-DD"),
          "tax_filling_month_to": moment(this.state.DateTo).format("YYYY-MM-DD"),
          "payment_options": this.state.selectedPayOption,
          "actual_paid_date": actual_paid_date,
          "amount": amount,
          "type": type,
          "amount_usd_indicative": amount * this.state.exchangeRateIndicative
        }
      )
    }
    ).then(response => response.json())
      .then(data => {
        if (data.status === "Submission Saved Successfully") {
          let fileList = this.state.attachmentList;
          if (fileList.length === 0) {
            this.setState({ isFinishSaving: true })
            alert("Tax Submission " + this.state.submission[0].tfs_submission_number + " has been saved successfully.")
          }
          else {
            this.upload(false);
          }
        }
      }).
      catch(e => console.log('error', e))

  }

  upload(redirect) {
    const url = `${baseURL}/tfs/submission_attachment/`;
    let formData = new FormData();
    let fileList = this.state.attachmentList

    if (fileList.length === 0) {
      if (redirect) {
        this.props.history.push('/taxsubmissions')
      }
    } else {
      for (var i = 0; i < fileList.length; i++) {
        formData.append('file', fileList[i].file)
        formData.append('filename', fileList[i].filename)
        formData.append('description', fileList[i].filename)
        formData.append('tfs_submission_id', this.state.submission[0].tfs_submission_id)
        formData.append('attachment_type', fileList[i].type)
        formData.append('created_by', this.props.userId)
        fetch(url, {
          signal: AbortController.signal,
          method: 'POST',
          headers: {

            'Authorization': `Bearer google-oauth2 ${this.state.accesstoken}`
          },
          body: formData
        }
        ).then(response => response.json())
          .then(data => {
            if (data.attachment_id) {
              this.getattachment(this.state.submission_id)
              let fileList = this.state.attachmentList
              this.setState({ isFinishSaving: true })
              if (!redirect) {
                alert("Tax Submission " + this.state.submission[0].tfs_submission_number + " has been saved successfully.\n" +
                  fileList[0].filename + " has been uploaded sucessfully.")
                fileList.length = 0
              }
              else {
                fileList.length = 0
                this.props.history.push('/taxsubmissions')
              }

            }
          })
          .catch(e => console.log('error', e))
      }
    }
  }

  RejectList() {
    Reject.length = 0
    const url = `${baseURL}/tfs/flow_submissions_summary_reject/?submission_id=${this.state.submission[0].tfs_submission_id}&order=${this.state.submission[0].next_order}&tz=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
    fetch(url, {
      signal: AbortController.signal,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer google-oauth2 ${this.state.accesstoken}`
      },
    }
    ).then(response => response.json())
      .then(data => {
        data.forEach(element => {
          Reject.push({ label: element["user_email"] + " (" + element["role_description"] + ")", value: element["user_email"] + " (" + element["role_description"] + ")", order: element["order"] });
        })
        this.setState({ RejectModal: true })
      }).
      catch(e => console.log('error', e))

  }

  RejectSubmission() {
    const url = `${baseURL}/tfs/reject_submission/`;
    fetch(url, {
      signal: AbortController.signal,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer google-oauth2 ${this.state.accesstoken}`
      },
      body: JSON.stringify(
        {
          "submission_id": this.state.submission[0].tfs_submission_id,
          "reject_to": this.state.selectedRejectUser.split(" ")[0],
          "order_reject": this.state.selectedRejectOrder
        }
      )
    }
    ).then(response => response.json())
      .then(data => {
        this.props.history.push('/taxsubmissions')
      }).
      catch(e => console.log('error', e))
  }

  submitHandler(e) {
    e.preventDefault();
    return false;
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  amountClick(type) {
    if (type === 'Draft') {
      this.setState({ DraftmodalIsOpen: true });
    }
    else if (type === 'Final') {
      this.setState({ FinalmodalIsOpen: true });
    }
    else if (type === 'Actual') {
      this.setState({ ActualmodalIsOpen: true });
    }
  }

  getTotalAmount(totalAmout, type) {
    if (type === 'Draft') {
      this.setState({ DrafttotalAmout: totalAmout })
    }
    else if (type === 'Final') {
      this.setState({ FinaltotalAmout: totalAmout });
    }
    else if (type === 'Actual') {
      this.setState({ ActualtotalAmout: totalAmout });
    }

  }

  getTotalAmount1(totalAmount) {
    this.setState({ totalAmount1: totalAmount, totalAmount: totalAmount + this.state.totalAmount2 + this.state.totalAmount3 })
  }

  getTotalAmount2(totalAmount) {
    this.setState({ totalAmount2: totalAmount, totalAmount: totalAmount + this.state.totalAmount1 + this.state.totalAmount3 })
  }

  getTotalAmount3(totalAmount) {
    this.setState({ totalAmount3: totalAmount, totalAmount: totalAmount + this.state.totalAmount1 + this.state.totalAmount2 })
  }


  closeModal(type) {
    if (type === 'Draft') {
      this.setState({ DraftmodalIsOpen: false });
    }
    else if (type === 'Final') {
      this.setState({ FinalmodalIsOpen: false });
    }
    else if (type === 'Actual') {
      this.setState({ ActualmodalIsOpen: false });
    }
  }

  disableButton(editable) {
    if (editable === 'Y') {
      return false
    }
    else {
      return true
    }

  }

  removeattachment(i, type) {
    let fileList
    switch (type) {
      case 'draft':
        fileList = this.state.attachmentDraft
        fileList.splice(i, 1)
        break;
      case 'final':
        fileList = this.state.attachmentFinal
        fileList.splice(i, 1)
        break;
      case 'tax_returns':
        fileList = this.state.attachmentTaxReturns
        fileList.splice(i, 1)
        break;
      case 'payment':
        fileList = this.state.attachmentPayment
        fileList.splice(i, 1)
        break;
      case 'all':
        fileList = this.state.attachmentList
        fileList.splice(i, 1)
        break;
    }
  }

  disableattachment(type) {

    if(!this.state.isAllowAccessAttachment) return true;

    switch (type) {
      case 'draft':
        if (this.state.fieldDraftAttachment === "Write") {
          return false
        }
        else {
          return true
        }
        break;
      case 'final':
        if (this.state.fieldFinalAttachment === "Write") {
          return false
        }
        else {
          return true
        }

        break;
      case 'tax_returns':
        if (this.state.fieldPostReviewAttachment === "Write") {
          return false
        }
        else {
          return true
        }
        break;
      case 'payment':
        if (this.state.fieldPaymentAttachment === "Write") {
          return false
        }
        else {
          return true
        }
        break;
    }

  }

  attachmentfunction(type) {
    return (
      <div>
        <input type="file"
          name="attachment"
          title="Upload"
          onChange={(e) => {
            let fileList = this.state.attachmentList
            fileList.length = 0
            if (e.target.files[0]) {
              fileList.push({ file: e.target.files[0], filename: e.target.files[0].name, change: true, type: type })
              this.setState({ attachmentList: fileList })
              switch (type) {
                case 'draft':
                  this.setState({ attachmentDraft: fileList })
                  break;
                case 'final':
                  this.setState({ attachmentFinal: fileList })
                  break;
                case 'tax_returns':
                  this.setState({ attachmentTaxReturns: fileList })
                  break;
                case 'payment':
                  this.setState({ attachmentPayment: fileList })
                  break;
              }
            }
            else {
              fileList.length = 0;
            }
          }

          }
          disabled={this.disableattachment(type)

          }
        />

      </div>
    )
  }

  download(attachment_id) {
    const url = `${baseURL}/tfs/download/?attachment_id=${attachment_id}`
    const signal = this.abortController.signal
    fetch(url, {
      signal: signal,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer google-oauth2 ${this.state.accessToken}`,
      },
    }).then(async (resp) => {
      if (resp.status !== 200) {
        alert('Error while download attachment')
        return
      }

      const fileName = decodeURIComponent(
        resp.headers.get('content-disposition').split('filename=')[1].replaceAll('"', '')
      )

      const blob = await resp.blob()
      const url = window.URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    })
  }

  deleteattachment(attachment_id, filename, type, i) {
    const url = `${baseURL}/tfs/submission_attachment_delete/${attachment_id}/`;
    const signal = this.abortController.signal;
    fetch(url, {
      signal: signal,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer google-oauth2 ${this.state.accesstoken}`
      },
    }).then(resp => {
      this.setState({ renderDelete: type })
      this.getattachment(this.state.submission_id)
      // this.getattachmentfunction(type)
      alert(`Attachment file ${filename} has been deleted. \n Click [Ok] to close this window.`)
    })
  }

  deleteattachmentprompt(file, type, i) {
    if (window.confirm(`Are you sure you want to delete attachment ${file.filename}? \n Click [Ok] to confirm deletion or [Cancel] to abort.`)) {
      this.deleteattachment(file.attachment_id, file.filename, type, i)
    }
  };

  getattachmentfunction(type) {
    let getfield
    switch (type) {
      case 'draft':
        getattachmentlist = this.state.getattachmentDraft
        getfield = this.state.fieldDraftAttachment
        break;
      case 'final':
        getattachmentlist = this.state.getattachmentFinal
        getfield = this.state.fieldFinalAttachment
        break;
      case 'tax_returns':
        getattachmentlist = this.state.getattachmentTaxReturns
        getfield = this.state.fieldPostReviewAttachment
        break;
      case 'payment':
        getattachmentlist = this.state.getattachmentPayment
        getfield = this.state.fieldPaymentAttachment
        break;
    }

    return getattachmentlist

    if (getattachmentlist.length > 0) {
      return (
        <div>
          {getattachmentlist.map((file, i) => {
            return (

              <div key={file.attachment_id}>
                <table>
                  <td>
                    <Link className="hyperlink">
                      <ol><li key={file.attachment_id} onClick={e => this.download(file.attachment_id, file.filename)}>
                        {file.filename}
                      </li></ol>
                    </Link>
                  </td>
                  <td></td>
                  <td>
                    <Link >
                      <ol>
                        <li title='Delete' className='deleteIcon' key={file.attachment_id} onClick={e => this.deleteattachmentprompt(file, type, i)}> </li>
                      </ol>
                    </Link>
                  </td>
                </table>
              </div>
            )
          })
          }
        </div>
      )
    }
  }

  closeExportAlert = () => {
    this.setState({ isSave: false });
  };

  render() {
    const { selectedPayOption } = this.state.selectedPayOption;
    const { selectedRejectUser } = this.state.selectedRejectUser;
    if (this.state.submission.length >= 1) {
      return (
        <div>
          <form id="formTemp" onSubmit={this.submitHandler.bind(this)}>
            <div className="content" >
              <div className="fscp-wrapper">
                <div className="fscp-search">
                  <div className="fscp-btn-wrapper">
                    {/* {this.state.pagetype !=='entity'?<button className={ "fscp-btn"}  type='button' onClick={e=> this.SaveSubmissionTEST()}>TEST</button>:null} */}
                    {this.state.pagetype !== 'entity' ? <button className={this.disableButton(this.state.submission[0].editable) ? "fscp-btn-disabled btn-neutral" : "fscp-btn btn-neutral"} type='button'
                      onClick={e => {
                        this.setState({ isSave: true, isFinishSaving: false })
                        this.SaveSubmission()
                      }} disabled={this.disableButton(this.state.submission[0].editable)}>Save</button> : null}
                    {this.state.isSave &&
                      <Modal
                        isOpen={this.state.isSave}
                        onRequestClose={this.closeExportAlert}
                        style={SaveStyles}
                        shouldCloseOnOverlayClick={false}
                      >
                        <div className="fscp-createalert-wrapper">
                          <p className="loading">Loading</p>
                          {this.state.isFinishSaving ? this.setState({ isSave: false }) : null}
                        </div>
                      </Modal>}

                    {this.state.pagetype !== 'entity' ? <button className={this.disableButton(this.state.submission[0].editable) ? "fscp-btn-disabled btn-focus" : "fscp-btn btn-focus"} type='submit'
                      onClick={e => {
                        this.setState({ isSave: true, isFinishSaving: false })
                        this.ApproveSubmission()
                      }} disabled={this.disableButton(this.state.submission[0].editable)}>Approve</button> : null}
                    {this.state.pagetype !== 'entity' && this.state.submissionStatus !== 'Created' ? <button className={this.disableButton(this.state.submission[0].editable) ? "fscp-btn-disabled btn-neutral" : "fscp-btn btn-neutral"} type='button' onClick={e => this.RejectList()} disabled={this.disableButton(this.state.submission[0].editable)}>Reject</button> : null}
                    {this.state.RejectModal &&
                      <Modal
                        isOpen={this.state.RejectModal}
                        onRequestClose={e => this.setState({ RejectModal: false })}
                        style={rejectStyles}
                      >
                        <div className="fscp-updstat">
                          <Select
                            styles={selectStyleReject}
                            options={Reject}
                            placeholder={'Reject'}
                            name='reject'
                            onChange={this.onChangeHandler}
                            value={selectedRejectUser}
                          />
                        </div><br /><br />
                        <div className="fscp-updstat-btn-wrapper">
                          <button className="fscp-updstat-btn" onClick={(e) => this.RejectSubmission()}>Ok</button>
                        </div>
                      </Modal>}
                    {this.state.pagetype !== 'entity' && this.state.submissionStatus === 'Created' ? <button className={this.disableButton(this.state.submission[0].editable) ? "fscp-btn-disabled btn-neutral" : "fscp-btn btn-neutral"} disabled={this.disableButton(this.state.submission[0].editable)} type='submit'
                      onClick={e => {
                        this.setState({ isSave: true, isFinishSaving: false })
                        this.WithdrawSubmission()
                      }}>Withdraw</button> : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="leftbox">
              <div className="column">
                <div className='input'>
                  <label className="label">Submission ID</label><br />
                  <label >{this.state.submission[0].tfs_submission_number}</label>
                </div>
                <div className='input'>
                  <label className="label">Creation Date</label><br />
                  {<label >{this.state.submission[0].create_date_format}</label>}
                </div>
                <div className='input'>
                  <label className="label">Payment Option</label><br />
                  <Select
                    styles={selectStyle}
                    options={PaymentOptions}
                    placeholder={'Payment Option'}
                    name='paymentOption'
                    value={selectedPayOption}
                    defaultValue={PaymentOptions.length > 0 ? { label: PaymentOptions.filter(filter => { return filter.value === this.state.submission[0].payment_options })[0].label, value: this.state.submission[0].payment_options } : null}
                    isDisabled={this.state.fieldPaymentOptions === "Write" ? false : true}
                    onChange={this.onChangeHandler}
                  />
                </div>
                <div className='input'>
                  <form onClick={e => this.state.fieldAmount === "Write" && this.state.submission[0].tax_category !== "OTH" ? this.amountClick("Draft") : null}>
                    <CurrencyTextField
                      id="#amount"
                      label="Amount"
                      variant="outlined"
                      currencySymbol={this.state.submission[0].currency}
                      value={this.state.DrafttotalAmout ? this.state.DrafttotalAmout : (this.state.DrafttotalAmout === 0 ? this.state.DrafttotalAmout : this.state.submission[0].amount)}
                      style={numericStyles}
                      disabled={this.state.fieldAmount === "Write" ? false : true}
                      readOnly={this.state.submission[0].tax_category === "OTH" ? false : true}
                    />
                  </form>
                  {<Modal
                    isOpen={this.state.DraftmodalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={e => this.closeModal("Draft")}
                    style={modalStyle}
                  >
                    <h6 ref={subtitle => this.subtitle = subtitle}>
                      <button className="modal-close-button" onClick={e => this.closeModal("Draft")} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </h6><br></br>
                    {this.state.submission[0].tax_category === "VAT" ? <AmountDetailVAT
                      modalIsOpen={this.state.modalIsOpen}
                      accesstoken={this.state.accessToken}
                      currency={this.state.submission[0].currency}
                      entity={this.state.submission[0].entity_id}
                      taxType={this.state.submission[0].tax_type}
                      rowData={this.state.rowDataGridVAT}
                      type={"Draft"}
                      totalAmout={this.state.DrafttotalAmout ? this.state.DrafttotalAmout : this.state.submission[0].amount}
                      getTotalAmout={this.getTotalAmount}
                      tfsSubmissionId={this.state.submission[0].tfs_submission_id}
                      buttonEditable={this.state.submission[0].editable}
                      USDRate={this.state.exchangeRateIndicative}
                    /> :
                      this.state.submission[0].tax_category === "CIT" ?
                        <Tabs>
                          <div className="row">
                            <div className="account-balance-detail-label column-modal ">
                              <label className="label-field-right">Entity:</label> <input id="#entity" type="text" className="input-field-left" disabled /><br></br>
                              <label className="label-field-right">Tax Type:</label> <input id="#taxtype" type="text" className="input-field-left" disabled /><br></br>
                            </div>
                            <div className="account-balance-detail-label column-modal ">
                              <label className="label-field-right">Currency:</label> <input id="#currency" type="text" className="input-field-left" disabled /><br></br>
                              <label className="label-field-right">Amount:</label> <input id="#cit_amount" type="text" className="input-field-left" disabled /><br></br>
                            </div>
                          </div>
                          <TabList>
                            <br></br>
                            <Tab>Tax rate 1</Tab>
                            <Tab>Tax rate 2</Tab>
                            <Tab>Tax rate 3</Tab>
                          </TabList>
                          <TabPanel>
                            <AmountDetailCIT
                              modalIsOpen={this.state.modalIsOpen}
                              accesstoken={this.state.accessToken}
                              currency={this.state.submission[0].currency}
                              entity={this.state.submission[0].entity_id}
                              taxType={this.state.submission[0].tax_type}
                              type={"Draft"}
                              totalAmout={this.state.DrafttotalAmout ? this.state.DrafttotalAmout : this.state.submission[0].amount}
                              getTotalAmout={this.getTotalAmount}
                              getTaxPercent={this.getTaxPercentCIT}
                              tfsSubmissionId={this.state.submission[0].tfs_submission_id}
                              buttonEditable={this.state.submission[0].editable}
                              tab={1}
                              USDRate={this.state.exchangeRateIndicative}
                            />
                          </TabPanel>
                          <TabPanel>
                            <AmountDetailCIT
                              modalIsOpen={this.state.modalIsOpen}
                              accesstoken={this.state.accessToken}
                              currency={this.state.submission[0].currency}
                              entity={this.state.submission[0].entity_id}
                              taxType={this.state.submission[0].tax_type}
                              type={"Draft"}
                              totalAmout={this.state.DrafttotalAmout ? this.state.DrafttotalAmout : this.state.submission[0].amount}
                              getTotalAmout={this.getTotalAmount}
                              getTaxPercent={this.getTaxPercentCIT}
                              taxPercent={this.state.taxPercentCIT}
                              tfsSubmissionId={this.state.submission[0].tfs_submission_id}
                              buttonEditable={this.state.submission[0].editable}
                              tab={2}
                              USDRate={this.state.exchangeRateIndicative}
                            />
                          </TabPanel>
                          <TabPanel>
                            <AmountDetailCIT
                              modalIsOpen={this.state.modalIsOpen}
                              accesstoken={this.state.accessToken}
                              currency={this.state.submission[0].currency}
                              entity={this.state.submission[0].entity_id}
                              taxType={this.state.submission[0].tax_type}
                              type={"Draft"}
                              totalAmout={this.state.DrafttotalAmout ? this.state.DrafttotalAmout : this.state.submission[0].amount}
                              getTotalAmout={this.getTotalAmount}
                              getTaxPercent={this.getTaxPercentCIT}
                              taxPercent={this.state.taxPercentCIT}
                              tfsSubmissionId={this.state.submission[0].tfs_submission_id}
                              buttonEditable={this.state.submission[0].editable}
                              tab={3}
                              USDRate={this.state.exchangeRateIndicative}
                            />
                          </TabPanel>
                        </Tabs>
                        :
                        this.state.submission[0].tax_category === "WHT" ? <AmountDetailWHT
                          modalIsOpen={this.state.modalIsOpen}
                          accesstoken={this.state.accessToken}
                          currency={this.state.submission[0].currency}
                          entity={this.state.submission[0].entity_id}
                          taxType={this.state.submission[0].tax_type}
                          amount={this.state.submission[0].amount}
                          getTotalAmout={this.getTotalAmount}
                          type={"Draft"}
                          totalAmout={this.state.DrafttotalAmout ? this.state.DrafttotalAmout : this.state.submission[0].amount}
                          rowData={this.state.rowDataGridWHT}
                          tfsSubmissionId={this.state.submission[0].tfs_submission_id}
                          buttonEditable={this.state.submission[0].editable}
                          USDRate={this.state.exchangeRateIndicative}
                        /> : null
                    }
                  </Modal>}
                </div>
                <div className='input'>
                </div>
                <div className='input'>
                </div>
              </div>
              <div className="column">
                <div className='input'>
                  <label className="label">Entity</label><br />
                  {<label >{this.state.submission[0].entity_id}</label>}
                </div>
                <div className='input'>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id="date-picker-inline"
                      label="Due Date"
                      value={this.state.dueDate ? this.state.dueDate : moment(this.state.submission[0].due_date).toDate()}
                      onChange={e => { this.setState({ dueDate: e }) }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      disabled={this.state.fieldDueDate === "Write" ? false : true}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className='input'>
                </div>
                <div className='input'>
                  <label className="label">Attachment</label><br />
                  {this.attachmentfunction('draft')}
                  {!this.state.isAllowAccessAttachment && <div style={{color: 'red', marginTop: '5px'}}>Attachment is restricted</div>}
                  {this.state.getattachmentDraft.length > 0 ? <img className='attachIcon' src={AttachmentLogo} onClick={(e) => this.setState({ getopenModalDraftAttachment: true })} /> : null}

                  <FileEvent
                    open={this.state.getopenModalDraftAttachment}
                    handleClose={() => this.setState({ getopenModalDraftAttachment: false })}
                    fileList={this.getattachmentfunction('draft')}
                    rerender={()=>this.getattachment(this.state.submission_id)}
                    accesstoken={this.state.accesstoken}
                  />

                </div>
                <div className='input'>
                </div>
                <div className='input'>
                  <label className="label">Submitted Tax Returns</label><br />
                  {this.attachmentfunction('tax_returns')}
                  {!this.state.isAllowAccessAttachment && <div style={{color: 'red', marginTop: '5px'}}>Attachment is restricted</div>}
                  {this.state.getattachmentTaxReturns.length > 0 ? <img src={AttachmentLogo} className="attachIcon" onClick={(e) => this.setState({ getopenModalTaxReturnsAttachment: true })} /> : null}
                  <FileEvent
                      open={this.state.getopenModalTaxReturnsAttachment}
                      handleClose={() => this.setState({ getopenModalTaxReturnsAttachment: false })}
                      fileList={this.getattachmentfunction('tax_returns')}
                      rerender={()=>this.getattachment(this.state.submission_id)}
                      accesstoken={this.state.accesstoken}
                  />
                </div>
                <div className='input'>
                </div>
              </div>
              <div className="column">
                <div className='input'>
                  <label className="label">Tax Type</label><br />
                  {this.state.submission[0].tax_type}
                </div>
                <div className='input'>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id="date-picker-inline"
                      label="Tax Filing Month From"
                      value={this.state.DateFrom ? this.state.DateFrom : moment(this.state.submission[0].tax_filling_month_from).toDate()}
                      onChange={e => { this.setState({ DateFrom: e }) }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      disabled={this.state.fieldTaxFillingMonthFrom === "Write" ? false : true}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className='input'>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id="date-picker-inline"
                      label="Tax Filing Month To"
                      value={this.state.DateTo ? this.state.DateTo : moment(this.state.submission[0].tax_filling_month_to).toDate()}
                      onChange={e => { this.setState({ DateTo: e }) }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      disabled={this.state.fieldTaxFillingMonthFrom === "Write" ? false : true}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className='input'>
                  <MuiThemeProvider theme={formLabelsTheme}>
                    <CurrencyTextField
                      id="#txtAmountActual"
                      label="Actual Paid Amount"
                      variant="outlined"
                      currencySymbol={this.state.submission[0].currency}
                      value={this.state.ActualtotalAmout ? this.state.ActualtotalAmout : this.state.submission[0].actual_paid_amount}
                      style={numericStyles}
                      disabled={this.state.fieldActualPaidAmount === "Write" ? false : true}
                    />
                  </MuiThemeProvider>
                </div>
                <div className='input'>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id="date-picker-inline"
                      label="Actual Paid Date"
                      value={this.state.actual_paid_date ? this.state.actual_paid_date : this.state.submission[0].actual_paid_date === null ? null : moment(this.state.submission[0].actual_paid_date).toDate()}
                      onChange={e => { this.setState({ actual_paid_date: e }) }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      disabled={this.state.fieldActualPaidDate === "Write" ? false : true}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className='input'>
                  <label className="label">Payment Attachment</label><br />
                  {this.attachmentfunction('payment')}
                  {!this.state.isAllowAccessAttachment && <div style={{color: 'red', marginTop: '5px'}}>Attachment is restricted</div>}
                  {this.state.getattachmentPayment.length > 0 ? <img src={AttachmentLogo} className="attachIcon" onClick={(e) => this.setState({ getopenModalPaymentAttachment: true })} /> : null}
                  <FileEvent
                      open={this.state.getopenModalPaymentAttachment}
                      handleClose={() => this.setState({ getopenModalPaymentAttachment: false })}
                      fileList={this.getattachmentfunction('payment')}
                      rerender={()=>this.getattachment(this.state.submission_id)}
                      accesstoken={this.state.accesstoken}
                  />
                </div>
                <div className='input'>
                </div>
              </div>
              <div className='input-history'>
                <LastSubmissionHistory accesstoken={this.state.accesstoken} submission_id={this.state.submission_id}
                  entity_id={this.state.submission[0].entity_id} tax_type_id={this.state.submission[0].tax_type_id}
                  due_date={this.state.submission[0].due_date}>
                </LastSubmissionHistory>
                <SubmissionHistory accesstoken={this.state.accesstoken} submission_id={this.state.submission_id}></SubmissionHistory>
              </div>
            </div>
            <div className="middlebox">
              <div>
                {this.state.flow ? <ShowFlow flow={this.state.flow} type={'edit'} /> : null}
              </div>
              <Comments submission_id={this.props.match.params.submission_id} accesstoken={this.state.accesstoken} postcomment={this.disableButton(this.state.submission[0].editable) ? false : true} insertcomment={this.state.insertcomment} />

            </div>
          </form>
        </div >

      );
    }
    else {
      return null
    }
  };
};

export default withCookies(withRouter(EditSubmissions));
