import React from 'react';
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";

const ProgressBarRenderer = (props) => {

    return (
        <div style={{cursor: "pointer"}} onClick={()=>props.toggleFlowModal(props.node.rowIndex, props.data.tfs_submission_id)}>
            <Progress percent={props.data.status === "Closed" ? 100 : Math.round(props.data.percentage)} />
        </div>
    );
};

export default ProgressBarRenderer