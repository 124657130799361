import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ErrorIcon from '@material-ui/icons/Error';

export default function AlertDialog() {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        window.location.href = `${process.env.REACT_APP_REDIRECT_TO_HOME_PAGE}`
    };

    return (
        <>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}>
                            <ErrorIcon style={{fill:'rgb(232 74 73)', paddingRight:'8px'}}/>
                            <p>{"Access Denied"}</p>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You do not have permissions to access this resource.<br/>
                            Please go to <a href="https://tiny.one/systems">https://tiny.one/systems </a> for guides on how to get access <br/>
                            or contact <a href = "mailto: finsys-epm@sea.com">finsys-epm@sea.com</a>.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button style={{background: 'rgb(232 74 73)', padding: '6px 13px', borderRadius:'10px', color:'white'}}
                                onClick={handleClose}
                                autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}