/* global gapi */
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import Select from 'react-select';
import './sea-react-table.css';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import Logs from './Logs';
import "react-sweet-progress/lib/style.css";
import 'react-tabs/style/react-tabs.css';
import EditLogo from '../images/sprite/edit.png'
import DeleteLogo from '../images/sprite/delete.png'
import LogLogo from '../images/sprite/logicon.png'
import ManageFlowEdit from './ManageFlowEdit';
import ExportManageFlow from './ExportManageFlow';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

const baseURL = `${process.env.REACT_APP_API_URL}`;
var windowHeight = window.innerHeight;
var bufferSize = 10;
var gridHeight = windowHeight - bufferSize;
var pageSize = (Math.floor(gridHeight / 35) - 2);
gridHeight = (pageSize * 28) + bufferSize;

const historyStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: '50%',
    bottom: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%'
  }
};

const alertCreateStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '22%',
    height: '25%',
    transform: 'translate(-50%, -50%)'
  }
};

const alertColumnFilter = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '10%',
    height: '20%',
    transform: 'translate(-50%, -50%)'
  }
};


Modal.setAppElement('#root');

const selectStyle = {
  container: base => ({
    ...base,
    width: 250,
    minwidth: 250,
    maxwidth: 250,
    height: 38,
    maxheight: 38,
    marginLeft: 15,
  })
};

var pageId = 2;
let Segments = [];
let Entities = [];
let Countries = [];
let TaxCategory = [];
let Taxitem = [];
let EntitiesCreate = [];

class ManageFlow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accesstoken: '',
      UserID: '',
      username: '',
      cookies: this.props.cookies.get('Logins'),
      checklists: [],
      isSearch: false,
      isCreate: false,
      isChecklistsExist: false,
      selectedTaxCategory: 'All',
      selectedSegment: 'All',
      selectedCountry: 'All',
      selectedEntity: 'All',
      selectedStatus: null,
      selectedChecklistIndex: null,
      showHistory: false,
      showUpdateStatus: false,
      showCreateAlert: false,
      selectedChecklistId: null,
      entityId: null,
      periodId: null,
      accessType: null,
      render: false,
      checklistsHighlight: [],
      firstreview: false,
      secondreview: false,
      thirdreview: false,
      fourthreview: false,
      fifthreview: false,
      sixthreview: true,
      seventhreview: true,
      eigthreview: true,
      selectedTaxItem: '',
      selectedEntitiesCreate: '',
      errorlog: false,
      flow_id: '',
      entity_id: '',
      tax_type: '',
      modalIsOpen: false
    };
    this.fetchSummaryDataHandler = this.fetchSummaryDataHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.toggleButtonHandler = this.toggleButtonHandler.bind(this);
    this.createChecklistHandler = this.createChecklistHandler.bind(this);
    this.createTaxFlowMaster = this.createTaxFlowMaster.bind(this);
    this.editTaxFlow = this.editTaxFlow.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeHistory = this.closeHistory.bind(this);
    this.openUpdateStatus = this.openUpdateStatus.bind(this);
    this.afterOpenUpdateStatus = this.afterOpenUpdateStatus.bind(this);
    this.closeUpdateStatus = this.closeUpdateStatus.bind(this);
    this.openCreateAlert = this.openCreateAlert.bind(this);
    this.afterOpenCreateAlert = this.afterOpenCreateAlert.bind(this);
    this.closeCreateAlert = this.closeCreateAlert.bind(this);
    this.toggleShowModalHandler = this.toggleShowModalHandler.bind(this);
    this.validateUpdateStatus = this.validateUpdateStatus.bind(this);
  };

  abortController = new AbortController();

  getPrevPeriodName = (n) => {
    let sysDate = new Date();
    sysDate.setMonth(sysDate.getMonth() + n);
    return sysDate.toLocaleString('default', { month: 'short' }) + "-" + sysDate.getFullYear();
  }

  onChangeHandler = (selectedOption, compProps) => {
    const selectedValue = selectedOption.value, name = compProps.name;
    const { isSearch, selectedTaxCategory, selectedEntity, selectedCountry, selectedSegment } = this.state;
    if (isSearch && (selectedTaxCategory !== selectedValue || selectedEntity !== selectedValue || selectedValue === 'All')) {
      this.setState({ isSearch: false });
    }
    switch (name) {
      case 'TaxCategory':
        this.setState({ selectedTaxCategory: selectedValue, periodnameexport: selectedOption.label, checklistsHighlight: [], searchexport: false });
        break;
      case 'entitiescreate':
        this.setState({ selectedEntitiesCreate: selectedValue, enabletaxitem: true },
          () => {
            this.cleanUpDropdown('taxitem');
            this.populateTaxItem(selectedValue);
          });
        break;
      case 'taxitem':
        this.setState({ selectedTaxItem: selectedValue });
        break;
      case 'segment':
        this.setState({ selectedSegment: selectedValue },
          () => {
            this.cleanUpDropdown('country');
            this.cleanUpDropdown('entity');
            this.populateCountries(selectedEntity, selectedCountry, selectedValue);
            this.populateEntities(selectedCountry, selectedValue);
          });
        break;
      case 'country':
        this.setState({ selectedCountry: selectedValue },
          () => {
            this.cleanUpDropdown('segment');
            this.cleanUpDropdown('entity');
            this.populateSegments(selectedEntity, selectedValue);
            this.populateEntities(selectedValue, selectedSegment);
          });
        break;
      case 'entity':
        this.setState({ selectedEntity: selectedValue },
          () => {
            this.cleanUpDropdown('segment');
            this.cleanUpDropdown('country');
            this.populateCountries(selectedValue, selectedCountry, selectedSegment);
            this.populateSegments(selectedValue, selectedCountry);
          });
        break;
      default:
        break;
    };
  };

  toggleButtonHandler = () => {
    const { checklists, isSearch, isChecklistsExist, selectedTaxCategory, selectedEntity } = this.state;
    let disabled = false;
    if ((!isChecklistsExist && !isSearch) ||
      (isChecklistsExist && selectedTaxCategory === checklists[0].period_id && selectedEntity === checklists[0].entity_id) ||
      (isChecklistsExist && !isSearch && (selectedTaxCategory !== checklists[0].period_id || selectedEntity !== checklists[0].entity_id)) ||
      (selectedEntity === 'All')
    ) {
      disabled = true;
    }
    ;
    return disabled;
  };

  fetchSummaryDataHandler() {
    const { isCreate, selectedTaxCategory, selectedEntity, selectedSegment, selectedCountry, accesstoken } = this.state;
    const url = `${baseURL}/tfs/flow_summary/?tax_category=${selectedTaxCategory}&entity_id=${selectedEntity}&country_id=${selectedCountry}&segment_id=${selectedSegment}`;
    this.abortController.abort();
    this.abortController = new AbortController();
    const signal = this.abortController.signal;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then((jsonStr) => {
        this.setState({ checklists: jsonStr, isSearch: true, isChecklistsExist: jsonStr.length > 0 ? true : false });
      });
  };

  saveflow = e => {
    var url, apiMethod, recordKey, error, msg, existingrole;
    var categoryId, usdAmount, rate;
    var i, j, h, r;
    i = j = h = r = 0;
    error = "";
    msg = "";
    existingrole = "";
    this.gridApi.forEachNode((node) => {
      r++;
      const { data } = node;

      if (data.flow_detail_id > 0) {
        i++;
        url = `${baseURL}/tfs/flow_details/${data.flow_detail_id}/`;
        apiMethod = 'PUT';
      }
      else {
        j++;
        url = `${baseURL}/tfs/flow_details/`;
        apiMethod = 'POST';
      }

      fetch(url, {
        method: apiMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer google-oauth2 ${this.state.accessToken}`
        },
        body: JSON.stringify(
          {
            "flow_id": this.props.flow_id,
            "role": data.role,
            "user_email": data.user_email,
            "created_by": this.props.userId,
            "last_updated_by": this.props.userId
          }
        )
      }).then(resp => resp.json())
        .then(json => { existingrole = existingrole + data.role })
        .catch(error => console.log(error))

    });

    msg = "Number of records created: " + j + "\nNumber of records updated: " + i;
    if (h > 0)
      msg = msg.concat("\nNumber of invalid records: " + h + "\n\nError Summary:\n" + error);

    alert(msg);
  }

  createChecklistHandler = () => {
    const { UserID, selectedPeriod, selectedEntity, accesstoken } = this.state;
    const url = `${process.env.REACT_APP_API_URL}/fscp/checklist_header/`;
    const signal = this.abortController.signal;
    fetch(url, {
      signal: signal,
      method: 'POST',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      },
      body: JSON.stringify({ period_id: selectedPeriod, entity_id: selectedEntity, status: 'Created', created_by: UserID[0].id })
    }).then(response => response.json())
      .then((jsonStr) => {
        this.setState({ isCreate: !!(jsonStr.checklist_id) ? true : false });
        this.fetchSummaryDataHandler();
      })
      .catch(error => console.log(error));
  };

  createTaxFlowMaster = () => {
    const { UserID, selectedEntitiesCreate, selectedTaxItem, accesstoken, attachment } = this.state;
    const url = `${process.env.REACT_APP_API_URL}/tfs/flow_master/`;
    const signal = this.abortController.signal;

    fetch(url, {
      signal: signal,
      method: 'POST',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      },
      body: JSON.stringify({ entity_id: selectedEntitiesCreate, tax_item: selectedTaxItem, created_by: UserID[0].user_email })
    }).then(response => response.json())
      .then((jsonStr) => {
        this.setState({ isCreate: false });
      })
      .catch(error => console.log(error));
  };

  uploadAttachment = () => {
    var msg, alrtmsg;
    const { accesstoken, attachment } = this.state;
    var file = attachment.name.split('.');
    file = file[file.length - 1];
    if (file.toLowerCase() !== "xlsx") {
      alert('Invalid file. The file must be a file of type xlsx.');
    }
    else {
      const url = `${process.env.REACT_APP_API_URL}/parse-excel/`;
      const signal = this.abortController.signal;
      let formData = new FormData();
      formData.append('files', attachment)
      fetch(url, {
        signal: signal,
        method: 'POST',
        headers: {
          'Authorization': `Bearer google-oauth2 ${accesstoken}`
        },
        body: formData
      }).then(response => response.json())
        .then((jsonStr) => {
          if (jsonStr.length > 1) {
            alrtmsg = jsonStr.filter(j => { return j.status.match('Error') })
            msg = "There is error in loading. Please check the log for details.\n" + "Number of records inserted: " + alrtmsg[0].inserted + "\nNumber of records updated: " + alrtmsg[0].updated + "\nNumber of records failed: " + alrtmsg[0].failed;
            alert(msg)
            this.setState({ errorlog: true, uploadlog: jsonStr.filter(j => { return !j.status.match('Error') }) });
          }
          else {
            msg = jsonStr.status + "\nNumber of records inserted: " + jsonStr.inserted + "\nNumber of records updated: " + jsonStr.updated;
            alert(msg)
            this.setState({ errorlog: false });
          }
          this.setState({ isCreate: false });
          this.fetchSummaryDataHandler();
        })
        .catch(error => console.log(error));
    }
  };

  populateTaxCategory() {
    const url = `${baseURL}/tfs/tax_category/`;
    const signal = this.abortController.signal;
    const { accesstoken } = this.state;
    const def_PeriodName = this.getPrevPeriodName(-1);
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          TaxCategory.push({ label: element["taxcategory_id"], value: element["taxcategory_id"] });
        });
      });
  };

  populateCountries(selectedEntity, selectedCountry, selectedSegment) {
    const url = `${baseURL}/tfs/country_list/?entity_id=${selectedEntity}&country_id=${selectedCountry}&segment_id=${selectedSegment}`;
    const signal = this.abortController.signal;
    const { accesstoken } = this.state;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          Countries.push({ label: element["country_name"], value: element["country_id"] });
        });
      });
  };

  populateSegments(selectedEntity, selectedCountry) {
    const url = `${baseURL}/tfs/segment_list/?entity_id=${selectedEntity}&country_id=${selectedCountry}`;
    const signal = this.abortController.signal;
    const { accesstoken } = this.state;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          Segments.push({ label: element["segment_name"], value: element["segment_id"] });
        })
      });
  };

  populateEntities(selectedCountry, selectedSegment) {
    const url = `${baseURL}/tfs/entity_list/?country_id=${selectedCountry}&segment_id=${selectedSegment}`;
    const signal = this.abortController.signal;
    const { accesstoken } = this.state;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          Entities.push({ label: element["entity_id"], value: element["entity_id"] });
        })
      });
  };

  populateTaxItem(selectedEntitiesCreate) {
    const url = `${baseURL}/tfs/tax_item/?entity_id=${selectedEntitiesCreate}`;
    const signal = this.abortController.signal;
    const { accesstoken } = this.state;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          Taxitem.push({ label: element["tax_item"], value: element["tax_item"] });
        })
      });
  };

  populateEntitiesCreate() {
    const url = `${baseURL}/tfs/entities/`;
    const signal = this.abortController.signal;
    const { accesstoken } = this.state;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          EntitiesCreate.push({ label: element["entity_id"], value: element["entity_id"] });
        })
      });
  };

  cleanUpDropdown = (type) => {
    switch (type) {
      case 'All':
        TaxCategory.length = 0;
        Countries.length = 0;
        Segments.length = 0;
        Entities.length = 0;
        Countries.push({ label: "All", value: "All" });
        Segments.push({ label: "All", value: "All" });
        Entities.push({ label: "All", value: "All" });
        TaxCategory.push({ label: "All", value: "All" });
        break;
      case 'segment':
        Segments.length = 0;
        Segments.push({ label: "All", value: "All" });
        break;
      case 'country':
        Countries.length = 0;
        Countries.push({ label: "All", value: "All" });
        break;
      case 'entity':
        Entities.length = 0;
        Entities.push({ label: "All", value: "All" });
        break;
      case 'taxitem':
        Taxitem.length = 0;
        break;
      default:
        break;
    };
  }

  getuserid = () => {
    const { accesstoken } = this.state;
    const url = `${baseURL}/tfs/current_user/`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then((json) => {
        this.setState({ UserID: json });
      })
      .catch(error => console.log(error));
  };

  toggleShowModalHandler = (selectedChecklistIndex, type) => {
    this.setState(prevState => {
      const newchecklists = [...prevState.checklists];
      switch (type) {
        case 'History':
          newchecklists[selectedChecklistIndex].showHistory = !newchecklists[selectedChecklistIndex].showHistory;
          break;
        case 'UpdateStatus':
          newchecklists[selectedChecklistIndex].showUpdateStatus = !newchecklists[selectedChecklistIndex].showUpdateStatus;
          break;
        default:
          break;
      };
      return { checklists: newchecklists };
    });
  };

  editTaxFlow = (row) => {
    const { entity_id, period_id } = row.original;
    this.toggleShowModalHandler(row.index, 'History');
    this.setState({ selectedChecklistIndex: row.index, entityId: entity_id, periodId: period_id });
  };

  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    const { style } = this.subtitle;
    // style.color = '#3d6ed1';
    style.textAlign = 'Left';
    style.borderBottom = '1px solid #cfcfcf';
  };

  closeHistory = () => {
    const { selectedChecklistIndex } = this.state;
    this.toggleShowModalHandler(selectedChecklistIndex, 'History');
    this.setState({ selectedChecklistIndex: null, entity_id: null, periodId: null });
  };

  validateUpdateStatus = (row) => {
    const { checklists, accesstoken } = this.state;
    const selectedChecklistIndex = row.index;
    const url = `${baseURL}/fscp/checklist_header/${row.original.checklist_id}/`;
    const signal = this.abortController.signal;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then((jsonStr) => {
        if (row.original.status === jsonStr.status) {
          this.openUpdateStatus(row);
        }
        else {
          alert("Status has change, click ok to refresh")
          this.setState(prevState => {
            const updatedChecklists = [...prevState.checklists];
            updatedChecklists[selectedChecklistIndex].status = jsonStr.status;
            return { checklists: updatedChecklists };
          })
        }
      })
      .catch(error => console.log(error));
  };

  openUpdateStatus = (row) => {
    const { entity_id, period_id } = row.original;
    this.toggleShowModalHandler(row.index, 'UpdateStatus');
    this.setState({ selectedChecklistIndex: row.index, entityId: entity_id, periodId: period_id });
  };

  afterOpenUpdateStatus = () => {
    //references are now sync'd and can be accessed.f
    const { style } = this.subtitle;
    style.color = '#3d6ed1';
    style.textAlign = 'Center';
    style.borderBottom = '1px solid #cfcfcf';
  };

  closeUpdateStatus = (statusType) => {
    if (statusType === "Cancel") {
      const { selectedChecklistIndex } = this.state;
      this.toggleShowModalHandler(selectedChecklistIndex, 'UpdateStatus');
      this.setState({ selectedChecklistIndex: null, entity_id: null, periodId: null, selectedStatus: null })
    }
    else {
      this.updateChecklistStatus();
    }
  };

  openCreateAlert = () => {
    const { checklists } = this.state;
    const checklist_id = checklists.map((checklist) => checklist.checklist_number);
    this.setState({ showCreateAlert: true, selectedChecklistId: checklist_id });
  };

  afterOpenCreateAlert = () => {
    // references are now sync'd and can be accessed.
    const { style } = this.subtitle;
    // style.color = '#3d6ed1';
    style.fontSize = '16px';
    style.textAlign = 'Left';
  };

  closeCreateAlert = () => {
    const { selectedPeriod, selectedEntity, checklists, selectedChecklistId } = this.state;
    this.setState({ showCreateAlert: false, isCreate: false });
    const periodName = checklists[0].month + "-" + checklists[0].year;
    const status = checklists[0].status;

    this.props.history.push({
      pathname: `/fscp/checklist/${checklists[0].checklist_id}`,
      state: {
        period_id: selectedPeriod,
        period_name: periodName,
        entity_id: selectedEntity,
        status: status,
        checklistnumber: selectedChecklistId
      }
    });
  };

  updateChecklistStatus = () => {
    const { accesstoken, UserID, checklists, selectedChecklistIndex, entityId, periodId, selectedStatus } = this.state;

    const url = `${baseURL}/fscp/checklist_header/${checklists[selectedChecklistIndex].checklist_id}/`;
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      },
      body: JSON.stringify({
        period_id: periodId,
        entity_id: entityId,
        last_updated_by: UserID[0]["id"],
        status: selectedStatus
      })
    })
      .then(response => response.json())
      .then((jsonStr) => {
        this.setState({ selectedChecklistIndex: null, showUpdateStatus: false, entity_id: null, periodId: null, selectedStatus: null },
          () => {
            this.fetchSummaryDataHandler()
          })
      })
      .catch(error => console.log(error));
  };

  componentDidMount() {
    const { selectedSegment, selectedCountry, selectedEntity } = this.state;
    this.cleanUpDropdown('All');
    if (this.state.cookies !== "Test" && this.state.cookies) {
      window.gapi.load('auth2', () => {
        window.gapi.auth2.init({
          client_id: `${process.env.REACT_APP_CLIENT_ID}`,
          cookie_policy: 'none'
        }).then(() => {
          try {
            const user = gapi.auth2.getAuthInstance().currentUser.get();
            if(user){
              user.reloadAuthResponse()
            }
            gapi.auth2.getAuthInstance().currentUser.listen((usr) => this.setState({ accesstoken: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token, username: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail() }))
          } catch (error) {
            console.log("trycatch", error)
          }
          this.setState({ accesstoken: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token, username: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail() })

          this.getuserid();
          this.populateTaxCategory();
          this.populateCountries(selectedEntity, selectedCountry, selectedSegment);
          this.populateSegments(selectedEntity, selectedCountry);
          this.populateEntities(selectedCountry, selectedSegment);
          this.populateEntitiesCreate();
          this.initUserPageAccess();
        })
      })
    } else {
      gapi.auth2.getAuthInstance().disconnect()
      window.location.href = '/Login';
    }
  };

  hideUploadButton() {
    document.getElementById("#upload").disabled = (this.state.pageAccessType !== "READ_ONLY") ? false : true;
    document.getElementById("#upload").style.opacity = (this.state.pageAccessType !== "READ_ONLY") ? null : 0.5;
  }

  initUserPageAccess() {
    const { accesstoken } = this.state;
    const url = `${baseURL}/tfs/my_role_page_access/?page_id=${pageId}`;

    var pageAccessAllowed, pageAccessType;
    fetch(url, {
      signal: this.abortController.signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then((jsonStr) => {
        jsonStr.forEach(element => {
          pageAccessAllowed = element["access_allowed"];
          pageAccessType = element["access_type"];

          this.setState({ pageAccessAllowed: pageAccessAllowed, pageAccessType: pageAccessType }, () => {
            if (this.state.pageAccessAllowed !== "Y") {
              window.location.href = '/';
            }
          });
          this.hideUploadButton();
        })
      }).catch(error => console.log('e', error));
  };


  componentWillUnmount() {
    this.abortController.abort();
  }

  columnfilter = () => {
    this.setState({ columnfilter: true })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }

  ShowFlow(params) {
    const eDiv = document.createElement('div');
    eDiv.innerHTML = `<img border='0' width='25' height='25' style='margin-bottom: 0px; vertical-align:top' src=${EditLogo}>`;
    eDiv.addEventListener('click', () => {
      this.setState(
        {
          flow_id: params.data.flow_id,
          entity_id: params.data.entity_id,
          tax_type: params.data.tax_type_id__tax_type,
          modalIsOpen: true
        }
      );

    });

    return eDiv;
  }



  DeleteFlow(params) {
    const eDiv = document.createElement('div');
    eDiv.innerHTML = `<img border='0' width='25' height='25' style='margin-bottom: 0px; vertical-align:top' src=${DeleteLogo}>`;
    eDiv.addEventListener('click', () => {
      if (window.confirm(`Are you sure you want to delete approval flow for ${params.data.entity_id} ${params.data.tax_type_id__tax_type} ? \n Click [Ok] to confirm deletion or [Cancel] to abort.`)) {
        const url = `${baseURL}/tfs/flow/${params.data.flow_id}/`;
        this.abortController.abort();
        this.abortController = new AbortController();
        const signal = this.abortController.signal;
        fetch(url, {
          signal: signal,
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer google-oauth2 ${this.state.accesstoken}`
          }
        }).then(this.fetchSummaryDataHandler)
          .catch(error => alert(error));
      }

    });

    return eDiv;
  }


  render() {
    const columnDefs = [
      {
        headerName: " ",
        field: "flow_id",
        width: 50,
        // cellStyle: genericCellStyle,
        cellRenderer: this.ShowFlow.bind(this)
      },
      {
        headerName: " ",
        field: "flow_id",
        width: 50,
        // cellStyle: genericCellStyle,
        cellRenderer: this.DeleteFlow.bind(this),
        hide: (this.state.pageAccessType !== "READ_ONLY") ? false : true
      },
      {
        headerName: "Country",
        field: "entity_id__country_id",
        width: 120,
        editable: false,
        resizable: true
      },

      {
        headerName: "Entity",
        field: "entity_id",
        width: 120,
        editable: false,
        resizable: true
      },
      {
        headerName: "Tax Type",
        field: "tax_type_id__tax_type",
        width: 150,
        editable: false,
        resizable: true
        //   hide: true
      },
      {
        headerName: "1st Reviewer",
        field: "firstreview",
        width: 120,
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        },
        // editable: true,
        show: true,
        resizable: true
      },
      {
        headerName: "2nd Reviewer",
        field: "secondreview",
        width: 120,
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        },
        // editable: true,
        show: true,
        resizable: true
      },
      {
        headerName: "3rd Reviewer",
        field: "thirdreview",
        width: 120,
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        },
        // editable: true,
        show: true,
        resizable: true
      },
      {
        headerName: "4th Reviewer",
        field: "fourthreview",
        width: 120,
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        },
        // editable: true,
        show: true,
        resizable: true
      },
      {
        headerName: "5th Reviewer",
        field: "fifthreview",
        width: 120,
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        },
        // editable: true,
        show: true,
        resizable: true
      },
      {
        headerName: "6th Reviewer",
        field: "sixthreview",
        width: 120,
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        },
        // editable: true,
        hide: this.state.sixthreview
      },
      {
        headerName: "7th Reviewer",
        field: "seventhreview",
        width: 120,
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        },
        // editable: true,
        hide: this.state.seventhreview
      },
      {
        headerName: "8th Reviewer",
        field: "eightreview",
        width: 120,
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        },
        // editable: true,
        hide: this.state.eigthreview
      },
      {
        headerName: "Final Reviewer",
        field: "finalreview",
        width: 150,
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        },
        // editable: true,
        resizable: true
      },
      {
        headerName: "Post Review Submission",
        field: "postreviewsubmission",
        width: 200,
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        },
        // editable: true,
        resizable: true
      },
      {
        headerName: "Post Review Approve",
        field: "postreviewapprove",
        width: 200,
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        },
        // editable: true,
        resizable: true
      },
      {
        headerName: "Update Payment Info",
        field: "updatepaymentinfo",
        width: 200,
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        },
        // editable: true,
        resizable: true
      },
      {
        headerName: "Payment Amount Verification",
        field: "paymentamountverification",
        width: 200,
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        },
        // editable: true,
        resizable: true
      },

    ]

    const columns = [
      {
        headerName: "",
        filterable: false,
        sortable: false,
        width: 30, /*cakrab*/
        minwidth: 120,/*cakrab*/
        maxwidth: 120,/*cakrab*/
        style: {
          padding: 3
        },
        Cell: (row) => {
          const { checklists } = this.state;

          return (
            <div>

              <img src={EditLogo} className="attachment-logos"
                onClick={(e) => this.editTaxFlow(row)}
              />
              {checklists[row.index].showHistory &&
                <Modal
                  isOpen={checklists[row.index].showHistory}
                  onAfterOpen={this.afterOpenModal}
                  onRequestClose={this.fetchSummaryDataHandler}
                  style={historyStyles}
                >

                  <h3 ref={subtitle => this.subtitle = subtitle}>Entity : {row.original.entity_id}</h3>
                  <h3 ref={subtitle => this.subtitle = subtitle}>Tax Item : {row.original.tax_item}</h3>
                  <ManageFlowEdit
                    flow_id={row.original.flow_id}
                    accesstoken={this.state.accesstoken}
                    userId={this.props.userId} />
                </Modal>}
            </div>
          )
        }
      },
      {
        headerName: "flow",
        field: "flow_id",
        width: 270,/*cakrab*/
        minwidth: 270,/*cakrab*/
        maxwidth: 270,/*cakrab*/
        style: {
          textAlign: "left", /*cakrab*/
          paddingLeft: 15 /*cakrab*/
        },
        show: false
      },
      {
        Header: "Country",
        accessor: "entity_id__country_id",
        width: 70,/*cakrab*/
        minwidth: 70,/*cakrab*/
        maxwidth: 70,/*cakrab*/
        style: {
          textAlign: "left", /*cakrab*/
          paddingLeft: 15 /*cakrab*/
        }
      },
      {
        Header: "Entity",
        accessor: "entity_id",
        width: 70,/*cakrab*/
        minwidth: 70,/*cakrab*/
        maxwidth: 70,/*cakrab*/
        style: {
          textAlign: "left", /*cakrab*/
          paddingLeft: 15 /*cakrab*/
        }
      },
      {
        Header: "Tax Type",
        id: "tax_type_id__tax_type",
        accessor: "tax_type_id__tax_type",
        width: 120,/*cakrab*/
        minwidth: 120,/*cakrab*/
        maxwidth: 120,/*cakrab*/
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        }
      },
      {
        Header: "1st Reviewer",
        accessor: "firstreview",
        width: 200,/*cakrab*/
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        },
        show: true
      },
      {
        Header: "2nd Reviewer",
        accessor: "secondreview",
        width: 200,/*cakrab*/
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        },
        show: true
      },
      {
        Header: "3rd Reviewer",
        accessor: "thirdreview",
        width: 200,/*cakrab*/
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        },
        show: true
      },
      {
        Header: "4th Reviewer",
        accessor: "fourthreview",
        width: 200,/*cakrab*/
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        },
        show: true
      },
      {
        Header: "5th Reviewer",
        accessor: "fifthreview",
        width: 200,/*cakrab*/
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        },
        show: true
      },
      {
        Header: "6th Reviewer",
        accessor: "sixthreview",
        width: 270,/*cakrab*/
        minwidth: 270,/*cakrab*/
        maxwidth: 270,/*cakrab*/
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        },
        show: this.state.sixthreview
      },
      {
        Header: "7th Reviewer",
        accessor: "seventhreview",
        width: 270,/*cakrab*/
        minwidth: 270,/*cakrab*/
        maxwidth: 270,/*cakrab*/
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        },
        show: this.state.seventhreview
      },
      {
        Header: "8th Reviewer",
        accessor: "eightreview",
        width: 270,/*cakrab*/
        minwidth: 270,/*cakrab*/
        maxwidth: 270,/*cakrab*/
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        },
        show: this.state.eigthreview
      },
      {
        Header: "Final Reviewer",
        accessor: "finalreview",
        width: 200,/*cakrab*/
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        }
      },
      {
        Header: "Post Review Submission",
        accessor: "postreviewsubmission",
        width: 200,/*cakrab*/
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        }
      },
      {
        Header: "Post Review Approve",
        accessor: "postreviewapprove",
        width: 200,/*cakrab*/
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        }
      },
      {
        Header: "Update Payment Info",
        accessor: "updatepaymentinfo",
        width: 200,/*cakrab*/
        style: {
          textAlign: "left",/*cakrab*/
          paddingLeft: 15 /*cakrab*/
        }
      },

    ];

    const { selectedTaxCategory } = this.state.selectedTaxCategory;
    const { selectedSegment } = this.state.selectedSegment;
    const { selectedCountry } = this.state.selectedCountry;
    const { selectedEntity } = this.state.selectedEntity;
    const { selectedTaxItem } = this.state.selectedTaxItem;
    const { selectedEntitiesCreate } = this.state.selectedEntitiesCreate;
    const { isMenuExpanded, menuToggle } = this.props;

    return (

      <div className="content" onClick={isMenuExpanded ? menuToggle : null}>
        <div className="fscp-container" style={{ height: '100%' }}>
          <div className="fscp-wrapper">
            <div className="fscp-search">
              <Select
                styles={selectStyle}
                options={TaxCategory}
                placeholder={'Tax Category'}
                name='TaxCategory'
                value={selectedTaxCategory}
                onChange={this.onChangeHandler}
              />
              <Select
                styles={selectStyle}
                options={Countries}
                placeholder={'Country'}
                name='country'
                value={selectedCountry}
                onChange={this.onChangeHandler}
              />
              <Select
                styles={selectStyle}
                options={Segments}
                placeholder={'Segment'}
                name='segment'
                value={selectedSegment}
                onChange={this.onChangeHandler}
              />
              <Select
                styles={selectStyle}
                options={Entities}
                placeholder={'Entity'}
                name='entity'
                value={selectedEntity}
                onChange={this.onChangeHandler}
              />
              <div className="fscp-btn-wrapper">

                <button className="fscp-btn btn-focus" onClick={this.fetchSummaryDataHandler}>Search</button>
                {/* <button className="fscp-btn" onClick={this.saveflow}>Save</button> */}
                <button id="#upload" className={"fscp-btn btn-focus"}
                  onClick={e => this.setState({ isCreate: true, showCreateAlert: true })}>Upload</button>
                {this.state.isCreate &&
                  <Modal
                    isOpen={this.state.showCreateAlert}
                    onAfterOpen={this.afterOpenCreateAlert}
                    onRequestClose={e => this.setState({ isCreate: false, showCreateAlert: false, enabletaxitem: false })}
                    style={alertCreateStyles}
                  >
                    <h2 ref={subtitle => this.subtitle = subtitle}>Please choose file</h2>
                    <input type="file" name="attachment" title="Upload" onChange={(e) => { this.setState({ attachment: e.target.files[0] }) }} />
                    <div className="fscp-createalert-wrapper">
                      <button className="fscp-createalert-btn" onClick={this.uploadAttachment}>Upload</button>
                    </div>
                  </Modal>}
                <ExportManageFlow
                  checklists={this.state.checklists}
                />
                <button className="fscp-btn-report btn-neutral" onClick={this.columnfilter}>Show/Hide Columns</button>
                {this.state.columnfilter &&
                  <Modal
                    isOpen={this.state.columnfilter}
                    onRequestClose={e => this.setState({ columnfilter: false })}
                    style={alertColumnFilter}
                  >
                    <table>
                      <th>
                        <tr>
                          <label>Sixth Reviewer</label>
                          <input
                            name="firstName"
                            type="checkbox"
                            onChange={e => this.setState({ sixthreview: !this.state.sixthreview })}
                          />
                        </tr>

                      </th>
                      <th>
                        <tr>
                          <label>Seventh Reviewer</label>
                          <input
                            name="firstName"
                            type="checkbox"
                            onChange={e => this.setState({ seventhreview: !this.state.seventhreview })}
                          />
                        </tr>
                      </th>
                      <th>
                        <tr>
                          <label>Eight Reviewer</label>
                          <input
                            name="firstName"
                            type="checkbox"
                            onChange={e => this.setState({ eigthreview: !this.state.eigthreview })}
                          />
                        </tr>

                      </th>
                    </table>
                  </Modal>}
                {this.state.errorlog ? <img src={LogLogo} className="attachment-logos" onClick={(e) => this.setState({ isLog: true })} /> : null}
                {this.state.isLog &&
                  <Modal
                    isOpen={this.state.isLog}
                    onAfterOpen={this.afterOpenCreateAlert}
                    onRequestClose={e => this.setState({ isLog: false, showCreateAlert: false, enabletaxitem: false })}
                    style={historyStyles}
                  >
                    <h2 ref={subtitle => this.subtitle = subtitle}>Message</h2>
                    <Logs uploadlog={this.state.uploadlog} />
                  </Modal>}
              </div>

            </div>
            <div className="ag-theme-balham" style={{ height: gridHeight + 'px' }} >
              {this.state.flow_id &&
                <Modal
                  isOpen={this.state.modalIsOpen}
                  onAfterOpen={this.afterOpenModal}
                  onRequestClose={e => this.setState({ modalIsOpen: false })}
                  style={historyStyles}
                >

                  <h3 ref={subtitle => this.subtitle = subtitle}>Entity : {this.state.entity_id}</h3>
                  <h3 ref={subtitle => this.subtitle = subtitle}>Tax Type : {this.state.tax_type}</h3>
                  <ManageFlowEdit
                    flow_id={this.state.flow_id}
                    accesstoken={this.state.accesstoken}
                    userId={this.props.userId} />
                </Modal>}
              <AgGridReact
                id="agGridLocalInputDetail"
                gridOptions={this.gridOptions}
                pagination={true}
                rowDragManaged={true}
                paginationPageSize={pageSize}
                suppressMiniFilter={true}
                suppressRowClickSelection={false}
                suppressVerticalScroll={true}
                columnDefs={columnDefs}
                rowData={this.state.checklists}
                rowHeight={30}
                singleClickEdit={true}
                stopEditingWhenGridLosesFocus={true}
                onGridReady={this.onGridReady}
                onComponentStateChanged={this.onComponentStateChanged}
              >
              </AgGridReact>
            </div>
          </div>
        </div>
      </div>
    );
  };
};

export default withCookies(withRouter(ManageFlow));
