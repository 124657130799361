import React, { Component } from 'react';
import {AgGridReact} from 'ag-grid-react';
import { gapi } from 'gapi-script'
import {withCookies} from 'react-cookie';
import {withRouter} from 'react-router-dom';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

const baseURL= `${process.env.REACT_APP_API_URL}`;
var flow_lookup = [];
var user_lookup =[];
var INTERCOCP = [];
var CURRENCIES = [];
var CATEGORY = new Map();
var accountCode = "";
var variance = 0;
var totalDetailAmount = 0;
var exchangeRate = 0;
var usdCurrencyCode = "USD";
var windowHeight = window.innerHeight;
var bufferSize = Math.ceil(windowHeight * 0.125);
var gridHeight = Math.ceil((windowHeight * 0.75) * 0.6) + bufferSize;
var pageSize = Math.floor(gridHeight / 28) - 2;
gridHeight = (pageSize * 28) + bufferSize;

class Logs extends Component {  
  constructor(props){
    super(props);

    exchangeRate = this.props.exchangeRate;
    accountCode = this.props.accountCode;
    variance = this.props.variance;
    totalDetailAmount = this.props.totalDetailAmount;  

    this.state = {
      columnDefs:[
        {
          headerName: "Row", 
          field: "id", 
          width: 15, 
          editable: false,
        },
        {
            headerName: "Entity", 
            field: "entity_id", 
            width: (window.innerWidth * 0.2) * 0.15, 
            editable: false,
          },
        {
          headerName: "Tax Type", 
          field: "tax_type", 
          width: (window.innerWidth * 0.2) * 0.15, 
          editable: false,
        },
        {
          headerName: "Message", 
          field: "status", 
          width: (window.innerWidth * 0.8) * 0.15, 
          editable: false,
        },
       
      ],     
      rowData: [],
      accessToken: '',
      cookies: '',
    }

    this.fetchAccountBalanceDetail = this.fetchAccountBalanceDetail.bind(this);
    this.fetchFlowDetail = this.fetchFlowDetail.bind(this);
    this.onGridReady = this.onGridReady.bind(this);
  }

  abortController = new AbortController();

  fetchAccountBalanceDetail = (balanceId) =>{
    const url = `${baseURL}/gcr/balance_details_list/?balance_id=${balanceId}`;
    fetch(url, {
        signal: AbortController.signal,
        method: 'GET',
        headers: {'Authorization': `Bearer google-oauth2 ${this.state.accessToken}`}
      }
    ).then(result => result.json())
    .then(rowData => this.setState({rowData}))
    document.getElementById("#amountExplained").value = parseFloat(this.props.totalDetailAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  fetchFlowDetail = (flow_id) =>{
    const url = `${baseURL}/tfs/get_flow_details/?flow_id=${flow_id}`;
    fetch(url, {
        signal: AbortController.signal,
        method: 'GET',
        headers: {'Authorization': `Bearer google-oauth2 ${this.state.accessToken}`}
      }
    ).then(result => result.json())
    .then(rowData => this.setState({rowData}))
  }

  componentDidMount(){
    window.gapi.load('auth2', () => {
      window.gapi.auth2.init({
          client_id: `${process.env.REACT_APP_CLIENT_ID}`,
          cookie_policy: 'none'
      }).then(() => {
          this.setState({accessToken: gapi.auth2.getAuthInstance().isSignedIn.get()});
          if(this.state.accessToken){
            this.setState({accessToken:this.props.accesstoken})
            if(this.props.cookies.get('Logins') === "Test"){
              this.props.cookies.set('Logins', gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getId(), {maxAge: 432000, sameSite: false});
            }
            
            this.setState({cookies: this.props.cookies.get('Logins')})
            
            if(this.state.cookies !== "Test" && this.state.cookies){
              this.initArrays();
              this.fetchFlowDetail(this.props.flow_id);
            }
            else{
                window.location.href=`${process.env.REACT_APP_REDIRECTLOGIN_URI}`;
            }
          }
          else{
                window.location.href=`${process.env.REACT_APP_REDIRECTLOGIN_URI}`;
          }
        })
    });
  }

  initArrays(){
    var url;
    url = `${baseURL}/gcr/entities`;
    INTERCOCP.length = 0;
    fetch(url, {
        signal: AbortController.signal,
        method: 'GET',
        headers: {'Authorization': `Bearer google-oauth2 ${this.state.accessToken}`}
    }
    ).then(result => result.json())
     .then(counterparties => {
      counterparties.forEach(element => {
        if (this.props.entityId !== element["entity_id"])
          INTERCOCP.push(element["entity_id"]);
      })
    });

    url = `${baseURL}/tfs/flow_lookup/`;
    flow_lookup.length = 0;
    fetch(url, {
      signal: AbortController.signal,
      method: 'GET',
      headers: {'Authorization': `Bearer google-oauth2 ${this.state.accessToken}`}
    }
    ).then(result => result.json())
    .then(flow => {
        flow.forEach(element => {
        flow_lookup.push(element["role"]);
      })
    });

    url = `${baseURL}/tfs/users/`;
    user_lookup.length = 0;
    fetch(url, {
      signal: AbortController.signal,
      method: 'GET',
      headers: {'Authorization': `Bearer google-oauth2 ${this.state.accessToken}`}
    }
    ).then(result => result.json())
    .then(flow => {
        flow.forEach(element => {
        user_lookup.push(element["user_email"]);
      })
    });

    CURRENCIES.length = 0;
    CURRENCIES.push(this.props.accountCurrencyCode);
    if (usdCurrencyCode !== this.props.accountCurrencyCode)
      CURRENCIES.push(usdCurrencyCode);
      
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  onGridReady(params){
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  addAccountBalanceDetail = e =>{
    const updates = this.gridApi.updateRowData(
      {
        add: [{
          Role: "",
          User: this.props.accountCurrencyCode,
        }]
      }
    );
    this.gridApi.startEditingCell({
      rowIndex: updates.add[0].rowIndex,
      colKey: 'category_type'
    });
  }

  saveAccountBalanceDetail = e => {
    var url, apiMethod, recordKey, error, msg,existingrole;
    var categoryId, usdAmount, rate;
    var i, j, h, r;
    i = j = h = r = 0;
    error = "";
    msg = "";
    existingrole =   "";
    totalDetailAmount=usdAmount = 0;
    this.gridApi.forEachNode((node) => {
        r++;
        const {data} = node;
        categoryId = getMapValue(CATEGORY, data.category_type);
        recordKey = "";
        recordKey = recordKey.concat(data.flow_id, data.role, data.user_email);  

        if (recordKey.localeCompare(data.record_key) !== 0){
          if(existingrole.includes(data.role)){
            h++;
            error = "Record (" + r + ") - " + data.role + " role already exists for this Tax Item";
          }
          else if (!data.user_email){
            h++;
            error = "Record (" + r + ") - User Email cannot be empty for role " + data.role;
          }
          else {
            if (data.flow_detail_id > 0){
              i++; 
              url = `${baseURL}/tfs/flow_details/${data.flow_detail_id}/`;
              apiMethod = 'PUT';
            }
            else{
              j++;
              url = `${baseURL}/tfs/flow_details/`;
              apiMethod = 'POST';
            }

            fetch(url, {
              method: apiMethod,
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization' : `Bearer google-oauth2 ${this.state.accessToken}`
              },
              body: JSON.stringify(
                {
                  "flow_id": this.props.flow_id,
                  "role": data.role,
                  "user_email": data.user_email,
                  "created_by": this.props.userId,
                  "last_updated_by": this.props.userId
                }
              )
            }).then(resp => resp.json())
              .then(json=>{existingrole = existingrole + data.role})
              .catch(error => console.log(error))
          }
        
        }
        else{
          existingrole = existingrole + data.role
        }
      });

    msg = "Number of records created: " + j + "\nNumber of records updated: " + i;
    if (h > 0)
      msg = msg.concat("\nNumber of invalid records: " + h + "\n\nError Summary:\n" + error);

    alert(msg);
  }

  deleteAccountBalanceDetail = e =>{
    var i = 0;
    this.gridApi.forEachNode((node) => {
      const {data} = node;
      if (node.selected){
        if(data.flow_detail_id){
          fetch(`${baseURL}/tfs/flow_details/${data.flow_detail_id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : `Bearer google-oauth2 ${this.state.accessToken}`
            },
          }).catch(error => alert(error));
        }
        i++;
      }
    });

    var selectedData = this.gridApi.getSelectedRows();
    this.gridApi.updateRowData({ remove: selectedData });
    alert("Number of record(s) deleted: " + i);
  }
  render () {
    return (
        <div>
          <div className="ag-theme-balham" style={{ height: gridHeight + 'px' }} >
            <AgGridReact
              id="agGridLocalInputDetail"
              gridOptions={this.gridOptions}
              pagination={true}
              rowMultiSelectWithClick={true}
              rowDragManaged={true}
              paginationPageSize={pageSize}
              suppressMiniFilter={true}
              suppressRowClickSelection={false}
              suppressHorizontalScroll={true}
              suppressVerticalScroll={true}
              columnDefs={this.state.columnDefs}
              rowData={this.props.uploadlog}
              rowHeight={28}
              rowSelection='multiple'
              singleClickEdit={true}
              stopEditingWhenGridLosesFocus={true}
              onGridReady={this.onGridReady}
              onComponentStateChanged={this.onComponentStateChanged}
              >
            </AgGridReact>    
          </div>
        </div>
        
    );
  }
};

function getMapValue(map, searchValue) {
  for (let [key, value] of map.entries()) {
    if (value === searchValue)
      return key;
  }
}

export default withRouter(withCookies(Logs));