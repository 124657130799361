import React, { useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactFileViewer from 'finsys-webcomponent-library/dist/lib/ReactFileViewer'
import {Grid, IconButton, Tooltip} from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
const baseURL = `${process.env.REACT_APP_API_URL}`;

export default function FileEvent({open, handleClose, fileList, rerender, accesstoken}) {
    const [name, setName] = useState(undefined)
    const [fileType, setFileType] = useState(undefined)
    const [filePath, setFilePath] = useState(undefined)

    const handleDialogClose = () => {
        setFilePath(undefined)
        setName(undefined)
        handleClose()
    }

    const handleDownloadFile = (attachment_id, download = true) => {
        const url = `${baseURL}/tfs/download/?attachment_id=${attachment_id}`
        const signal = AbortController.signal
        fetch(url, {
            signal: signal,
            method: 'GET',
            responseType: 'blob',
            headers: {
                Authorization: `Bearer google-oauth2 ${accesstoken}`,
            },
        }).then(async (resp) => {
            if (resp.status !== 200) {
                alert('Error while download attachment')
                return
            }


            const fileName = decodeURIComponent(
                resp.headers.get('content-disposition').split('filename=')[1].replaceAll('"', '')
            )

            //to revoke and remove before generating another blog
            if (localStorage.getItem('blob-url')) {
                URL.revokeObjectURL(localStorage.getItem('blob-url'))
                localStorage.removeItem('blob-url')
            }

            const blob = await resp.blob()
            const url = window.URL.createObjectURL(blob)
            localStorage.setItem('blob-url', url)

            if(download){
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
            }else{
                setFilePath(url)
            }

        })
    }

    const handleDeleteFile = (file) => {
        if (window.confirm(`Are you sure you want to delete attachment ${file.filename}? \n Click [Ok] to confirm deletion or [Cancel] to abort.`)) {
            const url = `${baseURL}/tfs/submission_attachment_delete/${file.attachment_id}/`;
            fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer google-oauth2 ${accesstoken}`
                },
            }).then(resp => {
                alert(`Attachment file ${file.filename} has been deleted. \n Click [Ok] to close this window.`)
                rerender()
            })
        }
    }

    const renderFileList = () =>{
        if(fileList.length > 0){
            return (fileList.map((file) => {
                return (
                  <table key={file.attachment_id}>
                      <td style={{width:'400px'}}>
                          <li style={{cursor: 'pointer', fontWeight: 'bold'}} key={file.attachment_id} onClick={() => {
                              setName(file.filename)
                              setFileType(file.filename.split('.').pop())
                              handleDownloadFile(file.attachment_id, false)

                              if(filePath){
                                  setFilePath(undefined)
                              }
                          }}>
                              {file.filename}
                          </li>
                      </td>
                      <td></td>
                      <td>
                          <Tooltip title="Delete" placement={'top'}>
                              <IconButton style={{color:'red'}} onClick={() => handleDeleteFile(file)}>
                                  <HighlightOffIcon />
                              </IconButton>
                          </Tooltip>
                      </td>
                      <td>
                          <Tooltip title="Download" placement={'top'}>
                              <IconButton color="primary" onClick={() => handleDownloadFile(file.attachment_id, file.filename)}>
                                  <GetAppIcon />
                              </IconButton>
                          </Tooltip>
                      </td>
                  </table>
                )
            }))
        }

        return <h2>No attachment!</h2>
    }

    return (
      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
          <DialogTitle id="max-width-dialog-title">File Attachments</DialogTitle>
          <DialogContent style={{overflow: 'hidden'}}>
              <Grid container style={{height: '100%', overflow: 'hidden'}} spacing={2}>
                  <Grid item md={6} lg={6}>
                      <h2>All Files</h2>
                      <Grid container style={{height:'60vh', margin:'20px 0', overflowY: 'scroll'}}>
                          <Grid item md={12} lg={12}>
                              {renderFileList()}
                          </Grid>
                      </Grid>
                  </Grid>
                  <Grid item md={6} lg={6} style={{textAlign: 'center',background:'#efefef'}}>
                      <h2>File Preview {name && `for ${name}`}</h2>
                      <p> Click on file name to preview </p>
                      <br/>
                      <div style={{height:'60vh'}}>
                          {filePath && <ReactFileViewer type={fileType} filePath={filePath}/> }
                      </div>
                  </Grid>
              </Grid>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                  Close
              </Button>
          </DialogActions>
      </Dialog>
    );
}