/* global gapi */
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import ReactTable from "react-table";
import './sea-react-table.css';
import {Link, withRouter} from 'react-router-dom';
import "react-sweet-progress/lib/style.css";
import 'react-tabs/style/react-tabs.css';

const baseURL = `${process.env.REACT_APP_API_URL}`;

class LastSubmissionHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accesstoken: '',
      UserID: '',
      username: '',
      cookies: this.props.cookies.get('Logins'),
      checklists: [],
      isSearch: false,
      isCreate: false,
      isChecklistsExist: false,
      selectedPeriod: 'All',
      selectedSegment: 'All',
      selectedCountry: 'All',
      selectedEntity: 'All',
      selectedStatus: null,
      selectedChecklistIndex: null,
      showHistory: false,
      showUpdateStatus: false,
      showCreateAlert: false,
      selectedChecklistId: null,
      entityId: null,
      periodId: null,
      accessType: null,
      render: false,
      submissionhistory: [],
      search: true,
      showFlow: false,
      flow: []
    };

  };

  abortController = new AbortController();

  componentDidMount() {
    this.fetchhistory()
  }

  fetchhistory() {
    const { accesstoken } = this.props;
    const url = `${baseURL}/tfs/last_submission_summary_edit/?entity_id=${this.props.entity_id}&tax_type_id=${this.props.tax_type_id}&due_date=${this.props.due_date}&tz=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then((jsonStr) => {
        this.setState({ submissionhistory: jsonStr })
      }).catch(error => console.log(error));
  };

  render() {
    const columns = [
      {
        Header: "Submission ID",
        accessor: "tfs_submission_number",
        Cell: props => {
          const titleTooltip = "Click here to open the submission details";
          return (
              <Link to={{
                pathname: `/taxsubmissions/submission/${props.original.tfs_submission_id}`,
                state: {
                  entity_id: props.original.entity_id,
                  tfs_submission_id: props.original.tfs_submission_id,
                  pagetype: this.props.pagetype,
                  accessToken: this.props.accessToken
                }
              }
              }
                    target="_blank"
              ><span className='font-blue' title={titleTooltip}>{props.value}</span>
              </Link>
          )
        },
        style: {
          textAlign: "left",
          textDecoration: "underline"
        }
      },
      {
        Header: "Due Date",
        accessor: "due_date",
        width: 120,
        style: {
          textAlign: "left",
        }
      },
      {
        Header: "Amount",
        accessor: "amount_currency",
        width: 300,
        style: {
          textAlign: "right",
        }
      },
      {
        Header: "Status",
        accessor: "status",
        style: {
          textAlign: "left",
        }
      },
      {
        Header: "Created By",
        accessor: "created_by",
        style: {
          textAlign: "left",
        }
      },
    ];
    const styles = { height: '10%', color: 'black', paddingRight: '5%' };
    return (
      <div style={styles}>
        <h3>Previous 3 filings</h3>
        <ReactTable columns={columns}
          data={this.state.submissionhistory}
          filterable={false}
          pageSize={3}
          className="-highlight"
          showPagination={false}
        />
        <br></br>
      </div>
    )
  }
};

export default withCookies(withRouter(LastSubmissionHistory));
