import React, { useEffect, useState } from "react";
import {AgGridReact} from 'ag-grid-react';

import './index.scss'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

export interface AgGridProps {
    toRetrieveNextPageData(): void,
    onGridReady(): void,
    gridApi: any,
    rowData: Array,
    columnDefs: Array,
    paginationFields: {
        currentPage: number,
        totalPages: number,
        loading: boolean,
        totalCount: number
    },
    paginationPageSize: number,
    frameworkComponents?: any,
    defaultColumnDef?: any,
    newSetOfRowData: boolean
}

export const AgGrid = (props: AgGridProps) => {
    const { gridApi, rowData, columnDefs, paginationFields,
        frameworkComponents, defaultColumnDef, paginationPageSize, refreshPagination } = props
    const { totalPages, totalCount, currentPage, loading } = paginationFields
    const [startRow, setStartRow] = useState(1)
    const [endRow, setEndRow] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)

    /**
     * To handle current page number
     */
    useEffect(()=>{
        setPageNumber(currentPage)
    }, [currentPage])

    /**
     * To loading of data
     */
    useEffect( () => {
        if(loading && gridApi){
            gridApi.showLoadingOverlay();
        }
    }, [loading, gridApi])

    /**
     * To handle filter input
     */
    useEffect( () => {
        if(!refreshPagination){
            return;
        }

        resetPagination()
    }, [refreshPagination])

    /**
     * To handle the end row content
     */
    useEffect(() => {
        setStartRow(pageNumber === 1 || pageNumber === null ? 1 : ((pageNumber - 1) * paginationPageSize) + 1)
        setEndRow((pageNumber * paginationPageSize) > totalCount ? totalCount : pageNumber * paginationPageSize)
    }, [pageNumber, paginationPageSize, totalCount]);

    /**
     * To reset pagination
     */
    const resetPagination = () => {
        setStartRow(1)
        setPageNumber(1)
        setEndRow(rowData?.length >= paginationPageSize ? paginationPageSize : rowData?.length)

        if(gridApi){
            gridApi.paginationGoToFirstPage();
        }
    }

    /**
     * To navigate to the next page of the table
     */
    const onClickToNextPage = () => {
        if(loading || totalPages === pageNumber){
            return;
        }

        setPageNumber(pageNumber => pageNumber + 1)
        if(pageNumber>=currentPage){
            props.toRetrieveNextPageData()
        }else{
            gridApi.paginationGoToNextPage()
        }
    };

    /**
     * To navigate to the previous page of the table
     */
    const onClickToPreviousPage = () => {
        if(pageNumber === 1 || loading){
            return;
        }

        setPageNumber(pageNumber => pageNumber - 1)

        gridApi.paginationGoToPreviousPage();
    };

    /**
     * To navigate to the first page of the table
     *
     */
    const onClickToFirstPage = () => {
        if(pageNumber === 1 || loading){
            return;
        }

        setPageNumber(1)

        gridApi.paginationGoToFirstPage();
    };

    return (
        <div style={{ width: '100%', height: '92%'}} className="ag-theme-alpine">
            <div
                style={{
                    height: '100%',
                    width: '100%',
                }}
            >
                <AgGridReact
                    columnDefs={columnDefs}
                    defaultColDef={defaultColumnDef}
                    onGridReady={props.onGridReady}
                    rowData={rowData}
                    frameworkComponents={frameworkComponents}
                    pagination={true}
                    suppressPaginationPanel={true}
                    suppressMenu={true}
                    paginationPageSize={paginationPageSize}
                />
                {
                    (currentPage && totalCount) ?
                    //<!--AG-PAGINATION-->
                    <div className="ag-paging-panel">
                  <span className="ag-paging-row-summary-panel">
                    <span className="ag-paging-row-summary-panel-number">{startRow}</span>
                    <span>to</span>
                    <span className="ag-paging-row-summary-panel-number">{endRow}</span>
                      {totalPages !== pageNumber &&
                      <>
                          <span>of</span>
                          <span className="ag-paging-row-summary-panel-number">{totalCount}</span>
                      </>
                      }
                  </span>
                        <span className="ag-paging-page-summary-panel">
                        <div className={`ag-paging-button ${ (loading || pageNumber === 1) && 'ag-disabled'}`} role="button" onClick={onClickToFirstPage}>
                          <span className="ag-icon ag-icon-first" unselectable="on" role="button"></span>
                        </div>
                        <div className={`ag-paging-button ${ (loading || pageNumber === 1) && 'ag-disabled'}`} role="button" onClick={onClickToPreviousPage}>
                          <span className="ag-icon ag-icon-previous" unselectable="on" role="presentation"></span>
                        </div>
                        <span className="ag-paging-description" aria-hidden="true">
                          <span>Page</span>
                          <span className="ag-paging-number">{pageNumber}</span>
                          <span>of</span>
                          <span className="ag-paging-number">{totalPages === pageNumber ? pageNumber : totalPages}</span>
                        </span>
                        <div className={`ag-paging-button ${(loading || totalPages === pageNumber) && 'ag-disabled'}`} role="button" onClick={onClickToNextPage}>
                          <span className="ag-icon ag-icon-next" unselectable="on"></span>
                        </div>
                    </span>
                    </div> : null
                }
            </div>
        </div>
    )
}