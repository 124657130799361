import React, {
    forwardRef,
    Fragment,
    useImperativeHandle,
    useRef,
} from 'react';

export default forwardRef((props, ref) => {
    const inputRef = useRef(null);

    // we need this
    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
        return {};
    });

    return (
        <Fragment>
            <input
                ref={inputRef}
                onInput={(e)=> props.onFilterInputChange(
                    props.column.userProvidedColDef.field,
                    e.target.value)
                }
            />
        </Fragment>
    );
});