import React, {
    forwardRef,
    Fragment,
    useImperativeHandle,
    useRef,
} from 'react';

export default forwardRef((props, ref) => {
    const inputRef = useRef(null);

    // we need this
    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
        return {};
    });

    return (
        <Fragment>
            <select
                ref={inputRef}
                onChange={e => props.onFilterInputChange(
                    props.column.userProvidedColDef.field,
                    e.target.value)
                }
                style={{ width: "100%" }}
            >
                {
                    props.selectOptions?.map(({value, label}) => {
                        return  <option value={value}>{label}</option>
                    })
                }
            </select>
        </Fragment>
    );
});