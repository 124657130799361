/* global gapi */
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import Select from 'react-select';
import './sea-react-table.css';
import { Link, withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import Comments from './Comments';
import ShowFlow from './showflow';
import "react-sweet-progress/lib/style.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import AmountDetailVAT from './AmountDetailVAT';
import AmountDetailCIT from './AmountDetailCIT';
import AmountDetailWHT from './AmountDetailWHT';
import 'date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import "react-datepicker/dist/react-datepicker.css";
import AttachmentLogo from '../images/sprite/icon_entrylist_attachment.png'
import './FileDrop.css';
import moment from 'moment';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';

const baseURL = `${process.env.REACT_APP_API_URL}`;
var pageId = 1;

const selectStyle = {
    container: base => ({
        ...base,
        width: 250,
        minwidth: 250,
        maxwidth: 250,
        height: 40,
        maxheight: 38,
        marginLeft: 0,
    })
};


const numericStyles = {
    zIndex: 0,
    fontWeight: 'bold'
};

const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: '50%',
        bottom: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: '85%'
    }
};


const AttachmentStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        width: '50%',
        height: '50%',
        transform: 'translate(-50%, -50%)',
    }
};
const SaveStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        width: '22%',
        height: '25%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(255,255,255,0.5)',
        opacity: 0.8,
        outline: 'none',
        border: '1px solid #ffffff'
    },
    overlay: {
        zIndex: 1000
    },
};

const formLabelsTheme = createMuiTheme({
    overrides: {
        MuiFormLabel: {
            asterisk: {
                color: "#db3131",
                "&$error": {
                    color: "#db3131"
                }
            }
        }
    }
});

let Entities = [];
let TaxTypes = [];
let Currencies = [];
let PaymentOptions =
    [{ label: "Giro arrangements in place, and sufficient funds in bank account for GIRO deductions", value: "GIRO" },
    { label: "Cheque payment applicable?", value: "Cheque" },
    { label: "Not Applicable", value: "NA" },
    { label: "Cash", value: "Cash" }
    ];
let getattachmentlist = []

class CreateSubmissions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accesstoken: '',
            UserID: this.props.userId,
            username: '',
            cookies: this.props.cookies.get('Logins'),
            selectedEntity: 'None',
            selectedTaxType: "",
            selectedTaxCategory: 'None',
            showHistory: false,
            entityId: null,
            accessType: null,
            render: false,
            createDate: new Date(),
            dueDate: null,
            DateFrom: null,
            DateTo: null,
            ActualPaidDate: null,
            selectedCurrency: null,
            selectedPayOption: 'None',
            isVAT: false,
            isWHT: false,
            isCIT: false,
            isOTH: false,
            modalIsOpen: false,
            showModal: false,
            rowDataGridVAT: [],
            tab1_rowDataGridCIT: [],
            tab2_rowDataGridCIT: [],
            tab3_rowDataGridCIT: [],
            rowDataGridWHT: [],
            totalAmount: "0.00",
            attachmentList: [],
            attachmentDraft: [],
            getattachmentDraft: [],
            getattachmentFinal: [],
            getattachmentTaxReturns: [],
            getattachmentPayment: [],
            fieldDraftAttachment: "Write",
            taxPercentCIT1: "",
            taxPercentCIT2: "",
            taxPercentCIT3: "",
            totalAmount1: 0,
            totalAmount2: 0,
            totalAmount3: 0,
            isAmountReadOnly: true,
            exchangeRateIndicative: 0,
            entityList: [],
        };

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.amountClick = this.amountClick.bind(this);
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getTotalAmount = this.getTotalAmount.bind(this);
        this.getTotalAmount1 = this.getTotalAmount1.bind(this);
        this.getTotalAmount2 = this.getTotalAmount2.bind(this);
        this.getTotalAmount3 = this.getTotalAmount3.bind(this);
        this.getTaxPercentCIT1 = this.getTaxPercentCIT1.bind(this);
        this.getTaxPercentCIT2 = this.getTaxPercentCIT2.bind(this);
        this.getTaxPercentCIT3 = this.getTaxPercentCIT3.bind(this);
    };

    abortController = new AbortController();

    fetchFromCITLookup() {
        const { taxPercentCIT2, taxPercentCIT3 } = this.state;
        if (taxPercentCIT2 === "") {
            const url = `${baseURL}/tfs/cit_lookup/`;
            fetch(url, {
                signal: this.abortController.signal,
                method: 'GET',
                headers: { 'Authorization': `Bearer google-oauth2 ${this.state.accesstoken}` }
            }
            ).then(result => result.json())
                .then((jsonStr) => {
                    this.setState({ tab2_rowDataGridCIT: jsonStr })
                })
        }

        if (taxPercentCIT3 === "") {
            const url = `${baseURL}/tfs/cit_lookup/`;
            fetch(url, {
                signal: this.abortController.signal,
                method: 'GET',
                headers: { 'Authorization': `Bearer google-oauth2 ${this.state.accesstoken}` }
            }
            ).then(result => result.json())
                .then((jsonStr) => {
                    this.setState({ tab3_rowDataGridCIT: jsonStr })
                })
        }
    }

    amountClick() {
        const { isVAT, isCIT, isWHT } = this.state;
        this.fetchFromCITLookup();
        if (isVAT || isCIT || isWHT) {
            this.openModal();
        }
    };

    onChangeHandler = (selectedOption, compProps) => {
        const selectedValue = selectedOption.value, name = compProps.name, label = selectedOption.label;
        const { isSearch, selectedTaxType, selectedEntity, selectedCurrency, selectedPayOption, entityList } = this.state;
        if (isSearch && (selectedTaxType !== selectedValue || selectedEntity !== selectedValue || selectedValue === 'All')) {
            this.setState({ isSearch: false });
        }
        switch (name) {
            case 'entity':
                this.setState({ selectedEntity: selectedValue },
                    () => {
                        this.cleanUpDropdown('taxtype');
                        this.populateTaxType(selectedValue);
                    });

                const selectedCurr = Currencies.filter(item => item.entityId == selectedValue)[0].value;
                var rateIndicative = entityList.filter(x => x.entity_id == selectedValue)[0].exchange_rate_indicative;
                this.setState({ selectedCurrency: selectedCurr, exchangeRateIndicative: rateIndicative });
                this.setState({ selectedTaxType: "" });
                break;
            case 'taxtype':
                const tax_category = selectedOption.tax_category
                this.setState({ selectedTaxType: label });
                this.setState({ selectedTaxCategory: tax_category });
                this.fetchFlow(label);
                this.setState({ totalAmount: 0, totalAmount1: 0, totalAmount2: 0, totalAmount3: 0 });
                document.getElementById("#amount").value = "0.00";
                this.setState({ taxPercentCIT1: "", taxPercentCIT2: "", taxPercentCIT3: "" });

                if (tax_category == "WHT") {
                    this.setState({ isWHT: true });

                    this.setState({ isVAT: false });
                    this.setState({ isCIT: false });
                    this.setState({ isOTH: false });
                    this.setState({ isAmountReadOnly: true });

                    this.populateRowDataGrid(tax_category);
                }

                if (tax_category == "VAT") {
                    this.setState({ isVAT: true });

                    this.setState({ isWHT: false });
                    this.setState({ isCIT: false });
                    this.setState({ isOTH: false });
                    this.setState({ isAmountReadOnly: true });

                    this.populateRowDataGrid(tax_category);
                }

                if (tax_category == "CIT") {
                    this.setState({ isCIT: true });

                    this.setState({ isVAT: false });
                    this.setState({ isWHT: false });
                    this.setState({ isOTH: false });
                    this.setState({ isAmountReadOnly: true });

                    this.fetchFromCITLookup();
                    this.populateRowDataGrid(tax_category);
                }

                if (tax_category == "OTH") {
                    this.setState({ isOTH: true, isAmountReadOnly: false });

                    this.setState({ isVAT: false });
                    this.setState({ isWHT: false });
                    this.setState({ isCIT: false });
                }

                break;
            case 'paymentOption':
                this.setState({ selectedPayOption: selectedValue });
                break;
            default:
                break;
        };
    };

    cleanUpDropdown = (type) => {
        switch (type) {
            case 'taxtype':
                TaxTypes.length = 0;
                break;
            case 'entity':
                Entities.length = 0;
                break;
            default:
                break;
        };
    }

    componentDidMount() {
        if (this.state.cookies !== "Test" && this.state.cookies) {
            window.gapi.load('auth2', () => {
                window.gapi.auth2.init({
                    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
                    cookie_policy: 'none'
                }).then(() => {
                    try {
                        const user = gapi.auth2.getAuthInstance().currentUser.get();
                        if(user){
                            user.reloadAuthResponse()
                        }
                        gapi.auth2.getAuthInstance().currentUser.listen((usr) => this.setState({ accesstoken: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token, username: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail() }))
                    } catch (error) {
                        console.log("trycatch", error)
                    }
                    this.setState({ accesstoken: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token, username: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail() })
                    this.initUserPageAccess();
                    this.populateEntities();
                })
            })
        } else {
            gapi.auth2.getAuthInstance().disconnect()
            window.location.href = '/Login';
        }
    };

    initUserPageAccess() {
        const { accesstoken } = this.state;
        const url = `${baseURL}/tfs/my_role_page_access/?page_id=${pageId}`;

        var pageAccessAllowed, pageAccessType;
        fetch(url, {
            signal: this.abortController.signal,
            method: 'GET',
            headers: {
                'Authorization': `Bearer google-oauth2 ${accesstoken}`
            }
        })
            .then(response => response.json())
            .then((jsonStr) => {
                jsonStr.forEach(element => {
                    pageAccessAllowed = element["access_allowed"];
                    pageAccessType = element["access_type"];

                    this.setState({ pageAccessAllowed: pageAccessAllowed, pageAccessType: pageAccessType }, () => {
                        if (this.state.pageAccessAllowed !== "Y" || this.state.pageAccessType !== "FULL_ACCESS") {
                            window.location.href = '/';
                        }
                    });
                })
            }).catch(error => console.log('e', error));
    };

    fetchFlow(selectedTaxType) {
        const { accesstoken, selectedEntity } = this.state;
        const url = `${baseURL}/tfs/flow_submissions_summary_create/?entity_id=${selectedEntity}&tax_type=${selectedTaxType}`;

        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer google-oauth2 ${accesstoken}`
            }
        })
            .then(response => response.json())
            .then((jsonStr) => {
                this.setState({ flow: jsonStr })
            }).catch(error => console.log(error));
    };

    populateRowDataGrid(tax_category) {
        const signal = this.abortController.signal;
        const { accesstoken } = this.state;

        if (tax_category === "VAT") {
            const url = `${baseURL}/tfs/vat_lookup/`;
            fetch(url, {
                signal: signal,
                method: 'GET',
                headers: { 'Authorization': `Bearer google-oauth2 ${accesstoken}` }
            }
            ).then(result => result.json())
                .then(rowDataGridVAT => this.setState({ rowDataGridVAT }))
        }

        if (tax_category === "CIT") {
            const url = `${baseURL}/tfs/cit_lookup/`;
            fetch(url, {
                signal: signal,
                method: 'GET',
                headers: { 'Authorization': `Bearer google-oauth2 ${accesstoken}` }
            }
            ).then(result => result.json())
                .then((jsonStr) => {
                    this.setState({ tab1_rowDataGridCIT: jsonStr })
                })
        }

        if (tax_category === "WHT") {
            this.setState({ rowDataGridWHT: [] });
        }
    }

    populateEntities() {
        const url = `${baseURL}/tfs/entity_list/`;
        const signal = this.abortController.signal;
        const { accesstoken } = this.state;
        fetch(url, {
            signal: signal,
            method: 'GET',
            headers: {
                'Authorization': `Bearer google-oauth2 ${accesstoken}`
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ entityList: data })
                data.forEach(element => {
                    Entities.push({ label: element["entity_id"], value: element["entity_id"] });
                    Currencies.push({ entityId: element["entity_id"], value: element["currency"] });
                })
            });
    };

    populateTaxType(selectedEntity) {
        const url = `${baseURL}/tfs/tax_type/?entity_id=${selectedEntity}`;
        const signal = this.abortController.signal;
        const { accesstoken } = this.state;
        fetch(url, {
            signal: signal,
            method: 'GET',
            headers: {
                'Authorization': `Bearer google-oauth2 ${accesstoken}`
            }
        })
            .then(response => response.json())
            .then(data => {
                data.forEach(element => {
                    TaxTypes.push({ label: element["tax_type"], value: element["tax_type_id"], tax_category: element["taxcategory_id"] });
                })
            });
    };

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        //this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    CreateSubmission(event) {
        event.preventDefault();
    }

    getTotalAmount(totalAmount) {
        this.setState({ totalAmount: totalAmount })
    }

    getTotalAmount1(totalAmount) {
        this.setState({ totalAmount1: totalAmount, totalAmount: totalAmount + this.state.totalAmount2 + this.state.totalAmount3 })
    }

    getTotalAmount2(totalAmount) {
        this.setState({ totalAmount2: totalAmount, totalAmount: totalAmount + this.state.totalAmount1 + this.state.totalAmount3 })
    }

    getTotalAmount3(totalAmount) {
        this.setState({ totalAmount3: totalAmount, totalAmount: totalAmount + this.state.totalAmount1 + this.state.totalAmount2 })
    }

    getTaxPercentCIT1(taxPercentCIT) {
        this.setState({ taxPercentCIT1: taxPercentCIT })
    }

    getTaxPercentCIT2(taxPercentCIT) {
        this.setState({ taxPercentCIT2: taxPercentCIT })
    }

    getTaxPercentCIT3(taxPercentCIT) {
        this.setState({ taxPercentCIT3: taxPercentCIT })
    }


    getattachmentfunction(type) {

        let getfield
        switch (type) {
            case 'draft':
                getattachmentlist = this.state.getattachmentDraft
                getfield = this.state.fieldDraftAttachment
                break;
        }

        if (getattachmentlist.length > 0) {
            return (
                <div>
                    {getattachmentlist.map((file, i) => {
                        return (

                            <div key={file.attachment_id}>
                                <table>
                                    <td>
                                        <Link >
                                            <ol><li key={file.attachment_id} onClick={e => this.download(file.attachment_id, file.filename)}>
                                                {file.filename}
                                            </li></ol>
                                        </Link>
                                    </td>
                                    <td></td>
                                    <td>
                                        <Link >
                                            <ol >
                                                <li title='Delete' className='deleteIcon' key={file.attachment_id} onClick={e => this.deleteattachmentprompt(file, type, i)}> </li>
                                            </ol>
                                        </Link>
                                    </td>
                                </table>
                            </div>
                        )
                    })
                    }
                </div>
            )
        }

    }

    disableattachment(type) {
        switch (type) {
            case 'draft':
                if (this.state.fieldDraftAttachment === "Write") {
                    return false
                }
                else {
                    return true
                }
                break;
            case 'final':
                if (this.state.fieldFinalAttachment === "Write") {
                    return false
                }
                else {
                    return true
                }

                break;
            case 'tax_returns':
                if (this.state.fieldPostReviewAttachment === "Write") {
                    return false
                }
                else {
                    return true
                }
                break;
            case 'payment':
                if (this.state.fieldPaymentAttachment === "Write") {
                    return false
                }
                else {
                    return true
                }
                break;
        }

    }

    attachmentfunction(type) {
        return (
            <div>
                <input type="file"
                    name="attachment"
                    title="Upload"
                    onChange={(e) => {
                        let fileList = this.state.attachmentList
                        fileList.length = 0
                        if (e.target.files[0]) {
                            fileList.push({ file: e.target.files[0], filename: e.target.files[0].name, change: true, type: type })
                            this.setState({ attachmentList: fileList })
                            switch (type) {
                                case 'draft':
                                    this.setState({ attachmentDraft: fileList })
                                    break;
                                case 'final':
                                    this.setState({ attachmentFinal: fileList })
                                    break;
                                case 'tax_returns':
                                    this.setState({ attachmentTaxReturns: fileList })
                                    break;
                                case 'payment':
                                    this.setState({ attachmentPayment: fileList })
                                    break;
                            }
                        }
                    }
                    }
                    disabled={this.disableattachment(type)

                    }
                />

            </div>
        )
    }

    removeattachment(i, type) {
        let fileList
        switch (type) {
            case 'draft':
                fileList = this.state.attachmentDraft
                fileList.splice(i, 1)
                break;
            case 'all':
                fileList = this.state.attachmentList
                fileList.splice(i, 1)
                break;
        }
    }

    upload(tfsSubmissionId) {
        const url = `${baseURL}/tfs/submission_attachment/`;
        let formData = new FormData();
        let fileList = this.state.attachmentList
        if (fileList.length === 0) {
            this.props.history.push('/taxsubmissions')
        } else {
            for (var i = 0; i < fileList.length; i++) {
                formData.append('file', fileList[i].file)
                formData.append('filename', fileList[i].filename)
                formData.append('description', fileList[i].filename)
                formData.append('tfs_submission_id', tfsSubmissionId)
                formData.append('attachment_type', fileList[i].type)
                formData.append('created_by', this.props.userId)

                fetch(url, {
                    signal: AbortController.signal,
                    method: 'POST',
                    headers: {

                        'Authorization': `Bearer google-oauth2 ${this.state.accesstoken}`
                    },
                    body: formData
                }
                ).then(response => response.json())
                    .then(data => {
                        if (data.attachment_id) {
                            this.setState({ isFinishSaving: true })
                            alert("Tax Submission " + this.state.selectedEntity + tfsSubmissionId + " has been created successfully.")
                            this.props.history.push(`/taxsubmissions/submission/${tfsSubmissionId}`);
                        }
                    }).
                    catch(e => console.log('error', e))
            }
        }
    }

    submitcomments(tfs_submission_id) {
        if (document.getElementById("commentTextArea").value !== "") {
            const { accesstoken } = this.state;

            const url = `${baseURL}/tfs/comment_save/`;
            this.abortController.abort();
            this.abortController = new AbortController();
            const signal = this.abortController.signal;
            fetch(url, {
                signal: signal,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer google-oauth2 ${accesstoken}`
                },
                body: JSON.stringify(
                    {
                        submission_id: tfs_submission_id,
                        comments: document.getElementById("commentTextArea").value,
                    }
                )
            })
                .then(response => response.json())
                .then((jsonStr) => {
                })
                .catch(e => console.log('commente', e));
        }
    };

    saveWHTDetail(tfsSubmissionId) {
        const { accesstoken, rowDataGridWHT, username } = this.state;
        let data = Object.keys(rowDataGridWHT).map(function (item) {
            return {
                percentage: rowDataGridWHT[item].percentage,
                amount: rowDataGridWHT[item].amount,
                amount_percentage: rowDataGridWHT[item].amount_percentage,
                type: "Draft",
                category: rowDataGridWHT[item].wht_category_id,
                tfs_submission_id: tfsSubmissionId,
                created_by: username,
                creation_date: new Date(),
                last_update_date: new Date(),
                last_updated_by: username
            }
        })

        const url = `${baseURL}/tfs/create_wht_submission/`;
        this.abortController.abort();
        this.abortController = new AbortController();
        const signal = this.abortController.signal;
        fetch(url, {
            signal: signal,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer google-oauth2 ${accesstoken}`
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then((jsonStr) => {
            })
            .catch(e => console.log('commente', e));
    };

    saveCITDetail(tfsSubmissionId) {
        const { tab1_rowDataGridCIT, tab2_rowDataGridCIT, tab3_rowDataGridCIT } = this.state;

        // save tab 1
        if (this.state.taxPercentCIT1 !== "")
            this.saveTabCIT(tab1_rowDataGridCIT, 1, tfsSubmissionId, this.state.taxPercentCIT1);
        // save tab 2
        if (this.state.taxPercentCIT2 !== "")
            this.saveTabCIT(tab2_rowDataGridCIT, 2, tfsSubmissionId, this.state.taxPercentCIT2);
        // save tab 3
        if (this.state.taxPercentCIT3 !== "")
            this.saveTabCIT(tab3_rowDataGridCIT, 3, tfsSubmissionId, this.state.taxPercentCIT3);

    };

    saveTabCIT(rowDataTab, tabNumber, tfsSubmissionId, taxPercent) {
        const { accesstoken, username } = this.state;
        let data = Object.keys(rowDataTab).map(function (item) {
            return {
                account: rowDataTab[item].account,
                amount: rowDataTab[item].account === "tax_%" ? taxPercent : rowDataTab[item].amount,
                type: "Draft",
                tfs_submission_id: tfsSubmissionId,
                account_description: rowDataTab[item].account_description,
                calculated: rowDataTab[item].calculated,
                style: rowDataTab[item].style,
                created_by: username,
                creation_date: new Date(),
                last_update_date: new Date(),
                last_updated_by: username,
                tab: tabNumber
            }
        })

        const url = `${baseURL}/tfs/create_cit_submission/`;
        this.abortController.abort();
        this.abortController = new AbortController();
        const signal = this.abortController.signal;
        fetch(url, {
            signal: signal,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer google-oauth2 ${accesstoken}`
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then((jsonStr) => {
            })
            .catch(e => console.log('commente', e));
    }

    saveVATDetail(tfsSubmissionId) {
        const { accesstoken, rowDataGridVAT, username } = this.state;
        let data = Object.keys(rowDataGridVAT).map(function (item) {
            return {
                account: rowDataGridVAT[item].account,
                amount: rowDataGridVAT[item].amount,
                type: "Draft",
                tfs_submission_id: tfsSubmissionId,
                account_description: rowDataGridVAT[item].account_description,
                calculated: rowDataGridVAT[item].calculated,
                style: rowDataGridVAT[item].style,
                created_by: username,
                creation_date: new Date(),
                last_update_date: new Date(),
                last_updated_by: username
            }
        })

        const url = `${baseURL}/tfs/create_vat_submission/`;
        this.abortController.abort();
        this.abortController = new AbortController();
        const signal = this.abortController.signal;
        fetch(url, {
            signal: signal,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer google-oauth2 ${accesstoken}`
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then((jsonStr) => {
            })
            .catch(e => console.log('commente', e));
    };

    createSubmission() {
        const url = `${baseURL}/tfs/create_submission/`;
        var amountOth = 0;

        if (this.state.flow === undefined || this.state.flow.length === 0) {
            this.setState({ isFinishSaving: true })
            alert("Unable to find approver for " + this.state.selectedEntity + " " + this.state.selectedTaxType +
                "\nPlease contact HQ Tax team for assistance.")
        }
        else if (this.state.selectedEntity === "None") {
            this.setState({ isFinishSaving: true })
            alert('Please enter a value for Entity.')
        }
        else if (this.state.selectedTaxType === "None") {
            this.setState({ isFinishSaving: true })
            alert('Please enter a value for Tax Type.')
        }
        else if (this.state.dueDate === null) {
            this.setState({ isFinishSaving: true })
            alert('Please enter a value for Due Date.')
        }
        else if (this.state.selectedPayOption === "None") {
            this.setState({ isFinishSaving: true })
            alert('Please enter a value for Payment Options.')
        }
        else if (this.state.DateFrom === null) {
            this.setState({ isFinishSaving: true })
            alert('Please enter a value for Tax Filling Month From.')
        }
        else if (this.state.DateTo === null) {
            this.setState({ isFinishSaving: true })
            alert('Please enter a value for Tax Filling Month To.')
        }
        else if (document.getElementById("#amount").value == "") {
            this.setState({ isFinishSaving: true })
            alert('Please enter a value for Amount.')
        }
        else {
            const { isOTH } = this.state;

            if (isOTH && document.getElementById("#amount").value !== "") {
                amountOth = parseFloat(document.getElementById("#amount").value.replace(/,/g, ""))
            }

            fetch(url, {
                signal: AbortController.signal,
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer google-oauth2 ${this.state.accesstoken}`
                },
                body: JSON.stringify(
                    {
                        "entity": this.state.selectedEntity,
                        "tax_type": this.state.selectedTaxType,
                        "due_date": moment(this.state.dueDate).format("YYYY-MM-DD"),
                        "tax_filling_month_from": moment(this.state.DateFrom).format("YYYY-MM-DD"),
                        "tax_filling_month_to": moment(this.state.DateTo).format("YYYY-MM-DD"),
                        "payment_options": this.state.selectedPayOption,
                        "amount": isOTH ? amountOth : this.state.totalAmount,
                        "currency": this.state.selectedCurrency,
                        "amount_usd_indicative": isOTH ? amountOth * this.state.exchangeRateIndicative : this.state.totalAmount * this.state.exchangeRateIndicative
                    }
                )
            }
            ).then(response => response.json())
                .then(data => {
                    if (data.tfs_submission_id) {
                        if (this.state.selectedTaxCategory === "WHT") {
                            this.saveWHTDetail(data.tfs_submission_id)
                        }
                        else if (this.state.selectedTaxCategory === "CIT") {
                            this.saveCITDetail(data.tfs_submission_id)
                        }
                        else if (this.state.selectedTaxCategory === "VAT") {
                            this.saveVATDetail(data.tfs_submission_id)
                        }

                        this.submitcomments(data.tfs_submission_id)
                        let fileList = this.state.attachmentList
                        if (fileList.length > 0) {
                            this.upload(data.tfs_submission_id)
                        }
                        else {
                            this.setState({ isFinishSaving: true })
                            alert("Tax Submission " + this.state.selectedEntity + data.tfs_submission_id + " has been created successfully.")
                            this.props.history.push(`/taxsubmissions/submission/${data.tfs_submission_id}`);
                        }
                    }
                }).
                catch(e => console.log('error', e))
        }
    }

    closeExportAlert = () => {
        this.setState({ isSave: false });
    };

    render() {
        const { selectedEntity } = this.state.selectedEntity;
        const { selectedTaxType } = this.state.selectedTaxType;
        const { selectedPayOption } = this.state.selectedPayOption;

        return (
            <div>
                <form id="formTemp" onSubmit={this.CreateSubmission.bind(this)}>
                    <div className="content" >
                        <div className="fscp-wrapper">
                            <div className="fscp-search">
                                <div className="fscp-btn-wrapper">
                                    <button className="fscp-btn btn-focus" type='submit' onClick={e => {
                                        this.setState({ isSave: true, isFinishSaving: false })
                                        this.createSubmission()
                                    }}>Create</button>
                                    {this.state.isSave &&
                                        <Modal
                                            isOpen={this.state.isSave}
                                            onRequestClose={this.closeExportAlert}
                                            style={SaveStyles}
                                            shouldCloseOnOverlayClick={false}
                                        >
                                            <div className="fscp-createalert-wrapper">
                                                <p className="loading">Loading</p>
                                                {this.state.isFinishSaving ? this.setState({ isSave: false }) : null}
                                            </div>
                                        </Modal>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="leftbox">
                        <div className="column">
                            <div className='input'>
                                <label className="label">Submission ID</label><br />
                                <input type='text' className="inputstyle-left-right" disabled={true}></input>
                            </div>
                            <div className='input'>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="yyyy-MM-dd"
                                        margin="normal"
                                        id="date-picker-inline"
                                        label="Creation Date"
                                        disabled='true'
                                        value={this.state.createDate}
                                        onChange={e => { this.setState({ createDate: e }) }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className='input'>
                                <label className="label">Payment Option</label><span className="asterisk">*</span><br />
                                <Select required
                                    styles={selectStyle}
                                    options={PaymentOptions}
                                    placeholder={'Payment Option'}
                                    name='paymentOption'
                                    value={selectedPayOption}
                                    onChange={this.onChangeHandler}
                                    required={'true'}
                                />
                            </div>
                            <div className='input'>
                                <div onClick={this.amountClick}>
                                    <MuiThemeProvider theme={formLabelsTheme}>
                                        <CurrencyTextField
                                            required
                                            id="#amount"
                                            label="Amount"
                                            variant="outlined"
                                            currencySymbol={this.state.selectedCurrency}
                                            value={this.state.totalAmount}
                                            style={numericStyles}
                                            disabled={this.state.isAmountReadOnly}
                                        />
                                    </MuiThemeProvider>
                                </div>
                                <Modal
                                    isOpen={this.state.modalIsOpen}
                                    onAfterOpen={this.afterOpenModal}
                                    onRequestClose={this.closeModal}
                                    style={modalStyle}
                                >
                                    <h6 ref={subtitle => this.subtitle = subtitle}>
                                        <button className="modal-close-button" onClick={this.closeModal} aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </h6><br></br>
                                    {this.state.isVAT ? <AmountDetailVAT
                                        modalIsOpen={this.state.modalIsOpen}
                                        accesstoken={this.state.accessToken}
                                        currency={this.state.selectedCurrency}
                                        entity={this.state.selectedEntity}
                                        taxType={this.state.selectedTaxType}
                                        rowData={this.state.rowDataGridVAT}
                                        getTotalAmout={this.getTotalAmount}
                                        totalAmount={this.state.totalAmount}
                                        type={"Draft"}
                                    /> :
                                        this.state.isCIT ?
                                            <Tabs>
                                                <div className="row">
                                                    <div className="account-balance-detail-label column-modal ">
                                                        <label className="label-field-right">Entity:</label> <input id="#entity" type="text" className="input-field-left" disabled /><br></br>
                                                        <label className="label-field-right">Tax Type:</label> <input id="#taxtype" type="text" className="input-field-left" disabled /><br></br>
                                                    </div>
                                                    <div className="account-balance-detail-label column-modal ">
                                                        <label className="label-field-right">Currency:</label> <input id="#currency" type="text" className="input-field-left" disabled /><br></br>
                                                        <label className="label-field-right">Amount:</label> <input id="#cit_amount" type="text" className="input-field-left" disabled /><br></br>
                                                    </div>
                                                </div>
                                                <TabList>
                                                    <br></br>
                                                    <Tab>Tax rate 1</Tab>
                                                    <Tab>Tax rate 2</Tab>
                                                    <Tab>Tax rate 3</Tab>
                                                </TabList>
                                                <TabPanel>
                                                    <AmountDetailCIT
                                                        modalIsOpen={this.state.modalIsOpen}
                                                        accesstoken={this.state.accessToken}
                                                        currency={this.state.selectedCurrency}
                                                        entity={this.state.selectedEntity}
                                                        taxType={this.state.selectedTaxType}
                                                        rowData={this.state.tab1_rowDataGridCIT}
                                                        getTotalAmout={this.getTotalAmount1}
                                                        totalAmount1={this.state.totalAmount1}
                                                        totalAmount2={this.state.totalAmount2}
                                                        totalAmount3={this.state.totalAmount3}
                                                        getTaxPercent={this.getTaxPercentCIT1}
                                                        taxPercent={this.state.taxPercentCIT1}
                                                        type={"Draft"}
                                                        tab={1}
                                                        isCreate={true}
                                                    />
                                                </TabPanel>
                                                <TabPanel>
                                                    <AmountDetailCIT
                                                        modalIsOpen={this.state.modalIsOpen}
                                                        accesstoken={this.state.accessToken}
                                                        currency={this.state.selectedCurrency}
                                                        entity={this.state.selectedEntity}
                                                        taxType={this.state.selectedTaxType}
                                                        rowData={this.state.tab2_rowDataGridCIT}
                                                        getTotalAmout={this.getTotalAmount2}
                                                        totalAmount1={this.state.totalAmount1}
                                                        totalAmount2={this.state.totalAmount2}
                                                        totalAmount3={this.state.totalAmount3}
                                                        getTaxPercent={this.getTaxPercentCIT2}
                                                        taxPercent={this.state.taxPercentCIT2}
                                                        type={"Draft"}
                                                        tab={2}
                                                        isCreate={true}
                                                    />
                                                </TabPanel>
                                                <TabPanel>
                                                    <AmountDetailCIT
                                                        modalIsOpen={this.state.modalIsOpen}
                                                        accesstoken={this.state.accessToken}
                                                        currency={this.state.selectedCurrency}
                                                        entity={this.state.selectedEntity}
                                                        taxType={this.state.selectedTaxType}
                                                        rowData={this.state.tab3_rowDataGridCIT}
                                                        getTotalAmout={this.getTotalAmount3}
                                                        totalAmount1={this.state.totalAmount1}
                                                        totalAmount2={this.state.totalAmount2}
                                                        totalAmount3={this.state.totalAmount3}
                                                        getTaxPercent={this.getTaxPercentCIT3}
                                                        taxPercent={this.state.taxPercentCIT3}
                                                        type={"Draft"}
                                                        tab={3}
                                                        isCreate={true}
                                                    />
                                                </TabPanel>
                                            </Tabs>
                                            :
                                            this.state.isWHT ? <AmountDetailWHT
                                                modalIsOpen={this.state.modalIsOpen}
                                                accesstoken={this.state.accessToken}
                                                currency={this.state.selectedCurrency}
                                                entity={this.state.selectedEntity}
                                                taxType={this.state.selectedTaxType}
                                                rowData={this.state.rowDataGridWHT}
                                                getTotalAmout={this.getTotalAmount}
                                                totalAmount={this.state.totalAmount}
                                                type={"Draft"}
                                            /> : null
                                    }
                                </Modal>
                            </div>
                            <div className='input'>
                                <label className="label">Attachment</label><br />
                                {this.attachmentfunction('draft')}
                                {this.state.getattachmentDraft.length > 0 ? <img src={AttachmentLogo} className="attachment-logos" onClick={(e) => this.setState({ getopenModalDraftAttachment: true })} /> : null}
                                {this.state.getopenModalDraftAttachment &&
                                    <Modal
                                        isOpen={this.state.getopenModalDraftAttachment}
                                        // onAfterOpen={this.afterOpenModal}
                                        onRequestClose={e => this.setState({ getopenModalDraftAttachment: false })}
                                        style={AttachmentStyles}
                                    >
                                        {this.getattachmentfunction('draft')}
                                    </Modal>
                                }
                            </div>
                        </div>
                        <div className="column">
                            <div className='input'>
                                <label className="label">Entity</label><span className="asterisk">*</span><br />
                                <Select
                                    styles={selectStyle}
                                    options={Entities}
                                    placeholder={'Entity'}
                                    name='entity'
                                    value={selectedEntity}
                                    onChange={this.onChangeHandler}
                                />
                            </div>
                            <div className='input'>
                                <MuiThemeProvider theme={formLabelsTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            required
                                            disableToolbar
                                            variant="inline"
                                            format="yyyy-MM-dd"
                                            margin="normal"
                                            id="dueDate"
                                            label="Due Date"
                                            value={this.state.dueDate}
                                            onChange={e => { this.setState({ dueDate: e }) }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </MuiThemeProvider>
                            </div>
                            <div className='input'>
                            </div>
                            <br></br>
                            <div className='input'></div>
                            <div className='input'>
                                <label className="label">Submitted Tax Returns</label><br />
                                {this.attachmentfunction('tax_returns')}
                                {this.state.getattachmentTaxReturns.length > 0 ? <img src={AttachmentLogo} className="attachment-logos" onClick={(e) => this.setState({ getopenModalTaxReturnsAttachment: true })} /> : null}
                                {this.state.getopenModalTaxReturnsAttachment &&
                                    <Modal
                                        isOpen={this.state.getopenModalTaxReturnsAttachment}
                                        onRequestClose={e => this.setState({ getopenModalTaxReturnsAttachment: false })}
                                        style={AttachmentStyles}
                                    >
                                        {this.getattachmentfunction('tax_returns')}
                                    </Modal>
                                }
                            </div>
                        </div>
                        <div className="column">
                            <div className='input'>
                                <label className="label">Tax Type</label><span className="asterisk">*</span><br />
                                <Select
                                    styles={selectStyle}
                                    options={TaxTypes}
                                    placeholder={'Tax Type'}
                                    name='taxtype'
                                    value={selectedTaxType}
                                    onChange={this.onChangeHandler}
                                />
                            </div>
                            <div className='input'>
                                <MuiThemeProvider theme={formLabelsTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            required
                                            disableToolbar
                                            variant="inline"
                                            format="yyyy-MM-dd"
                                            margin="normal"
                                            id="dateFrom"
                                            label="Tax Filing Month From"
                                            value={this.state.DateFrom}
                                            onChange={e => { this.setState({ DateFrom: e }) }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </MuiThemeProvider>
                            </div>
                            <div className='input'>
                                <MuiThemeProvider theme={formLabelsTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            required
                                            disableToolbar
                                            variant="inline"
                                            format="yyyy-MM-dd"
                                            margin="normal"
                                            id="dateTo"
                                            label="Tax Filing Month To"
                                            minDate={this.state.DateFrom}
                                            value={this.state.DateTo}
                                            onChange={e => { this.setState({ DateTo: e }) }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </MuiThemeProvider>
                            </div>
                            <br></br>
                            <div className='input'>
                                <CurrencyTextField
                                    label="Actual Paid Amount"
                                    variant="outlined"
                                    currencySymbol={this.state.selectedCurrency}
                                    onChange={this.amountClick}
                                    value={"0.00"}
                                    disabled={true}
                                    style={numericStyles}
                                />
                            </div>
                            <div className='input'>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="yyyy-MM-dd"
                                        margin="normal"
                                        id="dateActualPaid"
                                        label="Actual Paid Date"
                                        disabled='true'
                                        value={this.state.ActualPaidDate}
                                        onChange={e => { this.setState({ ActualPaidDate: e }) }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className='input'>
                                <label className="label">Payment Attachment</label><br />
                                {this.attachmentfunction('payment')}
                                {this.state.getattachmentPayment.length > 0 ? <img src={AttachmentLogo} className="attachment-logos" onClick={(e) => this.setState({ getopenModalPaymentAttachment: true })} /> : null}
                                {this.state.getopenModalPaymentAttachment &&
                                    <Modal
                                        isOpen={this.state.getopenModalPaymentAttachment}
                                        // onAfterOpen={this.afterOpenModal}
                                        onRequestClose={e => this.setState({ getopenModalPaymentAttachment: false })}
                                        style={AttachmentStyles}
                                    >
                                        {this.getattachmentfunction('tax_returns')}
                                    </Modal>
                                }
                            </div>
                        </div>
                    </div>
                </form>
                    <div className="middlebox">
                        <div>
                            {this.state.flow ? <ShowFlow
                                flow={this.state.flow}
                                entity={this.state.selectedEntity}
                                taxType={this.state.selectedTaxType} /> : null}
                        </div>
                        <div>
                            <Comments submission_id={'create'} accesstoken={this.state.accesstoken} postcomment={false} insertcomment={this.state.insertcomment} />
                        </div>
                        <div className="rightbox">
                        </div>
                    </div>
            </div>

        );
    };
};

export default withCookies(withRouter(CreateSubmissions));
