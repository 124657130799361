import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import {baseURL} from '../Constant'
import 'react-tabs/style/react-tabs.css';
// ag-grd
import { AgGrid } from "./ag-grid/AgGridTable";
import ProgressBarRenderer from "./ag-grid/ProgressBarRenderer";
import NewTabRenderer from "./ag-grid/NewTabRenderer";
import FreeTextFilter from "./ag-grid/FreeTextFilter";
import CustomizedHeaderSorting from "./ag-grid/CustomizedHeaderSorting";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {Chip} from "@material-ui/core";
import Filter from "./Filter";
import Modal from "react-modal";
import ShowFlowSummary from "./showflowSummary";
import SelectFilter from "./ag-grid/SelectFilter";

const modalStyles = {
  content: {
    top: 'auto',
    left: 'auto',
    right: '42%',
    bottom: '-7%',
    marginRight: '-50%',
    width: '15%',
    height: '60%',
    transform: 'translate(-50%, -50%)',
  }
};

const SaveStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '22%',
    height: '25%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(255,255,255,0.5)',
    opacity: 0.8,
    outline: 'none',
    border: '1px solid #ffffff'
  },
  overlay: {
    zIndex: 1000
  },
};

class TaxSubmissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cookies: this.props.cookies.get('Logins'),
      columnDefs: [
        {
          headerName: "Submission ID",
          maxWidth: 150,
          field: 'tfs_submission_number',
          cellRenderer: 'newTabRenderer',
          suppressMenu: true,
          floatingFilter:true,
          floatingFilterComponent:"freeTextFilter",
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            onFilterInputChange: this.onFilterInputChange
          },
          headerComponent: "customizedHeaderSorting",
          headerComponentParams: {
            onSortingChange: this.onSortingChange,
            renderSortingStatus: this.renderSortingStatus
          },
        },
        {
          headerName: "Entity",
          field: 'entity_id',
          maxWidth:150,
          suppressMenu: true,
          floatingFilter:true,
          floatingFilterComponent:"freeTextFilter",
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            onFilterInputChange: this.onFilterInputChange
          },
          headerComponent: "customizedHeaderSorting",
          headerComponentParams: {
            onSortingChange: this.onSortingChange,
            renderSortingStatus: this.renderSortingStatus
          },
        },
        {
          headerName: "Tax Type",
          field: 'tax_type',
          maxWidth:250,
          suppressMenu: true,
          floatingFilter:true,
          floatingFilterComponent:"freeTextFilter",
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            onFilterInputChange: this.onFilterInputChange
          },
          headerComponent: "customizedHeaderSorting",
          headerComponentParams: {
            onSortingChange: this.onSortingChange,
            renderSortingStatus: this.renderSortingStatus
          },
        },
        {
          headerName: "Amount",
          field: 'amount_currency',
          maxWidth: 250,
          suppressMenu: true,
          filter:false,
          headerComponent: "customizedHeaderSorting",
          headerComponentParams: {
            onSortingChange: this.onSortingChange,
            renderSortingStatus: this.renderSortingStatus,
          },
        },
        {
          headerName: "Amount USD",
          field: 'amount_usd_indicative_currency',
          maxWidth: 250,
          suppressMenu: true,
          filter:false,
          headerComponent: "customizedHeaderSorting",
          headerComponentParams: {
            onSortingChange: this.onSortingChange,
            renderSortingStatus: this.renderSortingStatus,
            toolTip: {
              title: "Approximate USD amount is calculated using exchange rate on the 1st of January",
            }
          },
        },
        {
          headerName: "Due Date",
          field: 'due_date',
          maxWidth:140,
          suppressMenu: true,
          floatingFilter:true,
          floatingFilterComponent:"freeTextFilter",
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            onFilterInputChange: this.onFilterInputChange
          },
          headerComponent: "customizedHeaderSorting",
          headerComponentParams: {
            onSortingChange: this.onSortingChange,
            renderSortingStatus: this.renderSortingStatus
          },
        },
        {
          headerName: "Status",
          field: 'status',
          maxWidth:180,
          suppressMenu: true,
          floatingFilter:true,
          floatingFilterComponent:"selectFilter",
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            onFilterInputChange: this.onFilterInputChange,
            selectOptions: [
              {value: "All", label: "Show All"},
              {value: "Closed", label: "Closed"},
              {value: "Open", label: "Open"},
            ]
          },
          headerComponent: "customizedHeaderSorting",
          headerComponentParams: {
            onSortingChange: this.onSortingChange,
            renderSortingStatus: this.renderSortingStatus
          },
        },
        {
          headerName: "Progress",
          field: 'percentage',
          maxWidth:145,
          cellRenderer: 'progressBarRenderer',
          suppressMenu:true,
          filter:false,
          sortable: false,
          cellRendererParams:{
            toggleFlowModal: this.toggleFlowModal
          },
          style: {
          }
        }
        ,
      ],
      frameworkComponents: {
        progressBarRenderer: ProgressBarRenderer,
        newTabRenderer: NewTabRenderer,
        freeTextFilter: FreeTextFilter,
        selectFilter: SelectFilter,
        customizedHeaderSorting: CustomizedHeaderSorting
      },
      defaultColDef: {
        minWidth:150,
        flex: 1,
        filter: true,
        wrapText: true,
        sortable: true,
        autoHeight: true,
        resizable: true,
      },
      paginationPageSize: 20,
      rowData: null,
      fieldsToBeFiltered: [],
      fieldsToBeFilteredString: "",
      fieldsToBeSorted: [],
      fieldsToBeSortedString: "",
      backendPagination: {
        limit: 20,
        toNextPage: null,
        totalPages: null,
        currentPage: null,
        totalCount: null,
        loading: false
      },
      refreshPagination: false,
      pendingPageCount: 0,
      selectedTaxCategory: 'All',
      selectedSegment: 'All',
      selectedCountry: 'All',
      selectedEntity: 'All',
      selectedYear: 'All',
      showFlowModal: false,
      flowData: null,
      tabs: 1, // default it to approval,
      isLoading: false,
      exportData: null
    }
  };

  async componentDidMount() {
    window.addEventListener('message', function (e) {
      // e.data hold the message from child
      if (e.data === "tab closed" && e.origin === 'https://' + window.location.hostname.replace('workspace', 'bpm')) {
        window.location.reload();
      }
    }, false);
  };

  /**
   * To toggle flow model for progress bar
   *
   * @param index
   * @param submissionId
   * @return {Promise<void>}
   */
  toggleFlowModal= async (index, submissionId) => {
    const flow = await this.toRetrieveFlowProgress(submissionId);

    if(flow !== 0){
      this.setState({
        flowData: flow,
        showFlowModal:true
      })
    }
  }

  /**
   * To retrieve flow progress
   *
   * @param submissionId
   * @return {Promise<any | void>}
   */
  toRetrieveFlowProgress = async (submissionId) => {
    const url = `${baseURL}/tfs/flow_submissions_summary_edit/?submission_id=${submissionId}&tz=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${this.props.accessToken}`
      }
    })
        .then(response => response.json())
        .then((jsonStr) => {
          return jsonStr
        }).catch(error => console.log('e', error));
  };

  /**
   * To save user selected filter
   *
   * @param entity
   * @param segment
   * @param country
   * @param taxcategory
   * @param year
   */
  onClickSearch = (entity, segment, country, taxcategory, year) => {
    this.setState({
      selectedEntity: entity,
      selectedSegment: segment,
      selectedCountry: country,
      selectedTaxCategory: taxcategory,
      selectedYear: year
    }, () => {
      this.refreshPagination()
    });
  }

  /**
   * To reset sorting and pagination on tab select
   */
  onTabSelect = (index) => {
    this.setState({
      tabs:index,
      fieldsToBeFiltered: [],
      fieldsToBeFilteredString: "",
      fieldsToBeSorted: [],
      fieldsToBeSortedString: "",
    })
  }

  /**
   * To retrieve data upon ag-grid ready
   */
  onGridReady = async (params) => {
    const { tabs } = this.state
    this.grid = params.api;

    const updateData = (data) => {
      if(tabs === 1){
        this.setState({
          pendingPageCount: data.count
        })
      }

      this.setState(prevState =>({
        rowData: data.results,
        backendPagination: {
          ...prevState.backendPagination,
          currentPage: 1,
          totalPages: data.total_pages,
          totalCount: data.count,
          toNextPage: data.next,
          loading:false
        }
      }), () => this.grid.setRowData(this.state.rowData))
    };

    this.setState(prevState => ({
      backendPagination: {
        ...prevState.backendPagination,
        loading:true
      }}), async () => {
      updateData(await this.toRetrieveTaxSubmissionSummary())
    })
  };

  /**
   * To retrieve paginated result
   */
  toRetrieveNextPageResult = () =>{
    this.setState(prevState => ({
      backendPagination: {
        ...prevState.backendPagination,
        loading: true,
      }}))

    const url = `${this.state.backendPagination.toNextPage.replace('http://','https://')}`
    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${this.props.accessToken}`
      }
    })
        .then(response => response.json())
        .then((data) =>
            this.setState(prevState => ({
                  rowData: this.state.rowData.concat(data.results),
                  backendPagination: {
                    ...prevState.backendPagination,
                    toNextPage: data.next,
                    currentPage: this.state.backendPagination.currentPage + 1,
                  }
                }), () => {
                  this.setState(prevState => ({
                    backendPagination: {
                      ...prevState.backendPagination,
                      loading: false,
                    }}))

                  this.grid.setRowData(this.state.rowData)
                  this.grid.paginationGoToNextPage()
                }
            ));
  }

  /**
   * To retrieve the entire dataset
   * @return {Promise<*>}
   */
  onClickExportData = async () => {
    this.setState({ isLoading: true })

    const data = await this.toRetrieveTaxSubmissionSummary(true);

    this.setState({ isLoading:false })

    return data
  }

  /**
   * To retrieve Tax Submission Summary
   *
   * @param filter? |string
   * @return array
   */
  toRetrieveTaxSubmissionSummary = async (toExportData = false) =>{
    const { selectedTaxCategory, selectedEntity, selectedSegment, selectedCountry, selectedYear } = this.state;

    let pageType;
    switch (this.state.tabs){
      case 0:
        pageType = 'submission'
        break;
      case 1:
        pageType = 'approval'
        break;
      default:
        pageType = 'entity'
        break;
    }
    let url = `${baseURL}/tfs/submission_summary/?pagetype=${pageType}&?year=${selectedYear}&tax_category=${selectedTaxCategory}&entity_code=${selectedEntity}&country_id=${selectedCountry}&segment_id=${selectedSegment}`

    if(!toExportData){
      url = url+=`&limit=${this.state.backendPagination.limit}`
    }

    if(this.state.fieldsToBeFilteredString){
      url = url.concat(this.state.fieldsToBeFilteredString);
    }
    if(this.state.fieldsToBeSortedString) {
      url = url.concat(this.state.fieldsToBeSortedString);
    }

    let result = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${this.props.accessToken}`
      }
    })

    let resultJson = await result.json()
    return resultJson
  }

  /**
   * To be passed to AG-GRID column as a params to render the sorting fields status
   *
   * @param column
   * @return {T[]}
   */
  renderSortingStatus = (column) => {
    return this.state.fieldsToBeSorted.filter(fields => fields.column === column)
  }

  /**
   * To be passed to AG-GRID column as a params to handle on sorting change
   *
   * @param column
   * @param callback
   */
  onSortingChange = (column, callback) => {
    const { fieldsToBeSorted } = this.state;
    let sortingParams = ''

    const concatSortParams = () => {
      return this.state.fieldsToBeSorted.map(fields => {
        return `&${fields.column.concat('_sort')}=${fields.sortBy}`
      }).join("")
    }

    // check if its part of the fields to be sorted
    let isSorted = fieldsToBeSorted.filter(fields => fields.column === column)

    if(isSorted){
      let sortBy = null;
      switch (isSorted[0]?.sortBy){
        case 'asc':
          sortBy = 'desc'
          break;
        case 'desc':
          sortBy = null
          break;
        default:
          sortBy = 'asc'
          break;
      }

      if(this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.setState({loading: true})

        this.setState({fieldsToBeSorted: fieldsToBeSorted.filter(fields => fields.column !== column),
          loading: true
        }, async()=> {
          if(sortBy){
            this.setState({fieldsToBeSorted: [...this.state.fieldsToBeSorted, {column, sortBy}]}, () => {
              callback()
              sortingParams = concatSortParams()
              this.setState({fieldsToBeSortedString:sortingParams}, async() => {
                await this.refreshPagination()
              })
            })
          }else{
            callback()
            sortingParams = concatSortParams()
            this.setState({fieldsToBeSortedString:sortingParams}, async() => {
              await this.refreshPagination()
            })
          }
        })
      }, 200);
    }
  }

  /**
   * To be passed to AG-GRID column as a params to handle a user input filter change
   *
   * @param name string
   * @param value string
   */
  onFilterInputChange = ( name, value: { name: string, value:string }) => {
    const { fieldsToBeFiltered } = this.state;
    let fieldsToBeFilteredString = ''

    const concatFilterParams = () => {
      return this.state.fieldsToBeFiltered.map(fields => {
        return `&${fields.name}=${fields.value}`
      }).join("")
    }

    if(this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({loading: true})

      this.setState({fieldsToBeFiltered: fieldsToBeFiltered.filter(fields => fields.name !== name),
        loading: true
      }, async()=> {
        if(value !== ""){
          this.setState({fieldsToBeFiltered: [...this.state.fieldsToBeFiltered, {name, value}]},
              async ()=> {
                fieldsToBeFilteredString = concatFilterParams()
                this.setState({fieldsToBeFilteredString}, async() => {
                  await this.refreshPagination()
                })
              }
          )
        }else{
          fieldsToBeFilteredString = concatFilterParams()
          this.setState({fieldsToBeFilteredString}, async() => {
            await this.refreshPagination()
          })
        }
      })
    }, 500);
  }

  /**
   * To rerender data for filter or sorting
   *
   * @return {Promise<void>}
   */
  async refreshPagination(){
    let data = await this.toRetrieveTaxSubmissionSummary()
    this.setState(prevState => ({
      rowData: data.results,
      backendPagination: {
        ...prevState.backendPagination,
        currentPage: 1,
        totalPages: data.total_pages,
        totalCount: data.count,
        toNextPage: data.next,
        loading: false,
      },
      refreshPagination: true
    }), () => {
      this.setState({refreshPagination: false})
      this.grid.setRowData(this.state.rowData)
    })
  }

  render() {
    const { totalPages, totalCount, limit, currentPage, loading } = this.state.backendPagination
    const { isReadOnly } = this.props
    const { pendingPageCount, showFlowModal, flowData, tabs } = this.state

    return (
        <>
          {this.props.accessToken && <>
            {showFlowModal &&
            <Modal isOpen={true}
                   onRequestClose={e => this.setState({ showFlowModal: false, flowData: null })}
                   style={modalStyles}
                   shouldCloseOnOverlayClick={true}
                   overlayClassName="Overlay"
            >
              <ShowFlowSummary flow={flowData} type={'summary'}/>
            </Modal>
            }
            {this.state.isLoading &&
            <Modal
                isOpen={this.state.isLoading}
                onRequestClose={this.closeExportAlert}
                style={SaveStyles}
                shouldCloseOnOverlayClick={false}
            >
              <div className="fscp-createalert-wrapper">
                <p className="loading">Exporting data in progress</p>
              </div>
            </Modal>}
            <div className="content">
              <div className="fscp-wrapper">
                <div className="fscp-search" style={{paddingBottom:"16px"}}>
                  <Filter getDropdownData={this.onClickSearch} onClickExportData={this.onClickExportData}/>
                </div>
                <Tabs style={{height:'calc(100% - 32px)'}} selectedIndex={tabs} onSelect={index => {this.onTabSelect(index)}}>
                  <TabList>
                    {isReadOnly ? null :
                        <>
                          <Tab> My Submission </Tab>
                          <Tab>
                            My Approval <Chip label={pendingPageCount}
                                              style={{fontSize:"11px", marginLeft:"5px"}}
                                              size="small"
                                              color="primary"/>
                          </Tab>
                        </>
                    }
                    <Tab> Entity View </Tab>
                  </TabList>
                  {isReadOnly ? null :
                      <>
                        <TabPanel style={{height: '92%'}}>
                          <AgGrid
                              toRetrieveNextPageData={this.toRetrieveNextPageResult}
                              onGridReady={this.onGridReady}
                              rowData={this.state.rowData}
                              gridApi={this.grid}
                              columnDefs={this.state.columnDefs}
                              paginationFields={{totalPages, totalCount, limit, currentPage, loading}}
                              frameworkComponents={this.state.frameworkComponents}
                              defaultColumnDef={this.state.defaultColDef}
                              paginationPageSize={this.state.paginationPageSize}
                              refreshPagination={this.state.refreshPagination}
                          />
                        </TabPanel>
                      <TabPanel style={{height:'92%'}}>
                      <AgGrid
                      toRetrieveNextPageData={this.toRetrieveNextPageResult}
                      onGridReady={this.onGridReady}
                      rowData={this.state.rowData}
                      gridApi={this.grid}
                      columnDefs={this.state.columnDefs}
                      paginationFields={{totalPages, totalCount, limit, currentPage, loading}}
                      frameworkComponents={this.state.frameworkComponents}
                      defaultColumnDef={this.state.defaultColDef}
                      paginationPageSize={this.state.paginationPageSize}
                      refreshPagination={this.state.refreshPagination}
                      />
                      </TabPanel>
                    </>
                  }
                  <TabPanel style={{height:'92%'}}>
                    <AgGrid
                        toRetrieveNextPageData={this.toRetrieveNextPageResult}
                        onGridReady={this.onGridReady}
                        rowData={this.state.rowData}
                        gridApi={this.grid}
                        columnDefs={this.state.columnDefs}
                        paginationFields={{totalPages, totalCount, limit, currentPage, loading}}
                        frameworkComponents={this.state.frameworkComponents}
                        defaultColumnDef={this.state.defaultColDef}
                        paginationPageSize={this.state.paginationPageSize}
                        refreshPagination={this.state.refreshPagination}
                    />
                  </TabPanel>
                </Tabs>
              </div>
            </div>
            </>
          }
        </>
    );
  };
}

export default withCookies(withRouter(TaxSubmissions));
