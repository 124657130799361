import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { gapi } from 'gapi-script'
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import { TextField, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import Select from 'react-select';
import moment from 'moment';
import ExportTaxSchedulerInfo from './ExportTaxSchedulerInfo';

const baseURL = `${process.env.REACT_APP_API_URL}`;
let COUNTRY = [];
let ENTITY = [];
var TAXTYPES = new Map();
let Segments = [];
let Entities = [];
let Countries = [];
let TaxCategory = [];
let TAXTYPE = [];
let frequency = ["Monthly", "Quarterly", "Annually"]
let months = [
  "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

var windowHeight = window.innerHeight;
var bufferSize = 10;
var gridHeight = windowHeight - bufferSize;
var pageSize = (Math.floor(gridHeight / 35) - 2);
gridHeight = (pageSize * 28) + bufferSize;

const selectStyle = {
  container: base => ({
    ...base,
    width: 250,
    minwidth: 250,
    maxwidth: 250,
    height: 40,
    maxheight: 38,
    marginLeft: 15
  })
};

const formLabelsTheme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131"
        }
      }
    }
  }
});

class TaxSubmissionScheduleInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      accessToken: '',
      userName: '',
      cookies: '',
      newCategory: "",
      selectedTaxCategory: 'All',
      selectedSegment: 'All',
      selectedCountry: 'All',
      selectedEntity: 'All',
      columnDefs: [
        {
          headerName: "Scheduler Id",
          field: "tfs_scheduler_id",
          hide: true
        },
        {
          headerName: "Country",
          field: "country_id",
          width: 70,
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: { values: COUNTRY },
          cellStyle: cellStyle,
          resizable: true
        },
        {
          headerName: "Entity",
          field: "entity_id",
          width: 70,
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: function (params) {
            var selectedCountry = params.data.country_id;
            var dt = ENTITY.filter((item) => item.country_id.includes(selectedCountry));
            let data = [];
            for (let i = 0; i < dt.length; i++) {
              data.push(dt[i].entity_id);
            }
            return { values: data }
          },
          cellStyle: cellStyleLeft,
          resizable: true
        },
        {
          headerName: "Tax Type",
          field: "tax_type",
          width: 120,
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: function (params) {
            var selectedCountry = params.data.country_id;
            var dt = TAXTYPE.filter((item) => item.country_id.includes(selectedCountry));
            let data = [];
            for (let i = 0; i < dt.length; i++) {
              data.push(dt[i].tax_type);
            }
            return { values: data }
          },
          cellStyle: cellStyleLeft,
          resizable: true
        },
        {
          headerName: "Submission Day",
          field: "submission_day",
          width: 70,
          editable: true,
          type: "numericColumn",
          valueGetter: submissionDayValueGetter,
          cellStyle: cellStyle,
          resizable: true
        },
        {
          headerName: "Submission Month",
          field: "submission_month",
          width: 70,
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: { values: months },
          cellStyle: cellStyleLeft,
          valueGetter: submissionMonthGetter,
          resizable: true
        },
        {
          headerName: "Frequency",
          field: "submission_frequency",
          width: 70,
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: { values: frequency },
          cellStyle: cellStyleLeft,
          resizable: true
        },
      ]
    }

    this.fetchInfoDetail = this.fetchInfoDetail.bind(this);
    this.onGridReady = this.onGridReady.bind(this);
    this.saveCategory = this.saveCategory.bind(this);
  }

  abortController = new AbortController();

  fetchInfoDetail = () => {
    const url = `${baseURL}/tfs/scheduler_summary/?segment_id=${this.state.selectedSegment}&country_id=${this.state.selectedCountry}&tax_category=${this.state.selectedTaxCategory}&entity_id=${this.state.selectedEntity}`;
    fetch(url, {
      signal: AbortController.signal,
      method: 'GET',
      headers: { 'Authorization': `Bearer google-oauth2 ${this.state.accessToken}` }
    }
    ).then(result => result.json())
      .then((jsonStr) => {
        this.setState({ rowData: jsonStr });
      });
  }

  populateTaxCategory() {
    const url = `${baseURL}/tfs/tax_category/`;
    const signal = this.abortController.signal;
    const { accessToken } = this.state;
    TaxCategory.length = 0;
    TaxCategory.push({ label: "All", value: "All" });
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accessToken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          TaxCategory.push({ label: element["taxcategory_id"], value: element["taxcategory_id"] });
        });
      });
  };

  populateCountries(selectedEntity, selectedCountry, selectedSegment) {
    const url = `${baseURL}/tfs/country_list/?entity_id=${selectedEntity}&country_id=${selectedCountry}&segment_id=${selectedSegment}`;
    const signal = this.abortController.signal;
    const { accessToken } = this.state;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accessToken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          Countries.push({ label: element["country_name"], value: element["country_id"] });
        });
      });
  };

  populateSegments(selectedEntity, selectedCountry) {
    const url = `${baseURL}/tfs/segment_list/?entity_id=${selectedEntity}&country_id=${selectedCountry}`;
    const signal = this.abortController.signal;
    const { accessToken } = this.state;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accessToken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          Segments.push({ label: element["segment_name"], value: element["segment_id"] });
        })
      });
  };

  populateEntities(selectedCountry, selectedSegment) {
    const url = `${baseURL}/tfs/entity_list/?country_id=${selectedCountry}&segment_id=${selectedSegment}`;
    const signal = this.abortController.signal;
    const { accessToken } = this.state;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accessToken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          Entities.push({ label: element["entity_id"], value: element["entity_id"] });
        })
      });
  };

  cleanUpDropdown = (type) => {
    switch (type) {
      case 'All':
        TaxCategory.length = 0;
        //Year.length = 0;
        Countries.length = 0;
        Segments.length = 0;
        Entities.length = 0;
        Countries.push({ label: "All", value: "All" });
        Segments.push({ label: "All", value: "All" });
        Entities.push({ label: "All", value: "All" });
        TaxCategory.push({ label: "All", value: "All" });
        //Year.push({ label: "All", value: "All" });
        break;
      case 'segment':
        Segments.length = 0;
        Segments.push({ label: "All", value: "All" });
        break;
      case 'country':
        Countries.length = 0;
        Countries.push({ label: "All", value: "All" });
        break;
      case 'entity':
        Entities.length = 0;
        Entities.push({ label: "All", value: "All" });
        break;
      default:
        break;
    };
  }

  onChangeHandler = (selectedOption, compProps) => {
    const selectedValue = selectedOption.value, name = compProps.name;
    const { isSearch, selectedTaxCategory, selectedYear, selectedEntity, selectedCountry, selectedSegment } = this.state;
    if (isSearch && (selectedTaxCategory !== selectedValue || selectedEntity !== selectedValue || selectedValue === 'All')) {
      this.setState({ isSearch: false });
    }
    switch (name) {
      case 'TaxCategory':
        this.setState({ selectedTaxCategory: selectedValue, periodnameexport: selectedOption.label, checklistsHighlight: [], searchexport: false });
        //this.fetchHighlightReport(selectedValue)
        console.log("taxcat", selectedValue)
        break;
      case 'Year':
        this.setState({ selectedYear: selectedValue, periodnameexport: selectedOption.label, checklistsHighlight: [], searchexport: false });
        //this.fetchHighlightReport(selectedValue)
        console.log("taxcat", selectedValue)
        break;
      case 'segment':
        this.setState({ selectedSegment: selectedValue },
          () => {
            this.cleanUpDropdown('country');
            this.cleanUpDropdown('entity');
            this.populateCountries(selectedEntity, selectedCountry, selectedValue);
            this.populateEntities(selectedCountry, selectedValue);
          });
        break;
      case 'country':
        this.setState({ selectedCountry: selectedValue },
          () => {
            this.cleanUpDropdown('segment');
            this.cleanUpDropdown('entity');
            this.populateSegments(selectedEntity, selectedValue);
            this.populateEntities(selectedValue, selectedSegment);
          });
        break;
      case 'entity':
        this.setState({ selectedEntity: selectedValue },
          () => {
            this.cleanUpDropdown('segment');
            this.cleanUpDropdown('country');
            this.populateCountries(selectedValue, selectedCountry, selectedSegment);
            this.populateSegments(selectedValue, selectedCountry);
          });
        break;
      default:
        break;
    };
  };


  populateGridCountries() {
    const url = `${baseURL}/tfs/country_list/?entity_id=All&country_id=All&segment_id=All`;
    const signal = this.abortController.signal;
    const { accessToken } = this.state;
    COUNTRY.length = 0;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accessToken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          COUNTRY.push(element["country_id"])
        });
      });
  };

  populateGridEntities(selectedCountry) {
    const url = `${baseURL}/tfs/entity_list/?country_id=${selectedCountry}&segment_id=All`;
    const signal = this.abortController.signal;
    const { accessToken } = this.state;
    ENTITY.length = 0;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accessToken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          ENTITY.push({ entity_id: element["entity_id"], country_id: element["country_id"] });
        })
      });
  };

  populateGridTaxType(selectedEntity) {
    //console.log('entity',selectedEntity)
    const url = `${baseURL}/tfs/tax_type/?entity_id=${selectedEntity}`;
    const signal = this.abortController.signal;
    const { accessToken } = this.state;
    fetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accessToken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(element => {
          TAXTYPE.push({ tax_type: element["tax_type"], tax_type_id: element["tax_type_id"], taxcategory_id: element["taxcategory_id"], country_id: element["country_id"] });
          TAXTYPES.set(element["tax_type_id"], element["tax_type"])
        })
      });
  };

  componentDidMount() {
    const { selectedSegment, selectedCountry, selectedEntity } = this.state;
    window.gapi.load('auth2', () => {
      window.gapi.auth2.init({
        client_id: `${process.env.REACT_APP_CLIENT_ID}`,
        cookie_policy: 'none'
      }).then(() => {
        try {
          const user = gapi.auth2.getAuthInstance().currentUser.get();
          if(user){
            user.reloadAuthResponse()
          }
          gapi.auth2.getAuthInstance().currentUser.listen((usr) => this.setState({ accessToken: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token, userName: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail() }))
        }
        catch (error) {
          console.log("trycatch", error)
        }

        this.setState({ accessToken: gapi.auth2.getAuthInstance().isSignedIn.get() });
        if (this.state.accessToken) {
          this.setState({ accessToken: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token, userName: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail() });
          if (this.props.cookies.get('Logins') === "Test") {
            this.props.cookies.set('Logins', gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getId(), { maxAge: 432000, sameSite: false });
          }

          this.setState({ cookies: this.props.cookies.get('Logins') })

          if (this.state.cookies !== "Test" && this.state.cookies) {
            this.populateTaxCategory();
            this.populateCountries(selectedEntity, selectedCountry, selectedSegment);
            this.populateSegments(selectedEntity, selectedCountry);
            this.populateEntities(selectedCountry, selectedSegment);

            this.populateGridCountries();
            this.populateGridEntities("All");
            this.populateGridTaxType("All");
          }
          else {
            gapi.auth2.getAuthInstance().disconnect()
            window.location.href = '/Login';
          }
        }
        else {
          gapi.auth2.getAuthInstance().disconnect()
          window.location.href = '/Login';
        }
      })
    });
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  addDetail = e => {
    const updates = this.gridApi.updateRowData(
      {
        add: [{
          country_id: COUNTRY[0],
          entity_id: null,
          tax_type: null,
          submission_day: 0,
          submission_month: months[0],
          submission_frequency: frequency[0]
        }]
      }
    );
    this.gridApi.startEditingCell({
      rowIndex: updates.add[0].rowIndex,
      colKey: 'tfs_scheduler_id'
    });
  }

  saveDetail = e => {
    var url, apiMethod, recordKey, error, msg, creationDate;
    var taxtypeId;
    var i, j, h, r;
    i = j = h = r = 0;
    error = "";
    this.gridApi.forEachNode((node) => {
      r++;
      const { data } = node;
      taxtypeId = getMapValue(TAXTYPES, data.tax_type);
      recordKey = "";
      recordKey = recordKey.concat(data.tfs_scheduler_id, data.country_id, data.entity_id, data.tax_type, data.submission_day, data.submission_frequency);

      if (recordKey.localeCompare(data.record_key) !== 0) {

        if (data.tfs_scheduler_id > 0) {
          i++;
          url = `${baseURL}/tfs/scheduler/${data.tfs_scheduler_id}/`;
          apiMethod = 'PUT';
          creationDate = null;
        }
        else {
          j++;
          url = `${baseURL}/tfs/scheduler/`;
          apiMethod = 'POST';
          creationDate = moment().format("YYYY-MM-DD");
        }

        fetch(url, {
          method: apiMethod,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer google-oauth2 ${this.state.accessToken}`
          },
          body: JSON.stringify(
            {
              "tfs_scheduler_id": data.tfs_scheduler_id,
              "submission_day": data.submission_day,
              "submission_month": this.monthMappingValue(data.submission_month),
              "submission_frequency": data.submission_frequency,
              "creation_date": creationDate,
              "last_update_date": moment().format("YYYY-MM-DD"),
              "country_id": data.country_id,
              "created_by": this.state.userName,
              "entity_id": data.entity_id,
              "last_updated_by": this.state.userName,
              "tax_type_id": taxtypeId
            }
          )
        }).then(resp => resp.json())
          .catch(error => console.log(error))
      }

    });

    msg = "Number of records created: " + j + "\nNumber of records updated: " + i;
    if (h > 0)
      msg = msg.concat("\nNumber of invalid records: " + h + "\n\nError Summary:\n" + error);

    alert(msg);
    this.fetchInfoDetail();
  }

  monthMappingValue(month) {
    if (month === "January")
      return 1
    else if (month === "February")
      return 2
    else if (month === "March")
      return 3
    else if (month === "April")
      return 4
    else if (month === "May")
      return 5
    else if (month === "June")
      return 6
    else if (month === "July")
      return 7
    else if (month === "August")
      return 8
    else if (month === "September")
      return 9
    else if (month === "October")
      return 10
    else if (month === "November")
      return 11
    else if (month === "December")
      return 12
  }

  deleteDetail = e => {
    var i = 0;
    this.gridApi.forEachNode((node) => {
      const { data } = node;
      if (node.selected) {
        if (data.tfs_scheduler_id) {
          fetch(`${baseURL}/tfs/scheduler/${data.tfs_scheduler_id}/`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer google-oauth2 ${this.state.accessToken}`
            },
          }).catch(error => alert(error));
        }
        i++;
      }
    });

    var selectedData = this.gridApi.getSelectedRows();
    var res = this.gridApi.updateRowData({ remove: selectedData });
    //console.log(res);
    alert("Number of record(s) deleted: " + i);
  }

  getNewCategory() {
    return (
      <div className='input'>
        <MuiThemeProvider theme={formLabelsTheme}>
          <form>
            <TextField
              required
              id="newCategory"
              label="Category Name"
              helperText="Input new category here"
              onChange={e => { this.setState({ newCategory: e.target.value }) }}
            />
            <button id="#saveCategory" className="account-balance-detail-button" onClick={this.saveCategory}>Save</button>
          </form>
        </MuiThemeProvider>
      </div>
    )
  }

  saveCategory() {
    if (this.state.newCategory === "") {
      alert('Please enter a value for Category Name.')
    }
    else {
      const url = `${baseURL}/tfs/save_wht_category/`;
      fetch(url, {
        signal: AbortController.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer google-oauth2 ${this.state.accessToken}`
        },
        body: JSON.stringify(
          {
            "entity_id": this.props.entity,
            "category": this.state.newCategory
          }
        )
      }
      ).then(response => response.json())
        .then(data => {
          this.setState({ getopenModalNewCategory: false });
          this.fetchWHTDetail();
        }).
        catch(e => console.log('error', e))


    }
  }

  disableButton(editable) {
    if (editable === 'Y' || this.props.tfsSubmissionId === undefined) {
      return false
    }
    else {
      return true
    }
  }

  render() {

    const { selectedTaxCategory } = this.state.selectedTaxCategory;
    const { selectedSegment } = this.state.selectedSegment;
    const { selectedCountry } = this.state.selectedCountry;
    const { selectedEntity } = this.state.selectedEntity;

    return (
      <div>
        <div className="content">
          <div className="fscp-container" style={{ height: '100%' }}>
            <div className="fscp-wrapper">
              <div className="fscp-search">
                <Select
                  styles={selectStyle}
                  options={TaxCategory}
                  placeholder={'Tax Category'}
                  name='TaxCategory'
                  value={selectedTaxCategory}
                  onChange={this.onChangeHandler}
                />
                <Select
                  styles={selectStyle}
                  options={Countries}
                  placeholder={'Country'}
                  name='country'
                  value={selectedCountry}
                  onChange={this.onChangeHandler}
                />
                <Select
                  styles={selectStyle}
                  options={Segments}
                  placeholder={'Segment'}
                  name='segment'
                  value={selectedSegment}
                  onChange={this.onChangeHandler}
                />
                <Select
                  styles={selectStyle}
                  options={Entities}
                  placeholder={'Entity'}
                  name='entity'
                  value={selectedEntity}
                  onChange={this.onChangeHandler}
                />
                <div className="fscp-btn-wrapper">
                  <button className="fscp-btn btn-focus" onClick={e => this.fetchInfoDetail()}>Search</button>
                  <ExportTaxSchedulerInfo rowData={this.state.rowData} />
                </div>
              </div>
              <div className="ag-theme-balham" style={{ height: gridHeight + 'px' }} >
                <AgGridReact
                  id="agGridInfo"
                  gridOptions={this.gridOptions}
                  pagination={false}
                  rowMultiSelectWithClick={false}
                  rowDragManaged={true}
                  paginationPageSize={pageSize}
                  suppressMiniFilter={true}
                  suppressRowClickSelection={false}
                  suppressHorizontalScroll={true}
                  suppressVerticalScroll={true}
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}
                  rowHeight={28}
                  rowSelection='multiple'
                  singleClickEdit={true}
                  stopEditingWhenGridLosesFocus={true}
                  onGridReady={this.onGridReady}
                  onComponentStateChanged={this.onComponentStateChanged}
                >
                </AgGridReact>
              </div>
            </div>
          </div>
          <div className="account-balance-detail-button-container">
            <button id="#save" className={this.disableButton(this.props.buttonEditable) ? "account-balance-detail-button-disabled btn-focus" : "account-balance-detail-button btn-focus"} disabled={this.disableButton(this.props.buttonEditable)} onClick={this.saveDetail}>Save</button>
            <button id="#add" className={this.disableButton(this.props.buttonEditable) ? "account-balance-detail-button-disabled btn-focus" : "account-balance-detail-button btn-focus"} disabled={this.disableButton(this.props.buttonEditable)} onClick={this.addDetail}>Add</button>
            <button id="#delete" className={this.disableButton(this.props.buttonEditable) ? "account-balance-detail-button-disabled btn-focus" : "account-balance-detail-button btn-focus"} disabled={this.disableButton(this.props.buttonEditable)} onClick={this.deleteDetail}>Delete</button>
          </div>
        </div>
      </div>
    );
  }
};

function submissionDayValueGetter(params) {
  if (params.data.submission_frequency === "Monthly" && params.data.submission_day > 30) {
    return (30);
  }
  else if (params.data.submission_frequency === "Quarterly" && params.data.submission_day > 90) {
    return (90);
  }
  else if (params.data.submission_frequency === "Annually" && params.data.submission_day > 365) {
    return (365);
  }
  else { return (params.data.submission_day) }
}


function submissionMonthGetter(params) {
  if (params.data.submission_month === 1) {
    return ("January");
  }
  else if (params.data.submission_month === 2) {
    return ("February");
  }
  else if (params.data.submission_month === 3) {
    return ("March");
  }
  else if (params.data.submission_month === 4) {
    return ("April");
  }
  else if (params.data.submission_month === 5) {
    return ("May");
  }
  else if (params.data.submission_month === 6) {
    return ("June");
  }
  else if (params.data.submission_month === 7) {
    return ("July");
  }
  else if (params.data.submission_month === 8) {
    return ("August");
  }
  else if (params.data.submission_month === 9) {
    return ("September");
  }
  else if (params.data.submission_month === 10) {
    return ("October");
  }
  else if (params.data.submission_month === 11) {
    return ("November");
  }
  else if (params.data.submission_month === 12) {
    return ("December");
  }
  else
    return params.data.submission_month;
}

function getMapValue(map, searchValue) {
  for (let [key, value] of map.entries()) {
    if (value === searchValue)
      return key;
  }
}

function cellStyle() {
  return { 'textAlign': 'center' };
};

function cellStyleLeft() {
  return { 'textAlign': 'left' };
};

export default withRouter(withCookies(TaxSubmissionScheduleInfo));