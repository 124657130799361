import React, { Component } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class ExportTaxSchedulerInfo extends Component {
    renderTableHeader() {
        return (
            <>
                <th key="Country">Country</th>
                <th key="Entity">Entity</th>
                <th key="Tax Type">Tax Type</th>
                <th key="Submission Day">Submission Day</th>
                <th key="Frequency">Frequency</th>
            </>
        )
    }

    setHTML(props) {
        return props ? { __html: props.replace(/<div>/g, "<br style=mso-data-placement:same-cell; />").replace(/<\/div>/g, "").replace(/<br>/g, "<br style=mso-data-placement:same-cell; />") } : { __html: props }
    }

    renderTableData() {
        const { rowData } = this.props;
        return rowData.map((data) => {
            const { country_id, entity_id, tax_type, submission_day, submission_frequency
            } = data;
            return (
                <tr key={country_id}>
                    <td>{country_id}</td>
                    <td>{entity_id}</td>
                    <td>{tax_type}</td>
                    <td>{submission_day}</td>
                    <td>{submission_frequency}</td>
                </tr>
            )
        })
    }

    render() {
        const fileName = `Tax Submission Schedule Information`;
        return (
            <>
                <ReactHTMLTableToExcel
                    id="FscpDetail-Export"
                    className={"fscp-btn btn-focus"}
                    table="FscpDetail-Table"
                    filename={fileName}
                    sheet={fileName}
                    buttonText="Export" />
                <table hidden id="FscpDetail-Table">
                    <tbody>
                        <tr>{this.renderTableHeader()}</tr>
                        {this.renderTableData()}
                    </tbody>
                </table>
            </>
        );
    }
}

export default ExportTaxSchedulerInfo;