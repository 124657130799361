import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Login from './components/Login';
import Redirect from './components/Redirect';
import {Route, BrowserRouter} from 'react-router-dom'
import {CookiesProvider} from 'react-cookie'

// welcome message to all fellow developers
console.log(
    '%c 🥂 Built by FinSys Team 🍻',
    'background: #000; color: #FFDE00; font-size:10px; padding: 8px'
)

const routing = (
  <CookiesProvider>
      <BrowserRouter>
           <Route exact path="/Login" component={Login}/>
           <Route exact path="/" component={App}/>
           <Route exact path="/taxsubmissions" component={App}/>
           <Route exact path="/createsubmission" component={App}/>
           <Route exact path='/taxsubmissions/submission/:submission_id' component={App}/>
           <Route exact path="/manageflow" component={App}/>
           <Route exact path="/redirect" component={Redirect}/>
           <Route exact path="/taxsubmissionscheduleinfo" component={App}/>
      </BrowserRouter>
  </CookiesProvider>
)

ReactDOM.render(routing, document.getElementById('root'));
