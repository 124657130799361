import React from 'react';
import ApprovalLogo from '../images/sprite/flow_approve.png'
import EmptyLogo from '../images/sprite/flow_empty.png'
import ArrowLogo from '../images/sprite/Arrow.png'

const ShowList = props => {
    const theLists = props.flow.map((flow, i) => {

        return (
            <div>
                <div style={{ inlineSize: 'inherit' }}>
                    <div className="flow-container-summary" style={{ height: '100%', fontWeight: 'bold' }}>
                        <table className="table-flow">
                            <tbody>
                                <tr>
                                    <td style={flow.approve_date_format ? { inlineSize: 'inherit', background: 'lightgreen' } : flow.skipped === "Y" ? { inlineSize: 'inherit', background: 'darkgrey' } : { inlineSize: 'inherit' }} className="td-flow" colspan="2">
                                        {flow.skipped === "Y" ? flow.role_flow + " (SKIPPED)" : flow.role_flow}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="td-image">
                                        <img src={flow.approve_date_format ? ApprovalLogo : EmptyLogo} className="approval-logos" />
                                    </td>
                                    <td className="td-emaildate">
                                        <tr>
                                            {flow.first_name + " " + flow.last_name}
                                        </tr>
                                        <tr className="tr-date">
                                            {flow.user_email}
                                        </tr>
                                        <tr className="tr-date">
                                            {flow.approve_date_format}
                                        </tr>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div>
                    {props.flow.length === i + 1 ? null : <img src={ArrowLogo} className="arrow-logos" />}
                </div>
            </div>
        )
    })
    return theLists;
}

const ShowFlowSummary = props => {
    return (
        <div className="content" onClick={props.isMenuExpanded ? props.menuToggle : null}>
            <div className="menu-container" style={{ height: '100%' }}>
                <ul className="flow-list-summary">
                    {props.flow.length > 0 ? <ShowList flow={props.flow} /> : <label>Flow Not Found.</label>}
                </ul>
            </div>
        </div>
    );
};


export default ShowFlowSummary;
