import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { gapi } from 'gapi-script'
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

const baseURL = `${process.env.REACT_APP_API_URL}`;
var ACCOUNTS = [];
var rowData = [];

var submissionId = "";
var totalDetailAmount = 0;
var totalAmountWithPenalty = 0;

var currency = "";
var entity = "";
var taxType = "";
var windowHeight = window.innerHeight;
var bufferSize = Math.ceil(windowHeight * 0.125);
var gridHeight = Math.ceil((windowHeight * 0.75) * 0.5) + bufferSize;
var pageSize = Math.floor(gridHeight / 28) - 2;
gridHeight = (pageSize * 28) + bufferSize;

class AmountDetailVAT extends Component {
    constructor(props) {
        super(props);

        currency = this.props.currency;
        entity = this.props.entity;
        taxType = this.props.taxtype;
        rowData = this.props.rowData;
        if (this.props.totalAmout > 0) {
            totalDetailAmount = this.props.totalAmout;
            totalAmountWithPenalty= this.props.totalAmout;
        }

        this.state = {
            columnDefs: [
                {
                    headerName: "Record Key",
                    field: "vat_lookup_id",
                    hide: true
                },
                {
                    headerName: "Account",
                    field: "account_description",
                    width: (window.innerWidth * 0.5) * 0.5,
                    editable: false,
                    cellEditor: "agSelectCellEditor",
                    cellEditorParams: { values: ACCOUNTS },
                    cellStyle: genericCellStyle
                },
                {
                    headerName: "Amount",
                    field: "amount",
                    width: (window.innerWidth * 0.5) * 0.15,
                    editable: function (params) {
                        return params.data.calculated.toUpperCase() === "N"
                    },
                    type: "numericColumn",
                    valueFormatter: numberFormat,
                    cellStyle: userInputCellStyle,
                    valueGetter: function (params) {
                        if (params.data.account === "input_vat" || params.data.account === "output_vat")
                            return "";
                        else
                            return params.data.amount;
                    }
                }
            ],
            //rowData: [],
            accessToken: '',
            userName: '',
            cookies: '',
        }

        //this.fetchVATDetail = this.fetchVATDetail.bind(this);
        this.onGridReady = this.onGridReady.bind(this);
    }

    abortController = new AbortController();

    fetchVATDetail = () => {
        document.getElementById("#currency").value = this.props.currency;
        document.getElementById("#entity").value = this.props.entity;
        document.getElementById("#taxtype").value = this.props.taxType;
        document.getElementById("#vat_amount").value = parseFloat(totalAmountWithPenalty).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    componentDidMount() {
        window.gapi.load('auth2', () => {
            window.gapi.auth2.init({
                client_id: `${process.env.REACT_APP_CLIENT_ID}`,
                cookie_policy: 'none'
            }).then(() => {
                try {
                    const user = gapi.auth2.getAuthInstance().currentUser.get();
                    if(user){
                        user.reloadAuthResponse()
                    }
                    gapi.auth2.getAuthInstance().currentUser.listen((usr) => this.setState({ accessToken: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token, userName: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail() }))
                }
                catch (error) {
                    console.log("trycatch", error)
                }

                this.setState({ accessToken: gapi.auth2.getAuthInstance().isSignedIn.get() });
                if (this.state.accessToken) {
                    this.setState({ accessToken: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token, userName: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail() });
                    if (this.props.cookies.get('Logins') === "Test") {
                        this.props.cookies.set('Logins', gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getId(), { maxAge: 432000, sameSite: false });
                    }

                    this.setState({ cookies: this.props.cookies.get('Logins') })

                    if (this.state.cookies !== "Test" && this.state.cookies) {
                        this.fetchVATDetail();
                    }
                    else {
                        gapi.auth2.getAuthInstance().disconnect()
                        window.location.href = '/Login';
                    }
                }
                else {
                    gapi.auth2.getAuthInstance().disconnect()
                    window.location.href = '/Login';
                }
            })
        });
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    onGridReady(params) {
        this.gridApi = params.api;
        //this.gridApi.setRowData(vatSubmit);
        this.columnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
    }

    saveVATDetail = e => {
        var recordKey, error, msg;
        var categoryId;
        var i;
        var r = 0;
        error = "";
        msg = "";
        totalDetailAmount = 0;
        totalAmountWithPenalty = 0;
        var tfsSubmissionId = this.props.tfsSubmissionId;
        var type = this.props.type;
        var isNewType = false;

        this.gridApi.forEachNode((node) => {
            const { data } = node;
            if (data.account === "vat_receivable" || data.account === "total_input") {
                totalDetailAmount += parseFloat(data.amount);
                totalAmountWithPenalty -= parseFloat(data.amount)

            }

            if (data.account === "total_standard_output_taxt"){
                totalDetailAmount = parseFloat(data.amount) - totalDetailAmount
                totalAmountWithPenalty += parseFloat(data.amount)
            }

            if (data.account === "vat_receivable_/_payable") {
                data.amount = totalDetailAmount;
            }

            if(data.account === "penalty/surcharge"){
                totalAmountWithPenalty += parseFloat(data.amount)
            }

            if(data.account === "total_amount"){
                data.amount = totalAmountWithPenalty
            }

            if (data.type !== type) {
                isNewType = true;
            }

        });

        const { userName } = this.state;
        let data = Object.keys(rowData).map(function (item) {
            if (isNewType) {
                return {
                    account: rowData[item].account,
                    amount: rowData[item].amount,
                    type: type,
                    tfs_submission_id: rowData[item].tfs_submission_id,
                    account_description: rowData[item].account_description,
                    calculated: rowData[item].calculated,
                    style: rowData[item].style,
                    created_by: rowData[item].created_by ? rowData[item].created_by : userName,
                    creation_date: rowData[item].creation_date ? rowData[item].creation_date : new Date(),
                    last_update_date: new Date(),
                    last_updated_by: userName
                }
            }
            else {
                return {
                    vat_submission_id: isNewType ? undefined : rowData[item].vat_submission_id,
                    account: rowData[item].account,
                    amount: rowData[item].amount,
                    type: type,
                    tfs_submission_id_tfs_submission_id: rowData[item].tfs_submission_id,
                    account_description: rowData[item].account_description,
                    calculated: rowData[item].calculated,
                    style: rowData[item].style,
                    created_by_user_email: rowData[item].created_by ? rowData[item].created_by : userName,
                    creation_date: rowData[item].creation_date ? rowData[item].creation_date : new Date(),
                    last_update_date: new Date(),
                    last_updated_by_user_email: userName
                }
            }
        })

        if (isNewType) {
            const url = `${baseURL}/tfs/create_vat_submission/`;
            this.abortController.abort();
            this.abortController = new AbortController();
            const signal = this.abortController.signal;
            fetch(url, {
                signal: signal,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer google-oauth2 ${this.state.accessToken}`
                },
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then((jsonStr) => {
                })
                .catch(e => console.log('commente', e));
        }
        else {
            const url = `${baseURL}/tfs/save_vat_submission/`;
            this.abortController.abort();
            this.abortController = new AbortController();
            const signal = this.abortController.signal;
            fetch(url, {
                signal: signal,
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer google-oauth2 ${this.state.accessToken}`
                },
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then((jsonStr) => {
                })
                .catch(e => console.log('commente', e));
        }

        //save total amount in tfs_submission
        fetch(`${baseURL}/tfs/save_amount_submission/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer google-oauth2 ${this.state.accessToken}`
            },
            body: JSON.stringify({
                "tfs_submission_id": tfsSubmissionId,
                "type": type,
                "amount": totalAmountWithPenalty,
                "amount_usd_indicative": this.props.USDRate ? totalAmountWithPenalty * this.props.USDRate : 0
            })
        }).catch(error => alert(error));

        this.gridApi.refreshCells();
        this.props.getTotalAmout(parseFloat(totalAmountWithPenalty), type);
        msg = `VAT detail has been saved. \n
        Total Amount (receivable)/payable (C-B-A+D): ${parseFloat(totalAmountWithPenalty).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
        `;
        alert(msg);
        document.getElementById("#vat_amount").value = parseFloat(totalAmountWithPenalty).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    disableButton(editable) {
        if (editable === 'Y' || this.props.tfsSubmissionId === undefined) {
            return false
        }
        else {
            return true
        }
    }

    render() {
        return (
            <div>
                <div className="account-balance-detail-label">
                    <form>
                        <label className="label-field-right">Entity:</label> <input id="#entity" type="text" className="input-field-left" disabled /><br></br>
                        <label className="label-field-right">Tax Type:</label> <input id="#taxtype" type="text" className="input-field-left" disabled /><br></br>
                        <label className="label-field-right">Currency:</label> <input id="#currency" type="text" className="input-field-left" disabled /><br></br>
                        <label className="label-field-right">Amount:</label> <input id="#vat_amount" type="text" className="input-field-left" disabled /><br></br>
                    </form>
                </div>
                <br></br>
                <div className="ag-theme-balham" style={{ height: gridHeight + 'px' }} >
                    <AgGridReact
                        id="agGridVATDetail"
                        gridOptions={this.gridOptions}
                        pagination={false}
                        rowMultiSelectWithClick={false}
                        rowDragManaged={true}
                        paginationPageSize={pageSize}
                        suppressMiniFilter={true}
                        suppressRowClickSelection={false}
                        suppressHorizontalScroll={true}
                        suppressVerticalScroll={true}
                        columnDefs={this.state.columnDefs}
                        rowData={rowData}
                        rowHeight={28}
                        rowSelection='multiple'
                        singleClickEdit={true}
                        stopEditingWhenGridLosesFocus={true}
                        onGridReady={this.onGridReady}
                        onComponentStateChanged={this.onComponentStateChanged}
                    >
                    </AgGridReact>
                </div>
                <div className="account-balance-detail-button-container">
                    <button id="#save" className={this.disableButton(this.props.buttonEditable) ? "account-balance-detail-button-disabled" : "account-balance-detail-button"} disabled={this.disableButton(this.props.buttonEditable)} onClick={this.saveVATDetail}>Save</button>
                </div>
            </div>

        );
    }
};

function genericCellStyle(params) {
    if (params.data.style.toUpperCase().includes("BOLD"))
        return { 'font-weight': 'bold' };
};

function userInputCellStyle(params) {
    if (params.data.calculated.toUpperCase() === "N")
        return { 'background-color': '#ffeb3b4d' };
    else
        return { 'background-color': '#9e9e9e63' }
};

function numberFormat(params) {
    if (params.data.account.toLowerCase() !== "input_vat" && params.data.account.toLowerCase() !== "output_vat") {
        return parseFloat(params.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
};

export default withRouter(withCookies(AmountDetailVAT));