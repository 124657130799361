import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { gapi } from 'gapi-script'
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';

const baseURL = `${process.env.REACT_APP_API_URL}`;
var ACCOUNTS = [];

var totalDetailAmount = 0;
var tempTotalAmount = 0;
var amount1 = 0;
var amount2 = 0;
var amount3 = 0;
var currency = "";
var entity = "";
var taxType = "";
var tab = "";
var windowHeight = window.innerHeight;
var bufferSize = Math.ceil(windowHeight * 0.125);
var gridHeight = Math.ceil((windowHeight * 0.75) * 0.7) + bufferSize;
var pageSize = Math.floor(gridHeight / 28) - 2;
gridHeight = (pageSize * 28) + bufferSize;

class AmountDetailCIT extends Component {
    constructor(props) {
        super(props);

        currency = this.props.currency;
        entity = this.props.entity;
        taxType = this.props.taxtype;
        tab = this.props.tab;
        amount1 = this.props.totalAmount1 !== undefined ? this.props.totalAmount1 : 0;
        amount2 = this.props.totalAmount2 !== undefined ? this.props.totalAmount2 : 0;
        amount3 = this.props.totalAmount3 !== undefined ? this.props.totalAmount3 : 0;
        totalDetailAmount = amount1 + amount2 + amount3;

        this.state = {
            columnDefs: [
                {
                    headerName: "Account",
                    field: "account_description",
                    width: (window.innerWidth * 0.5) * 0.5,
                    editable: false,
                    cellEditor: "agSelectCellEditor",
                    cellEditorParams: { values: ACCOUNTS },
                    cellStyle: genericCellStyle
                },
                {
                    headerName: "Amount",
                    field: "amount",
                    width: (window.innerWidth * 0.5) * 0.15,
                    editable: function (params) {
                        return params.data.calculated.toUpperCase() === "N"
                    },
                    type: "numericColumn",
                    valueFormatter: numberFormat,
                    cellStyle: userInputCellStyle
                },
                {
                    headerName: "IsCalculated",
                    field: "calculated",
                    hide: true
                },
                {
                    headerName: "Style",
                    field: "style",
                    hide: true
                }
            ],
            rowData: [],
            accessToken: '',
            userName: '',
            cookies: '',
            taxPercent: ''
        }

        this.fetchCITDetail = this.fetchCITDetail.bind(this);
        this.onGridReady = this.onGridReady.bind(this);
        this.resetCITDetail = this.resetCITDetail.bind(this);
    }

    abortController = new AbortController();

    fetchCITDetail = () => {
        if (this.props.isCreate) {
            this.setState({ rowData: this.props.rowData });
            document.getElementById("#taxPercentage").value = parseFloat(this.props.taxPercent);
            this.setState({ taxPercent: this.props.taxPercent });
        }
        else {
            const url = `${baseURL}/tfs/cit_submission/?submission_id=${this.props.tfsSubmissionId}&type=${this.props.type}&tab=${tab}`;
            totalDetailAmount = this.props.totalAmout;
            fetch(url, {
                signal: AbortController.signal,
                method: 'GET',
                headers: { 'Authorization': `Bearer google-oauth2 ${this.state.accessToken}` }
            }
            ).then(result => result.json())
                .then(rowData => {
                    this.setState({ rowData, taxPercent: rowData.filter(filter => { return filter.account === 'tax_%' })[0].amount })
                    const { taxPercent, rowdata } = this.state;

                    rowData.forEach(function (element, index, array) {
                        if (element.account.toLowerCase() === "tax_@_%") {
                        }
                    });

                    document.getElementById("#taxPercentage").value = parseFloat(taxPercent);
                })
        }

        document.getElementById("#currency").value = this.props.currency;
        document.getElementById("#entity").value = this.props.entity;
        document.getElementById("#taxtype").value = this.props.taxType;
        document.getElementById("#cit_amount").value = parseFloat(totalDetailAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    componentDidMount() {
        window.gapi.load('auth2', () => {
            window.gapi.auth2.init({
                client_id: `${process.env.REACT_APP_CLIENT_ID}`,
                cookie_policy: 'none'
            }).then(() => {
                try {
                    const user = gapi.auth2.getAuthInstance().currentUser.get();
                    if(user){
                        user.reloadAuthResponse()
                    }
                    gapi.auth2.getAuthInstance().currentUser.listen((usr) => this.setState({ accessToken: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token, userName: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail() }))
                }
                catch (error) {
                    console.log("trycatch", error)
                }

                this.setState({ accessToken: gapi.auth2.getAuthInstance().isSignedIn.get() });
                if (this.state.accessToken) {
                    this.setState({ accessToken: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token, userName: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail() });
                    if (this.props.cookies.get('Logins') === "Test") {
                        this.props.cookies.set('Logins', gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getId(), { maxAge: 432000, sameSite: false });
                    }

                    this.setState({ cookies: this.props.cookies.get('Logins') })

                    if (this.state.cookies !== "Test" && this.state.cookies) {
                        this.fetchCITDetail();
                    }
                    else {
                        gapi.auth2.getAuthInstance().disconnect()
                        window.location.href = '/Login';
                    }
                }
                else {
                    gapi.auth2.getAuthInstance().disconnect()
                    window.location.href = '/Login';
                }
            })
        });
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    initArrays() {
        var url;
        url = `${baseURL}/tfs/vat_lookup/`;
        ACCOUNTS.length = 0;
        fetch(url, {
            signal: AbortController.signal,
            method: 'GET',
            headers: { 'Authorization': `Bearer google-oauth2 ${this.state.accessToken}` }
        }
        ).then(result => result.json())
            .then(lookup => {
                lookup.forEach(element => {
                    ACCOUNTS.push(element["account"]);
                })
            });
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
    }

    disableButton(editable) {
        if (editable === 'Y' || this.props.tfsSubmissionId === undefined) {
            return false
        }
        else {
            return true
        }
    }


    saveCITDetail = e => {

        if (document.getElementById("#taxPercentage").value === "" && this.props.tab === 1) {
            alert("Please enter a value for Tax %.");
        }
        else {
            var msg;
            msg = "";
            totalDetailAmount = 0;
            var revenue, grossProfit, gpMargin,
                pbt, pbtMargin, other_income,
                chargeableIncome, opex,
                timingPermanentDiff, unutilTaxLoses,
                taxableIncome, chargeableIncome,
                tax, withholding_tax_utilised,
                net_tax_payable;
            revenue = grossProfit = gpMargin =
                pbt = pbtMargin = other_income =
                chargeableIncome = opex =
                timingPermanentDiff = unutilTaxLoses =
                taxableIncome = chargeableIncome =
                tax = withholding_tax_utilised = net_tax_payable = 0;

            var taxPercent = document.getElementById("#taxPercentage").value === "" ? 0 : document.getElementById("#taxPercentage").value;
            var tfsSubmissionId = this.props.tfsSubmissionId;
            var type = this.props.type;
            var isNewType = false;

            this.gridApi.forEachNode((node) => {
                const { data } = node;
                if (data.account.toLowerCase() === "revenue" || data.account.toLowerCase() === "cor") {
                    grossProfit += parseFloat(data.amount);

                    if (data.account.toLowerCase() === "revenue")
                        revenue = parseFloat(data.amount);
                }

                if (data.account.toLowerCase() === "gross_profit")
                    data.amount = grossProfit;

                if (data.account.toLowerCase() === "gp_margin") {
                    if (grossProfit !== 0 && revenue !== 0)
                        data.amount = grossProfit / revenue;
                }

                if (data.account.toLowerCase() === "other_income")
                    other_income = parseFloat(data.amount);

                if (data.account.toLowerCase() === "opex")
                    opex = parseFloat(data.amount);

                if (data.account.toLowerCase() === "pbt")
                    data.amount = pbt = grossProfit + other_income + opex;

                if (data.account.toLowerCase() === "pbt_margin") {
                    if (pbt !== 0 && revenue !== 0)
                        data.amount = pbt / revenue;
                }

                if (data.account.toLowerCase() === "timing_differences" || data.account.toLowerCase() === "permanent_differences")
                    timingPermanentDiff += parseFloat(data.amount);

                if (data.account.toLowerCase() === "taxable_income")
                    data.amount = taxableIncome = timingPermanentDiff + pbt;

                if (data.account.toLowerCase() === "unutilised_tax_lossess")
                    unutilTaxLoses = parseFloat(data.amount);

                if (data.account.toLowerCase() === "chargeable_income")
                    data.amount = chargeableIncome = taxableIncome + unutilTaxLoses < 0 ? 0 : taxableIncome + unutilTaxLoses;

                if (data.account.toLowerCase() === "tax_@_%") {
                    data.amount = net_tax_payable = tax = chargeableIncome * taxPercent / 100 < 0 ? 0 : chargeableIncome * taxPercent / 100;
                    data.account_description = "Tax @" + taxPercent + " %";
                }

                if (data.account.toLowerCase() === "withholding_tax_utilised"){
                    withholding_tax_utilised = parseFloat(data.amount);
                }

                if (data.account.toLowerCase() === "net_tax_payable") {
                    data.amount = net_tax_payable = tax + withholding_tax_utilised;
                }
            });

            //save to database
            const { userName, rowData } = this.state;
            if (rowData[0].cit_lookup_id) {
                isNewType = true;
            }

            let data = Object.keys(rowData).map(function (item) {
                if (isNewType) {
                    return {
                        cit_submission_id: isNewType ? undefined : rowData[item].cit_submission_id,
                        account: rowData[item].account,
                        amount: rowData[item].account === "tax_%" ? taxPercent : rowData[item].amount,
                        type: type,
                        tfs_submission_id: tfsSubmissionId,
                        account_description: rowData[item].account_description,
                        calculated: rowData[item].calculated,
                        style: rowData[item].style,
                        created_by: rowData[item].created_by ? rowData[item].created_by : userName,
                        creation_date_: rowData[item].creation_date ? rowData[item].creation_date : new Date(),
                        last_update_date: new Date(),
                        last_updated_by: userName,
                        tab: tab
                    }
                }
                else {
                    return {
                        cit_submission_id: isNewType ? undefined : rowData[item].cit_submission_id,
                        account: rowData[item].account,
                        amount: rowData[item].account === "tax_%" ? taxPercent : rowData[item].amount,
                        type: type,
                        tfs_submission_id_tfs_submission_id: tfsSubmissionId,
                        account_description: rowData[item].account_description,
                        calculated: rowData[item].calculated,
                        style: rowData[item].style,
                        created_by_user_email: rowData[item].created_by ? rowData[item].created_by : userName,
                        creation_date_: rowData[item].creation_date ? rowData[item].creation_date : new Date(),
                        last_update_date: new Date(),
                        last_updated_by_user_email: userName,
                        tab: tab
                    }
                }
            })

            if (isNewType) {
                const url = `${baseURL}/tfs/create_cit_submission/`;
                this.abortController.abort();
                this.abortController = new AbortController();
                const signal = this.abortController.signal;
                if (!this.props.isCreate) {
                    fetch(url, {
                        signal: signal,
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer google-oauth2 ${this.state.accessToken}`
                        },
                        body: JSON.stringify(data)
                    })
                        .then(response => response.json())
                        .then((jsonStr) => {
                            if (jsonStr[0].cit_submission_id) {
                                this.saveTotalAmount(type, net_tax_payable, msg, taxPercent);
                                this.setState({ rowData: jsonStr });
                            }
                        })
                        .catch(e => console.log('commente', e));
                }
                else {
                    this.saveTotalAmount(type, net_tax_payable, msg, taxPercent);
                }
            }
            else {
                const url = `${baseURL}/tfs/save_cit_submission/`;
                this.abortController.abort();
                this.abortController = new AbortController();
                const signal = this.abortController.signal;
                if (!this.props.isCreate) {
                    fetch(url, {
                        signal: signal,
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer google-oauth2 ${this.state.accessToken}`
                        },
                        body: JSON.stringify(data)
                    })
                        .then(response => response.json())
                        .then((jsonStr) => {
                            if (jsonStr[0].cit_submission_id) {
                                this.saveTotalAmount(type, net_tax_payable, msg, taxPercent)
                            }
                        })
                        .catch(e => console.log('commente', e));
                }
            }
        }
    }

    saveTotalAmount(type, net_tax_payable, msg, taxPercent) {
        if (this.props.isCreate) {
            if (tab === 1) {
                amount1 = parseFloat(net_tax_payable);
                this.props.getTotalAmout(parseFloat(amount1), type);
            }
            if (tab === 2) {
                amount2 = parseFloat(net_tax_payable);
                this.props.getTotalAmout(parseFloat(amount2), type);
            }
            if (tab === 3) {
                amount3 = parseFloat(net_tax_payable);
                this.props.getTotalAmout(parseFloat(amount3), type);
            }

            totalDetailAmount = parseFloat(amount1) + parseFloat(amount2) + parseFloat(amount3);

            this.gridApi.refreshCells();
            this.props.getTaxPercent(parseFloat(taxPercent), type);
            msg = "CIT detail has been saved.\nTotal Tax amount: " + parseFloat(totalDetailAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            alert(msg);
            document.getElementById("#cit_amount").value = parseFloat(totalDetailAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        }
        else {
            this.getTotalCITAmount(type, msg, taxPercent)
        }
    }

    getTotalCITAmount(type, msg, taxPercent) {
        var url;
        tempTotalAmount = 0;
        url = `${baseURL}/tfs/cit_total_amount/?submission_id=${this.props.tfsSubmissionId}`;
        fetch(url, {
            signal: AbortController.signal,
            method: 'GET',
            headers: { 'Authorization': `Bearer google-oauth2 ${this.state.accessToken}` }
        }
        ).then(result => result.json())
            .then(details => {
                details.forEach(element => {
                    tempTotalAmount += parseFloat(element.amount)
                })

                totalDetailAmount = tempTotalAmount;
                this.props.getTotalAmout(parseFloat(totalDetailAmount), type);

                fetch(`${baseURL}/tfs/save_amount_submission/`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer google-oauth2 ${this.state.accessToken}`
                    },
                    body: JSON.stringify({
                        "tfs_submission_id": this.props.tfsSubmissionId,
                        "type": type,
                        "amount": totalDetailAmount,
                        "amount_usd_indicative": this.props.USDRate ? totalDetailAmount * this.props.USDRate : 0
                    })
                }).catch(error => alert(error));

                this.gridApi.refreshCells();
                this.props.getTaxPercent(parseFloat(taxPercent), type);
                msg = "CIT detail has been saved.\nTotal Tax amount: " + parseFloat(totalDetailAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                alert(msg);
                document.getElementById("#cit_amount").value = parseFloat(totalDetailAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            });
    }

    resetCITDetail() {
        if (tab === 1) {
            amount1 = 0;
            this.props.getTotalAmout(parseFloat(amount1), this.props.type);
        }
        if (tab === 2) {
            amount2 = 0;
            this.props.getTotalAmout(parseFloat(amount2), this.props.type);
        }
        if (tab === 3) {
            amount3 = 0;
            this.props.getTotalAmout(parseFloat(amount3), this.props.type);
        }

        totalDetailAmount = parseFloat(amount1) + parseFloat(amount2) + parseFloat(amount3);
        document.getElementById("#cit_amount").value = parseFloat(totalDetailAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        document.getElementById("#taxPercentage").value = "";
        this.props.getTaxPercent("", this.props.type);
        this.setState({ taxPercent: "" });

        this.gridApi.forEachNode((node) => {
            const { data } = node;
            data.amount = 0;
            if (data.account.toLowerCase() === "tax_@_%") {
                data.account_description = "Tax @ %";
            }
        });
        this.gridApi.refreshCells();
    }

    handleChange(event) {
        if (event.target.value < 0) event.target.value = 0;
        if (event.target.value > 100) event.target.value = 100;
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="account-balance-detail-label column-modal ">
                        <label className="label-field-right">Tax %:</label> <input id="#taxPercentage" type="number"
                            className="input-field-left-percent" onChange={this.handleChange} /><span className="asterisk-super">*</span><br /><br></br>
                    </div>
                </div>
                <div className="row">
                    <div className="ag-theme-balham" style={{ height: 399 + 'px' }} >
                        <AgGridReact
                            id="agGridCITDetail"
                            gridOptions={this.gridOptions}
                            pagination={false}
                            rowMultiSelectWithClick={false}
                            rowDragManaged={true}
                            paginationPageSize={pageSize}
                            suppressMiniFilter={true}
                            suppressRowClickSelection={false}
                            suppressHorizontalScroll={true}
                            suppressVerticalScroll={true}
                            columnDefs={this.state.columnDefs}
                            rowData={this.state.rowData.filter(filter => { return filter.account_description !== 'Tax % from user input' })}
                            rowHeight={28}
                            rowSelection='multiple'
                            singleClickEdit={true}
                            stopEditingWhenGridLosesFocus={true}
                            onGridReady={this.onGridReady}
                            onComponentStateChanged={this.onComponentStateChanged}
                        >
                        </AgGridReact>
                    </div>
                </div>
                <div className="account-balance-detail-button-container">
                    <button id="#reset" className={this.disableButton(this.props.buttonEditable) ? "account-balance-detail-button-disabled" : "account-balance-detail-button"} disabled={this.disableButton(this.props.buttonEditable)} onClick={this.resetCITDetail}>Reset</button>
                    <button id="#save" className={this.disableButton(this.props.buttonEditable) ? "account-balance-detail-button-disabled" : "account-balance-detail-button"} disabled={this.disableButton(this.props.buttonEditable)} onClick={this.saveCITDetail}>Save</button>
                </div>
            </div>
        );
    }
};

function genericCellStyle(params) {
    if (params.data.style.toUpperCase().includes("BOLD"))
        return { 'font-weight': 'bold' };
};

function userInputCellStyle(params) {
    if (params.data.calculated.toUpperCase() === "N")
        return { 'background-color': '#ffeb3b4d' };
    else
        return { 'background-color': '#9e9e9e63' }
};


function numberFormat(params) {
    return parseFloat(params.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export default withRouter(withCookies(AmountDetailCIT));