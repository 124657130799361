import React from 'react';

const NewTabRenderer = (props) => {
    return (
        <>
            <a onClick={() => window.open(`/taxsubmissions/submission/${props.data.tfs_submission_id}`, "_blank")}
               className={"font-blue"}>{props.data.tfs_submission_number}</a>
        </>
    );
};

export default NewTabRenderer