import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { gapi } from 'gapi-script'
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import { TextField, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import Modal from 'react-modal';

const baseURL = `${process.env.REACT_APP_API_URL}`;
var ACCOUNTS = [];
var CATEGORIES = [];
var CATEGORY = new Map();
var rowData = [];

var totalDetailAmount = 0;

var currency = "";
var entity = "";
var taxType = "";
var windowHeight = window.innerHeight;
var bufferSize = Math.ceil(windowHeight * 0.125);
var gridHeight = Math.ceil((windowHeight * 0.75) * 0.5) + bufferSize;
var pageSize = Math.floor(gridHeight / 28) - 2;
gridHeight = (pageSize * 28) + bufferSize;

const newCategoryStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: '30%',
        height: '15%',
        transform: 'translate(-50%, -50%)',
    }
};

const formLabelsTheme = createMuiTheme({
    overrides: {
        MuiFormLabel: {
            asterisk: {
                color: "#db3131",
                "&$error": {
                    color: "#db3131"
                }
            }
        }
    }
});

class AmountDetailWHT extends Component {
    constructor(props) {
        super(props);

        currency = this.props.currency;
        entity = this.props.entity;
        taxType = this.props.taxType;
        rowData = this.props.rowData;
        if (this.props.totalAmout !== null && this.props.totalAmout !== undefined) {
            totalDetailAmount = this.props.totalAmout;
        }

        this.state = {
            columnDefs: [
                {
                    headerName: "Category Id",
                    field: "wht_category_id",
                    hide: true
                },
                {
                    headerName: "Category",
                    field: "category_name",
                    width: (window.innerWidth * 0.8) * 0.2,
                    editable: true,
                    cellEditor: "agSelectCellEditor",
                    cellEditorParams: { values: CATEGORIES },
                },
                {
                    headerName: "%",
                    field: "percentage",
                    width: (window.innerWidth * 0.5) * 0.1,
                    editable: true,
                    type: "numericColumn",
                    valueFormatter: numberFormat
                },
                {
                    headerName: "Amount",
                    field: "amount",
                    width: (window.innerWidth * 0.5) * 0.2,
                    editable: true,
                    type: "numericColumn",
                    valueFormatter: numberFormat
                },
                {
                    headerName: "Amount Percentage",
                    field: "amount_percentage",
                    width: (window.innerWidth * 0.5) * 0.2,
                    editable: false,
                    type: "numericColumn",
                    valueFormatter: numberFormat,
                    valueGetter: amountPercentageValueGetter
                },
            ],
            //rowData: [],
            accessToken: '',
            userName: '',
            cookies: '',
            newCategory: ""
        }

        this.fetchWHTDetail = this.fetchWHTDetail.bind(this);
        this.onGridReady = this.onGridReady.bind(this);
        this.saveCategory = this.saveCategory.bind(this);
    }

    abortController = new AbortController();

    fetchWHTDetail = () => {
        const url = `${baseURL}/tfs/get_wht_category/?entity_id=${this.props.entity}`;
        CATEGORIES.length = 0;
        fetch(url, {
            signal: AbortController.signal,
            method: 'GET',
            headers: { 'Authorization': `Bearer google-oauth2 ${this.state.accessToken}` }
        }
        ).then(result => result.json())
            .then(category => {
                if (category.length > 0) {
                    category.forEach(element => {
                        CATEGORY.set(element["wht_category_id"], element["category"]);
                        CATEGORIES.push(element["category"]);
                    })
                }
            });
        document.getElementById("#currency").value = this.props.currency;
        document.getElementById("#entity").value = this.props.entity;
        document.getElementById("#taxtype").value = this.props.taxType;
        document.getElementById("#wht_amount").value = parseFloat(totalDetailAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    componentDidMount() {
        window.gapi.load('auth2', () => {
            window.gapi.auth2.init({
                client_id: `${process.env.REACT_APP_CLIENT_ID}`,
                cookie_policy: 'none'
            }).then(() => {

                try {
                    const user = gapi.auth2.getAuthInstance().currentUser.get();
                    if(user){
                        user.reloadAuthResponse()
                    }
                    gapi.auth2.getAuthInstance().currentUser.listen((usr) => this.setState({ accessToken: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token, userName: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail() }))
                }
                catch (error) {
                    console.log("trycatch", error)
                }

                this.setState({ accessToken: gapi.auth2.getAuthInstance().isSignedIn.get() });
                if (this.state.accessToken) {
                    this.setState({ accessToken: gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token, userName: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail() });
                    if (this.props.cookies.get('Logins') === "Test") {
                        this.props.cookies.set('Logins', gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getId(), { maxAge: 432000, sameSite: false });
                    }

                    this.setState({ cookies: this.props.cookies.get('Logins') })

                    if (this.state.cookies !== "Test" && this.state.cookies) {
                        this.fetchWHTDetail();
                    }
                    else {
                        gapi.auth2.getAuthInstance().disconnect()
                        window.location.href = '/Login';
                    }
                }
                else {
                    gapi.auth2.getAuthInstance().disconnect()
                    window.location.href = '/Login';
                }
            })
        });
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
    }


    addDetail = e => {
        const updates = this.gridApi.updateRowData(
            {
                add: [{
                    category: CATEGORIES[0],
                    percentage: 0,
                    amount: 0,
                    amount_percentage: 0
                }]
            }
        );
        this.gridApi.startEditingCell({
            rowIndex: updates.add[0].rowIndex,
            colKey: 'category_name'
        });
    }

    saveDetail = e => {
        var recordKey, error, msg;
        var categoryId;
        var i;
        var r = 0;
        var tfsSubmissionId = this.props.tfsSubmissionId;
        var type = this.props.type;
        totalDetailAmount = 0;
        rowData.length = 0

        //remove existing
        if (tfsSubmissionId !== undefined && tfsSubmissionId !== "") {
            fetch(`${baseURL}/tfs/delete_tfs_wht_submission/`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer google-oauth2 ${this.state.accessToken}`
                },
                body: JSON.stringify(
                    {
                        "tfs_submission_id": tfsSubmissionId,
                        "type": this.props.type
                    })
            }).catch(error => alert(error));
        }

        this.gridApi.forEachNode((node) => {
            const { data } = node;
            categoryId = getMapValue(CATEGORY, data.category_name);
            data.wht_category_id = categoryId;
            data.amount_percentage = (parseFloat(data.amount) * parseFloat(data.percentage) / 100);
            totalDetailAmount += (parseFloat(data.amount) * parseFloat(data.percentage) / 100);
            data.type = type

            rowData.push(data);
        });

        //save to database, only for edit state
        if (tfsSubmissionId !== undefined && tfsSubmissionId !== "") {
            if (rowData.length > 0) {
                const { userName } = this.state;
                let dataNew = Object.keys(rowData).map(function (item) {
                    return {
                        percentage: rowData[item].percentage,
                        amount: rowData[item].amount,
                        amount_percentage: rowData[item].amount_percentage,
                        type: rowData[item].type,
                        category: rowData[item].wht_category_id,
                        tfs_submission_id: tfsSubmissionId,
                        created_by: rowData[item].created_by ? rowData[item].created_by : userName,
                        creation_date: rowData[item].creation_date ? rowData[item].creation_date : new Date(),
                        last_update_date: new Date(),
                        last_updated_by: userName
                    }
                })

                const url = `${baseURL}/tfs/create_wht_submission/`;
                this.abortController.abort();
                this.abortController = new AbortController();
                const signal = this.abortController.signal;
                fetch(url, {
                    signal: signal,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer google-oauth2 ${this.state.accessToken}`
                    },
                    body: JSON.stringify(dataNew)
                })
                    .then(response => response.json())
                    .then((jsonStr) => {
                    })
                    .catch(e => console.log('commente', e));

                //save total amount in tfs_submission
                fetch(`${baseURL}/tfs/save_amount_submission/`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer google-oauth2 ${this.state.accessToken}`
                    },
                    body: JSON.stringify({
                        "tfs_submission_id": tfsSubmissionId,
                        "type": type,
                        "amount": totalDetailAmount,
                        "amount_usd_indicative": this.props.USDRate ? totalDetailAmount * this.props.USDRate : 0
                    })
                }).catch(error => alert(error));

            }
        }

        this.gridApi.refreshCells();
        this.props.getTotalAmout(parseFloat(totalDetailAmount), this.props.type);
        msg = "WHT detail has been saved.\nTotal WHT amount: " + parseFloat(totalDetailAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        alert(msg);
        document.getElementById("#wht_amount").value = parseFloat(totalDetailAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    deleteDetail = e => {
        var i = 0;
        this.gridApi.forEachNode((node) => {
            const { data } = node;
            if (node.selected) {
                if (data.wht_submission_id) {
                    fetch(`${baseURL}/tfs/delete_wht_submission/${data.wht_submission_id}/`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer google-oauth2 ${this.state.accessToken}`
                        },
                    }).catch(error => alert(error));
                    totalDetailAmount -= (parseFloat(data.amount) * parseFloat(data.percentage) / 100);
                }
                i++;
            }
        });

        var selectedData = this.gridApi.getSelectedRows();
       this.gridApi.updateRowData({ remove: selectedData });
        this.props.getTotalAmout(parseFloat(totalDetailAmount), this.props.type);
        alert("Number of record(s) deleted: " + i);
        document.getElementById("#wht_amount").value = parseFloat(totalDetailAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    getNewCategory() {
        return (
            <div className='input'>
                <MuiThemeProvider theme={formLabelsTheme}>
                    <form>
                        <TextField
                            required
                            id="newCategory"
                            label="Category Name"
                            helperText="Input new category here"
                            onChange={e => { this.setState({ newCategory: e.target.value }) }}
                        />
                        <button id="#saveCategory" className="account-balance-detail-button" onClick={this.saveCategory}>Save</button>
                    </form>
                </MuiThemeProvider>
            </div>
        )
    }

    saveCategory() {
        if (this.state.newCategory === "") {
            alert('Please enter a value for Category Name.')
        }
        else {
            const url = `${baseURL}/tfs/save_wht_category/`;
            fetch(url, {
                signal: AbortController.signal,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer google-oauth2 ${this.state.accessToken}`
                },
                body: JSON.stringify(
                    {
                        "entity_id": this.props.entity,
                        "category": this.state.newCategory
                    }
                )
            }
            ).then(response => response.json())
                .then(data => {
                    this.setState({ getopenModalNewCategory: false });
                    this.fetchWHTDetail();
                }).
                catch(e => console.log('error', e))


        }
    }

    disableButton(editable) {
        if (editable === 'Y' || this.props.tfsSubmissionId === undefined) {
            return false
        }
        else {
            return true
        }
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="account-balance-detail-label column-modal">
                        <form>
                            <label className="label-field-right">Entity:</label> <input id="#entity" type="text" className="input-field-left" disabled /><br></br>
                            <label className="label-field-right">Tax Type:</label> <input id="#taxtype" type="text" className="input-field-left" disabled /><br></br>
                            <label className="label-field-right">Currency:</label> <input id="#currency" type="text" className="input-field-left" disabled /><br></br>
                            <label className="label-field-right">Amount:</label> <input id="#wht_amount" type="text" className="input-field-left" disabled /><br></br>
                        </form>
                    </div>
                    <div className="column-modal-right">
                        <button id="#add" className={this.disableButton(this.props.buttonEditable) ? "account-balance-detail-button-disabled" : "account-balance-detail-button"} disabled={this.disableButton(this.props.buttonEditable)}
                            onClick={(e) => this.setState({ getopenModalNewCategory: true })}>New Category</button>
                        {this.state.getopenModalNewCategory &&
                            <Modal
                                isOpen={this.state.getopenModalNewCategory}
                                onRequestClose={e => this.setState({ getopenModalNewCategory: false })}
                                style={newCategoryStyles}
                            >
                                {this.getNewCategory()}
                            </Modal>
                        }
                    </div>
                </div>
                <br></br>
                <div className="ag-theme-balham" style={{ height: gridHeight + 'px' }} >
                    <AgGridReact
                        id="agGridVATDetail"
                        gridOptions={this.gridOptions}
                        pagination={false}
                        rowMultiSelectWithClick={false}
                        rowDragManaged={true}
                        paginationPageSize={pageSize}
                        suppressMiniFilter={true}
                        suppressRowClickSelection={false}
                        suppressHorizontalScroll={true}
                        suppressVerticalScroll={true}
                        columnDefs={this.state.columnDefs}
                        rowData={rowData}
                        rowHeight={28}
                        rowSelection='multiple'
                        singleClickEdit={true}
                        stopEditingWhenGridLosesFocus={true}
                        onGridReady={this.onGridReady}
                        onComponentStateChanged={this.onComponentStateChanged}
                    >
                    </AgGridReact>
                </div>
                <div className="account-balance-detail-button-container">
                    <button id="#save" className={this.disableButton(this.props.buttonEditable) ? "account-balance-detail-button-disabled" : "account-balance-detail-button"} disabled={this.disableButton(this.props.buttonEditable)} onClick={this.saveDetail}>Save</button>
                    <button id="#add" className={this.disableButton(this.props.buttonEditable) ? "account-balance-detail-button-disabled" : "account-balance-detail-button"} disabled={this.disableButton(this.props.buttonEditable)} onClick={this.addDetail}>Add</button>
                    <button id="#delete" className={this.disableButton(this.props.buttonEditable) ? "account-balance-detail-button-disabled" : "account-balance-detail-button"} disabled={this.disableButton(this.props.buttonEditable)} onClick={this.deleteDetail}>Delete</button>
                </div>
            </div>

        );
    }
};

function numberFormat(params) {
    return parseFloat(params.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

function amountPercentageValueGetter(params) {
    return (parseFloat(params.data.percentage) / 100) * parseFloat(params.data.amount);
}

function getMapValue(map, searchValue) {
    for (let [key, value] of map.entries()) {
        if (value === searchValue)
            return key;
    }
}

export default withRouter(withCookies(AmountDetailWHT));