/* global gapi */
import React,{Component} from 'react';
import {withCookies} from 'react-cookie';

class Redirect extends Component {

    componentDidMount(){
        alert("You dont have access, please approach FINSYS team for access")
        window.location.href='/Login';
    }

    render(){
        return(
            <div>
            </div>
        )
    }
}
export default withCookies(Redirect);