/* global gapi */
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import ReactTable from "react-table";
import './sea-react-table.css';
import { withRouter } from 'react-router-dom';
import "react-sweet-progress/lib/style.css";
import 'react-tabs/style/react-tabs.css';

const baseURL = `${process.env.REACT_APP_API_URL}`;

class SubmissionHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accesstoken: '',
      UserID: '',
      username: '',
      cookies: this.props.cookies.get('Logins'),
      checklists: [],
      isSearch: false,
      isCreate: false,
      isChecklistsExist: false,
      selectedPeriod: 'All',
      selectedSegment: 'All',
      selectedCountry: 'All',
      selectedEntity: 'All',
      selectedStatus: null,
      selectedChecklistIndex: null,
      showHistory: false,
      showUpdateStatus: false,
      showCreateAlert: false,
      selectedChecklistId: null,
      entityId: null,
      periodId: null,
      accessType: null,
      render: false,
      submissionhistory: [],
      search: true,
      showFlow: false,
      flow: [],
      pageSize: 10,
    };

  };

  abortController = new AbortController();

  componentDidMount() {
    this.fetchhistory()
  }

  fetchhistory() {
    const { accesstoken } = this.props;
    const url = `${baseURL}/tfs/submission_history/?submission_id=${this.props.submission_id}&tz=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer google-oauth2 ${accesstoken}`
      }
    })
      .then(response => response.json())
      .then((jsonStr) => {
        this.setState({ submissionhistory: jsonStr })
      }).catch(error => console.log(error));
  };

  handlePageSizeChange = (pageSize) => {
    this.setState({ pageSize })
  }

  render() {
    const columns = [
      {
        Header: "Status",
        accessor: "status",
        width: 200,
        style: {
          textAlign: "left",
          paddingLeft: 15
        }
      },
      {
        Header: "Action by",
        accessor: "user_email",
        width: 270,
        minwidth: 270,
        maxwidth: 270,
        style: {
          textAlign: "left",
          paddingLeft: 15
        }
      },
      {
        Header: "Date",
        accessor: "date_format",
        width: 180,
        minwidth: 270,
        maxwidth: 270,
        style: {
          textAlign: "left",
          paddingLeft: 15
        }
      },
      {
        Header: "Amount",
        accessor: "amount_currency",
        style: {
          textAlign: "right",
          paddingLeft: 15,
          paddingRight: 15
        }
      },
    ];
    const styles = { height: '10%', color: 'black', paddingRight: '5%' };
    return (
      <div style={styles}>
        <h3>Submission History</h3>
        <ReactTable
          columns={columns}
          data={this.state.submissionhistory}
          filterable={false}
          pageSize={this.state.pageSize}
          className="-highlight"
          showPagination={this.state.submissionhistory.length > this.state.pageSize}
          onPageSizeChange={this.handlePageSizeChange}
        />
        <br></br>
      </div>
    )
  }
};

export default withCookies(withRouter(SubmissionHistory));
