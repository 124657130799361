import React, {Component} from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
 
class ExportTaxSummary extends Component {

    componentDidMount() {
        const script = document.createElement("script");
        const scriptText = document.createTextNode('exportButton = document.getElementById("FscpDetail-Export");' +
            'exportButton.click()');
        script.appendChild(scriptText);
        document.head.appendChild(script);
    }

    renderTableHeader() {
        return (
        <>
            <th key="Submission ID">Submission ID</th>
            <th key="Entity">Entity</th>
            <th key="Tax Type">Tax Type</th>
            <th key="Amount">Amount</th>
            <th key="Amount USD">Amount USD</th>
            <th key="Due Date">Due Date</th>
            <th key="Status">Status</th>
            <th key="Progress">Progress</th>
        </>
        )
    }

    renderTableData() {
        return this.props.exportData?.map((checklist) => {
           const { tfs_submission_number,entity_id,tax_type,amount_currency,amount_usd_indicative_currency,due_date,status,percentage
           } = checklist;
           return (
              <tr key={tfs_submission_number}>
                 <td>{tfs_submission_number}</td>
                 <td>{entity_id}</td>
                 <td>{tax_type}</td>
                 <td>{amount_currency}</td>
                 <td>{amount_usd_indicative_currency}</td>
                 <td>{due_date}</td>
                 <td>{status}</td>
                 <td>{percentage}</td>
              </tr>
           )
        })
    }

    render() {
        const fileName = `Tax Summary`;
        return (
            <>
                <ReactHTMLTableToExcel
                    id="FscpDetail-Export"
                    className={"export-react-table"}
                    table="FscpDetail-Table"
                    filename={fileName}
                    sheet={fileName}
                    buttonText="Export"
                />
                <table hidden id="FscpDetail-Table">
                    <tbody>
                        <tr>{this.renderTableHeader()}</tr>
                        {this.renderTableData()}
                    </tbody>
                </table>
            </>
        );
    }
}
 
export default ExportTaxSummary;